import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import KiAppBar from 'components/KiAppBar';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import CopyDatasetForm from './components/copyDatasetForm';
import DeleteDatasetForm from './components/deleteDatasetForm';
import {showSnackbar} from 'state/actions/Snackbar';
import {fetchDatasetList} from 'containers/datasetList/actions';
import './index.scss';

const DatasetCopy = props => {
	useEffect(() => {
		document.title = `${props.app.kiVersion} - Dataset Copy`;
		if (props.datasets?.length === 0) {
			props.fetchDatasetList();
		}
	}, []);

	return (
		<div className="container-wrapper">
			<div className="container-body">
				<header>
					<KiAppBar className="top-bar">
						<div className="top-bar-breadcrumb">
							<h1>Dataset Copy</h1>
						</div>
					</KiAppBar>
				</header>
				<div className="ki-panel">
					<KiTabs onChange={() => {}} index={0}>
						<KiTab title="Copy">
							<CopyDatasetForm
								datasets={props.datasets}
								showSnackbar={props.showSnackbar}
								fetchDatasetList={props.fetchDatasetList}
							/>
						</KiTab>
						<KiTab title="Delete">
							<DeleteDatasetForm
								datasets={props.datasets}
								showSnackbar={props.showSnackbar}
								fetchDatasetList={props.fetchDatasetList}
							/>
						</KiTab>
					</KiTabs>
				</div>
			</div>
		</div>
	);
};

DatasetCopy.propTypes = {
	datasets: PropTypes.array.isRequired,
	app: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func.isRequired,
	fetchDatasetList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	datasets: state.datasetList.data,
	app: state.app,
});

const mapDispatchToProps = () => ({
	fetchDatasetList,
	showSnackbar,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(DatasetCopy);

// Global Imports
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _isEqual from 'lodash/isEqual';

// Project Imports
import {TOTAL_BOOK_DATASET_ID} from 'ki-common/constants';
import {getSortDescFuncByField} from 'ki-common/utils/sorters';

// Local Imports
import KiFontIcon from 'components/KiFontIcon';
import KiCheckbox from 'components/KiCheckbox';
import KiButton from 'components/KiButton';
import KiConfirmModal from 'components/KiConfirmModal';
import './SnapshotBlock.scss';

export class SnapshotBlock extends Component {
	//eslint-disable-line react/prefer-stateless-function
	static propTypes = {
		dataset: PropTypes.object,
		isVisible: PropTypes.bool,
		handleEditSnapshotsClick: PropTypes.func,
		showDeleteSnapshotsConfirm: PropTypes.func,
	};

	state = {
		selectedSnapshots: [],
		snapshotDeleteRejectedModalVisible: false,
		selectAll: false,
	};

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			this.setState({selectedSnapshots: []});
		}
	}

	handleSnapshotChecked = snapshot => {
		if (this.state.selectedSnapshots.find(curSnapshot => curSnapshot === snapshot)) {
			this.setState({
				selectedSnapshots: this.state.selectedSnapshots.filter(curSnapshot => curSnapshot !== snapshot),
			});
		} else {
			this.setState({selectedSnapshots: [snapshot, ...this.state.selectedSnapshots]});
		}
	};

	toggleSelectAll = () => {
		this.setState({selectAll: !this.state.selectAll}, () => {
			if (this.state.selectAll) {
				// const selectedSnapshotsForDelete = this.props.dataset.snapshots.sort(getSortDescFuncByField('snapshotDate'))
				this.setState({
					selectedSnapshots: this.props.dataset.snapshots
						.sort(getSortDescFuncByField('snapshotDate'))
						.map(s => s.snapshotDate),
				});
			} else {
				this.setState({selectedSnapshots: []});
			}
		});
	};

	handleDeleteClick = () => {
		return this.props.showDeleteSnapshotsConfirm(this.props.dataset, this.state.selectedSnapshots);
	};

	render() {
		if (!this.props.isVisible) {
			return null;
		}

		const {datasetId} = this.props.dataset;

		return (
			<div className="dataset-list-snapshot-block">
				<section className="block-header">
					<p>Snapshot Dates</p>
					<KiFontIcon
						value="close"
						className="close-icon"
						onClick={e => this.props.handleEditSnapshotsClick(e, datasetId)}
					/>
				</section>
				<section className="dataset-snapshot-list-container">
					<div key="all" className="snapshot-entry">
						<div className="snapshot-checkbox">
							<KiCheckbox checked={this.state.selectAll} onChange={() => this.toggleSelectAll()} />
						</div>
						<div className="snapshot-text">Select All</div>
					</div>
					{this.props.dataset.snapshots
						.sort(getSortDescFuncByField('snapshotDate'))
						.map((snapshotObj, index) => {
							// If this is the first snapshot in the list AND there is more than one snapshot AND select all is not active
							const isCurrentNotDeletable =
								index === 0 && this.props.dataset.snapshots.length > 1 && !this.state.selectAll;
							return (
								<div key={snapshotObj.snapshotDate} className="snapshot-entry">
									<div className="snapshot-checkbox">
										<KiCheckbox
											id={`Snapshot-Chkbox-${snapshotObj.snapshotDate}`}
											disabled={
												this.props.dataset.datasetId === TOTAL_BOOK_DATASET_ID ||
												isCurrentNotDeletable
											}
											checked={this.state.selectedSnapshots.includes(snapshotObj.snapshotDate)}
											onChange={() => this.handleSnapshotChecked(snapshotObj.snapshotDate)}
										/>
									</div>
									<label
										htmlFor={`Snapshot-Chkbox-${snapshotObj.snapshotDate}`}
										className={isCurrentNotDeletable ? 'current-snapshot-text' : 'snapshot-text'}
									>
										{isCurrentNotDeletable
											? `${snapshotObj.snapshotDate} (Current, Not Deletable)`
											: snapshotObj.snapshotDate}
									</label>
								</div>
							);
						})}
				</section>
				<KiButton
					className="delete-snapshot-button"
					label="Delete"
					raised
					primary
					onClick={this.handleDeleteClick}
					disabled={
						this.state.selectedSnapshots.length === 0 ||
						this.props.dataset.datasetId === TOTAL_BOOK_DATASET_ID
					}
				/>
				<KiConfirmModal
					acceptLabel="Close"
					acceptFunc={() => this.setState({snapshotDeleteRejectedModalVisible: false})}
					active={this.state.snapshotDeleteRejectedModalVisible}
					header="Cannot Delete Snapshot"
					message="The selected snapshot is associated with a Pending scenario and cannot be deleted until it is transfered or rejected."
				/>
			</div>
		);
	}
}

export default connect()(SnapshotBlock);

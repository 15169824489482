export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
export const SNACKBAR_HIDE = 'SNACKBAR_HIDE';

export const hideSnackbar = () => {
	return {type: SNACKBAR_HIDE};
}

export const showSnackbar = options => {
	if (typeof options === 'string') {
		return {type: SNACKBAR_SHOW, options: {msg: options}};
	}
	return {type: SNACKBAR_SHOW, options: options};
}

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {getDescription} from 'ki-common/utils/constraintUtil';

export class ConstraintListItemView extends Component {
	static propTypes = {
		item: PropTypes.object,
		columns: PropTypes.array,
	};

	render() {
		const description = getDescription(this.props.item, this.props.columns);
		// The pre tag lets the /n in description render correctly
		return (
			<span style={{whiteSpace: 'pre-wrap'}} title={description}>
				{description}
			</span>
		);
	}
}
export default ConstraintListItemView;

import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';
import analytics from 'analytics';

export const fetchFundingVehicleList = datasetId => {
	if (datasetId) {
		// eslint-disable-next-line no-use-before-define
		return fetchFundingVehiclesByDatasetId(datasetId);
	}
	return fetch(`${apiUrl}/fundingvehicles`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchFundingVehicle = id => {
	analytics.sendEvent('funding-vehicles', 'get-by-id', id);
	return fetch(`${apiUrl}/fundingvehicles/${id}?customFields=true`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchFundingVehiclesByDatasetId = id => {
	analytics.sendEvent('funding-vehicles', 'get-by-dataset-id', id);
	return fetch(`${apiUrl}/fundingvehicles/datasetId/${id}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchFundingVehiclesWithDates = (datasetId, statementDate) => {
	analytics.sendEvent('funding-vehicles', 'fetchFundingVehiclesWithDates', datasetId);
	return fetch(`${apiUrl}/fundingvehicles/datasetId/${datasetId}?queryDate=${statementDate}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const addFundingVehicle = fundingVehicle => {
	analytics.sendEvent('funding-vehicles', 'create');
	return fetch(`${apiUrl}/fundingvehicles`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(fundingVehicle),
	}).then(_handleResponse);
};

export const updateFundingVehicle = fundingVehicle => {
	analytics.sendEvent('funding-vehicles', 'update');
	return fetch(`${apiUrl}/fundingvehicles/${fundingVehicle._id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(fundingVehicle),
	}).then(_handleResponse);
};

export const deleteFundingVehicles = fundingVehicleIds => {
	analytics.sendEvent('funding-vehicles', 'delete');
	return fetch(`${apiUrl}/fundingvehicles`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
		body: JSON.stringify(fundingVehicleIds),
	}).then(_handleResponse);
};

export const deactivateFundingVehicles = (fundingVehicleIds, inactiveDate) => {
	analytics.sendEvent('funding-vehicles', 'deactivate');
	return fetch(`${apiUrl}/fundingvehicles/inactive`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			ids: fundingVehicleIds,
			inactiveDate: inactiveDate,
		}),
	}).then(_handleResponse);
};

export const updateFundingVehicleSetting = fundingVehicleSetting => {
	analytics.sendEvent('funding-vehicles', 'update-setting');
	return fetch(`${apiUrl}/fundingVehicleData/${fundingVehicleSetting._id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(fundingVehicleSetting),
	}).then(_handleResponse);
};

export const addFundingVehicleSetting = fundingVehicleSetting => {
	analytics.sendEvent('funding-vehicles', 'create-setting');
	return fetch(`${apiUrl}/fundingVehicleData`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(fundingVehicleSetting),
	}).then(_handleResponse);
};

export const getFundingVehicleSettingsDependencies = (type, body) => {
	return fetch(`${apiUrl}/fundingVehicleData/dependencies/${type}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(body),
	}).then(_handleResponse);
};

export const deleteFundingVehicleSettings = fundingVehicleSettingIds => {
	analytics.sendEvent('funding-vehicles', 'delete-setting');
	return fetch(`${apiUrl}/fundingVehicleData`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
		body: JSON.stringify(fundingVehicleSettingIds),
	}).then(_handleResponse);
};

export const fetchFundingVehicleSettings = id => {
	analytics.sendEvent('funding-vehicles', 'get-settings-for-id', id);
	return fetch(`${apiUrl}/fundingvehicles/${id}/settings`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchFundingVehicleSettingsByDatasetId = id => {
	analytics.sendEvent('funding-vehicles', 'get-settings-for-datasetId', id);
	return fetch(`${apiUrl}/fundingVehicleData/dataset/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchFundingVehicleSetting = id => {
	analytics.sendEvent('funding-vehicles', 'get-setting-for-id', id);
	return fetch(`${apiUrl}/fundingVehicleData/${id}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchAllReportingDates = fvId => {
	analytics.sendEvent('funding-vehicles', 'get-report-dates-for-id', fvId);
	return fetch(`${apiUrl}/fundingvehicles/${fvId}/reportDates`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const checkUniqueNamePerDatasetId = (datasetId, name) => {
	return fetch(
		`${apiUrl}/fundingvehicles/nameValidation?datasetId=${encodeURIComponent(datasetId)}&name=${encodeURIComponent(
			name
		)}`,
		{
			credentials: 'include',
			headers: _getHeaders(),
		}
	).then(_handleResponse);
};

// GET /tracks/:trackId? (and queryParam of ?fundingVehicleId=xxx to filter by fundingVehicleId)
// POST /tracks (CREATE)
// PUT /tracks/:trackId (REPLACE)
// PATCH /tracks/:trackId (PARTIAL UPDATE)
// DELETE /tracks/:trackId (DELETION)
// GET /blocks/:blockId? (GET ALL OR ONE)
// POST /blocks (CREATE)
// PUT /blocks/:blockId (REPLACE)
// PATCH /blocks/:blockId (PARTIAL UPDATE)
// DELETE /blocks/:blockId (DELETE)

export const addBlock = block => {
	if (!block._id) {
		analytics.sendEvent('blocks', 'create');
		return fetch(`${apiUrl}/blocks`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(block),
		}).then(_handleResponse);
	}
	analytics.sendEvent('blocks', 'update', block._id);
	return fetch(`${apiUrl}/blocks/${block._id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(block),
	}).then(_handleResponse);
};

export const deleteBlock = blockId => {
	analytics.sendEvent('blocks', 'delete', blockId);
	return fetch(`${apiUrl}/blocks/${blockId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const fetchBlocksForFundingVehicle = fvId => {
	analytics.sendEvent('blocks', 'get-blocks-for-fvId', fvId);
	return fetch(`${apiUrl}/blocks?fundingVehicleId=${fvId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchTracksForFundingVehicle = fvId => {
	analytics.sendEvent('tracks', 'get-tracks-for-fvId', fvId);
	return fetch(`${apiUrl}/tracks?hydrateBlocks=true&fundingVehicleId=${fvId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const addTrack = track => {
	// if (track.blocks[0]._id) {
	// 	track.blocks = track.blocks.map(b => b._id);
	// }
	if (!track._id) {
		analytics.sendEvent('track', 'create');
		return fetch(`${apiUrl}/tracks`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(track),
		}).then(_handleResponse);
	}
	analytics.sendEvent('track', 'update', track._id);
	return fetch(`${apiUrl}/tracks/${track._id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(track),
	}).then(_handleResponse);
};

export const getPoolsForFundingVehicle = (datasetId, fvId) => {
	return fetch(`${apiUrl}/pools/${datasetId}/fundingVehicle/${fvId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchFundingVehicleList,
	fetchFundingVehicle,
	updateFundingVehicle,
	fetchFundingVehiclesByDatasetId,
	deleteFundingVehicles,
	deactivateFundingVehicles,
	updateFundingVehicleSetting,
	deleteFundingVehicleSettings,
	fetchFundingVehicleSettings,
	fetchFundingVehiclesWithDates,
	fetchFundingVehicleSettingsByDatasetId,
	fetchFundingVehicleSetting,
	addFundingVehicleSetting,
	checkUniqueNamePerDatasetId,
	fetchAllReportingDates,
	addBlock,
	fetchBlocksForFundingVehicle,
	deleteBlock,
	fetchTracksForFundingVehicle,
	addTrack,
	getPoolsForFundingVehicle,
};

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import styles from './KiModal2.theme.scss';
import KiButton from 'components/KiButton';

export const Modal = ({children, open, className, onClose}) =>
	open
		? ReactDOM.createPortal(
				<div
					className={`${className} ${styles.modal}`}
					onClickCapture={e => {
						e.currentTarget === e.target && onClose();
					}}
				>
					{children}
				</div>,
				document.body
		  )
		: null;

export class KiModal2 extends Component {
	static propTypes = {
		active: PropTypes.bool,
		className: PropTypes.string,
		header: PropTypes.any,
		onClose: PropTypes.func,
		actions: PropTypes.array,
		hideFooter: PropTypes.bool,
		children: PropTypes.node,
		modalStyles: PropTypes.object,
		panelStyles: PropTypes.object,
	};

	static defaultProps = {
		isOpen: false,
		active: false,
		className: '',
		header: '',
		actions: [],
		hideFooter: false,
	};

	state = {};

	componentDidMount() {
		this.setState({
			active: !!this.props.active,
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.active !== prevProps.active) {
			this.setState({active: this.props.active});
		}
	}

	toggleModal = () => this.setState({active: !this.state.active});

	render() {
		if (!this.props.active) {
			return null;
		}
		const {header, onClose, actions, children, modalStyles, panelStyles, hideFooter} = this.props;
		const {active} = this.state;
		return (
			<React.Fragment>
				<Modal
					style={modalStyles}
					open={active}
					onClose={onClose}
					contentLabel={header}
					className={this.props.className}
					// overlayClassName={styles.kiModalOverlay}
					closeTimeoutMS={300}
					shouldCloseOnOverlayClick={true}
				>
					<div className={styles.modalBox} style={panelStyles}>
						{header && <header className={styles.modalBoxHeader}>{header}</header>}
						<section className={styles.modalBoxBody}>{children && children}</section>
						{!hideFooter && (
							<footer className={styles.modalBoxFooter}>
								{actions.map((action, idx) => {
									if (action.label === 'Cancel') {
										return (
											<KiButton
												key={idx}
												className={action.className}
												disabled={action.disabled}
												onClick={action.onClick || this.toggleModal}
												flat
												primary
											>
												{action.label}
											</KiButton>
										);
									} else {
										return (
											<KiButton
												key={idx}
												className={action.className}
												disabled={action.disabled}
												onClick={action.onClick}
												raised
												primary
											>
												{action.label}
											</KiButton>
										);
									}
								})}
							</footer>
						)}
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}

export default KiModal2;

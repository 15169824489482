import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FundingAnalysisContext from './fundingAnalysisContext';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {
	fetchScenarios,
	fetchScenario,
	fetchDefaultBookmark,
	fetchBookmarkById,
	fetchBookmarks,
	fetchModel,
	getConstraintGroups,
	fetchScenarioAnalysisPost,
	fetchSummaryAnalysisPost,
	fetchQuickFilters,
} from 'api/fundingAnalysisApi';
import {getColumnsFromService} from 'api/columnServiceApi';

export default class FundingAnalysisProvider extends Component {
	state = {
		model: {
			_id: null,
			name: '',
			fvSources: [],
			fvTargets: [],
			isFractional: false,
			includePrevAssets: false,
			isBlended: false,
			isTopoff: true,
			groupBy: null,
			type: null,
			optimization: null,
			constraintGroups: [],
		},
		scenario: {
			_id: null,
			name: '',
			fundingModelId: null,
			scenarioLimit: '',
			constraintGroupId: null,
			snapshotDate: null,
			transferDate: null,
			fvSettings: [],
			filters: [],
		},
		bookmark: {
			_id: null,
			createdBy: null,
			createdAt: null,
			updatedAt: null,
			isDefault: false,
			isFavorite: false,
			name: '',
			datasetId: null,
			createDate: null,
			tags: [],
			settings: {
				viewType: null, //|summary|pool|excess|breaches
				tableType: null,
				sortOrder: 'asc',
				sortColumn: null,
				constraintGroup: 'setup',
				statementDate: dateToShortDate(new Date()),
				dateContext: '',
				scenarioType: 'assetSnapshot',
				scenarioId: null,
				transferDate: null,
				fundingVehicleIds: ['all'],
			},
			viewColumns: {
				scenario: [],
				summary: [],
				breaches: [],
				compliance: [],
			},
		},
		data: {
			allModels: [],
			allScenarios: [],
			allBookmarks: [],
			allColumns: [],
			analysisData: {columns: [], rows: []},
			exploreScenarioId: '',
			errors: [],
			scenarioQuickFilters: [],
			datasetDates: [],
		},
		ui: {
			isLoading: false,
			isCopy: false,
			allDraggableFvs: [],
			nameError: false,
			showSourcesError: false,
			showTargetsError: false,
			currentConstraintGroup: {},
			tabIndex: 0,
		},
	};

	throwDeprecated = () => {
		throw new Error('deprecated call');
	};

	render() {
		return (
			<FundingAnalysisContext.Provider
				displayName="Funding Analysis Provider"
				value={{
					//Model state
					model: this.state.model,
					_id: this.state.model._id,
					name: this.state.model.name,
					fvSources: this.state.model.fvSources,
					fvTargets: this.state.model.fvTargets,
					isFractional: this.state.model.isFractional,
					includePrevAssets: this.state.model.includePrevAssets,
					isBlended: this.state.model.isBlended,
					isTopoff: this.state.model.isTopoff,
					groupBy: this.state.model.groupBy,
					type: this.state.model.type,
					optimization: this.state.model.optimization,
					constraintGroups: this.state.model.constraintGroups,

					// Bookmark State
					bookmark: this.state.bookmark,

					// Scenario State
					scenario: this.state.scenario,
					fvSettings: this.state.scenario.fvSettings,

					// Data state
					allModels: this.state.data.allModels,
					allScenarios: this.state.data.allScenarios,
					allBookmarks: this.state.data.allBookmarks,
					allColumns: this.state.data.allColumns,
					analysisData: this.state.data.analysisData,
					exploreScenarioId: this.state.data.exploreScenarioId,
					scenarioQuickFilters: this.state.data.scenarioQuickFilters,
					datasetDates: this.state.data.datasetDates,

					// UI State
					errors: this.state.data.errors,
					isLoading: this.state.ui.isLoading,
					isCopy: this.state.ui.isCopy,
					nameError: this.state.ui.nameError,
					showSourcesError: this.state.ui.showSourcesError,
					showTargetsError: this.state.ui.showTargetsError,
					allDraggableFvs: this.state.ui.allDraggableFvs,
					currentConstraintGroup: this.state.ui.currentConstraintGroup,
					tabIndex: this.state.ui.tabIndex,
					setModel: newModel => {
						if (!newModel._id && !newModel.name) {
							newModel = {
								name: '',
								fvSources: [],
								fvTargets: [],
								isFractional: false,
								includePrevAssets: false,
								isBlended: false,
								isTopoff: true,
								groupBy: null,
								type: 'max_funding',
								optimization: 'minimize_excess',
								constraintGroups: [],
							};
						}
						this.setState({
							model: _.cloneDeep(newModel),
						});
					},
					getModel: () => {
						return _.cloneDeep({
							_id: this.state.model._id,
							name: this.state.model.name,
							fvSources: this.state.model.fvSources,
							fvTargets: this.state.model.fvTargets,
							isFractional: this.state.model.isFractional,
							includePrevAssets: this.state.model.includePrevAssets,
							isBlended: this.state.model.isBlended,
							isTopoff: this.state.model.isTopoff,
							groupBy: this.state.model.groupBy,
							optimization: this.state.model.optimization,
							type: this.state.model.type,
							constraintGroups: this.state.model.constraintGroups,
						});
					},
					setModelItem: (key, value) => {
						this.setState(currentState => {
							const model = _.cloneDeep(currentState.model);
							model[key] = value;
							return {model};
						});
					},
					getScenario: () => {
						return _.cloneDeep(this.state.scenario);
					},
					setScenario: scenario => {
						this.setState({
							scenario: _.cloneDeep(scenario || this.state.data.allScenarios[0]),
						});
					},
					clearScenario: () => {
						this.setState({
							scenario: {
								_id: null,
								name: '',
								fundingModelId: null,
								scenarioLimit: '',
								constraintGroupId: null,
								snapshotDate: null,
								transferDate: null,
								fvSettings: [],
								filters: [],
							},
						});
					},
					setScenarioItem: (key, value) => {
						const scenario = _.cloneDeep(this.state.scenario);
						scenario[key] = value;
						this.setState({
							scenario,
						});
					},
					getBookmark: () => {
						return _.cloneDeep(this.state.bookmark);
					},
					clearBookmark: () => {
						this.setState({
							bookmark: {
								_id: null,
							},
						});
					},
					setBookmark: bookmark => {
						// debugger;
						this.setState(
							{
								bookmark: _.cloneDeep(bookmark),
							},
							() => {
								return this.state.bookmark;
							}
						);

						// return this.state.bookmark;
					},
					setBookmarkItem: (key, value) => {
						const bookmark = _.cloneDeep(this.state.bookmark);
						_.set(bookmark, key, value);
						this.setState({
							bookmark,
						});
					},
					setDataItem: (key, value) => {
						const data = _.cloneDeep(this.state.data);
						data[key] = _.cloneDeep(value);
						this.setState({
							data,
						});
					},
					setStateItem: (key, value) => {
						const ui = _.cloneDeep(this.state.ui);
						ui[key] = value;
						this.setState({
							ui,
						});
					},
					addError: e => {
						const data = _.cloneDeep(this.state.data);
						data.errors.push(e);
						this.setState({
							data,
						});
					},
					clearErrors: () => {
						const data = _.cloneDeep(this.state.data);
						data.errors = [];
						this.setState({
							data,
						});
					},
					// data calls
					fetchScenarios: async datasetId => {
						const scenarioData = await fetchScenarios(datasetId, true, true, true);
						const data = _.cloneDeep(this.state.data);
						data['allScenarios'] = scenarioData;
						this.setState({
							data,
						});
					},
					fetchScenario: async scenarioId => {
						const scenarioData = await fetchScenario(scenarioId);
						this.setState({
							scenario: scenarioData,
						});
						return scenarioData;
					},
					fetchDefaultScenarioBookmark: async (datasetId, scenarioId) => {
						const bookmarkData = await fetchDefaultBookmark(datasetId, 'scenario');
						_.set(bookmarkData, 'settings.scenarioId', scenarioId);
						this.setState({
							bookmark: bookmarkData,
						});
						return bookmarkData;
					},
					fetchDefaultSummaryBookmark: async datasetId => {
						const bookmarkData = await fetchDefaultBookmark(datasetId, 'summary');
						this.setState({
							bookmark: bookmarkData,
						});
						return bookmarkData;
					},

					fetchBookmark: async bookmarkId => {
						const bookmarkData = await fetchBookmarkById(bookmarkId);
						this.setState({
							bookmark: bookmarkData,
						});
						return bookmarkData;
					},
					fetchBookmarks: async datasetId => {
						const bookmarkData = await fetchBookmarks(datasetId);
						const data = _.cloneDeep(this.state.data);
						data['allBookmarks'] = bookmarkData;
						this.setState({
							data,
						});
						return bookmarkData;
					},
					fetchModel: async modelId => {
						const modelData = await fetchModel(modelId);
						this.setState({
							model: modelData,
						});
						return modelData;
					},
					fetchConstraintGroups: async modelId => {
						const constraintData = await getConstraintGroups(modelId);
						const model = _.cloneDeep(this.state.model);
						model['constraintGroups'] = constraintData;
						this.setState({
							model,
						});
					},
					fetchAnalysisData: async (scenarioId = this.state.scenario._id) => {
						let tableData;
						const bm = _.cloneDeep(this.state.bookmark);
						if (
							bm.settings.fundingVehicleIds &&
							bm.settings.fundingVehicleIds.length &&
							bm.settings.fundingVehicleIds[0].fvId
						) {
							bm.settings.fundingVehicleIds = bm.settings.fundingVehicleIds.map(fv => fv.fvId);
						}
						try {
							tableData = await fetchScenarioAnalysisPost(bm, scenarioId);
						} catch (e) {
							tableData = {columns: [], rows: []};
							const data = _.cloneDeep(this.state.data);
							data.errors.push(e.message);
							this.setState({
								data,
							});
						}
						if (!tableData.rows || !tableData.columns) {
							tableData = {columns: [], rows: []};
						}
						const data = _.cloneDeep(this.state.data);
						data['analysisData'] = tableData;
						this.setState({
							data,
						});
					},
					fetchAnalysisSummaryData: async () => {
						const bm = _.cloneDeep(this.state.bookmark);
						if (bm.settings.fundingVehicleIds[0].fvId) {
							bm.settings.fundingVehicleIds = bm.settings.fundingVehicleIds.map(fv => fv.fvId);
						}
						if (bm.settings.criteriaSources[0].fvId) {
							bm.settings.criteriaSources = bm.settings.criteriaSources.map(fv => fv.fvId);
						}
						let td;
						if (this.state.bookmark.settings.scenarioType === 'pending') {
							td = this.state.bookmark.settings.transferDate;
						} else {
							td = null;
						}
						let tableData;
						try {
							tableData = await fetchSummaryAnalysisPost(bm, td);
						} catch (e) {
							tableData = {columns: [], rows: []};
							const data = _.cloneDeep(this.state.data);
							data.errors.push(e.message);
							this.setState({
								data,
							});
						}
						if (!tableData || !tableData.rows || (!tableData.columns && !tableData.columnInfo)) {
							tableData = {columns: [], rows: []};
						}
						const data = _.cloneDeep(this.state.data);
						data['analysisData'] = tableData;
						this.setState({
							data,
						});
					},
					fetchAllColumns: async datasetId => {
						const params = {
							sources: {
								includeCohortColumns: true,
								includeFundingAnalysisColumns: true,
							},
							options: {
								returnSingleArray: false,
							},
						};
						const columns = await getColumnsFromService(datasetId, params);
						const data = _.cloneDeep(this.state.data);
						data['allColumns'] = columns.fundingAnalysisCols;
						this.setState({
							data,
						});
					},
					fetchScenarioQuickFilters: async datasetId => {
						const lists = await fetchQuickFilters(
							datasetId,
							this.state.bookmark.settings.statementDate || dateToShortDate(new Date()),
							this.state.bookmark.settings.dateContext
						);
						const data = _.cloneDeep(this.state.data);
						data['scenarioQuickFilters'] = lists.scenarioList;
						this.setState({
							data,
						});
						return lists.scenarioList;
					},
				}}
			>
				{this.props.children}
			</FundingAnalysisContext.Provider>
		);
	}
}

FundingAnalysisProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

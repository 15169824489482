import {hot} from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Switch, BrowserRouter, Route} from 'react-router-dom';

import {App, Login} from './containers';
import {connect} from 'react-redux';

const ProtectedRoute = ({component: Component, user, ...rest}) => {
	if (!user || !user.userId) {
		window.location.href =
			window.ABS_SSO_ENABLED === 'true'
				? `${document.baseURI}web/login/oidc${
						window.OIDC_REDIRECT_URI ? `?redirect_uri=${window.OIDC_REDIRECT_URI}` : ''
				  }`
				: `${document.baseURI}login`;
		return null;
	}
	return <Route component={Component} user={user} {...rest} />;
};
ProtectedRoute.propTypes = {
	component: PropTypes.any,
	location: PropTypes.object,
	user: PropTypes.object,
};

class AppRouter extends Component {
	static propTypes = {
		user: PropTypes.object,
	};

	render() {
		return (
			<BrowserRouter basename={new URL(document.baseURI).pathname}>
				<div className="router-wrapper">
					<Switch>
						<Route path="/login" component={Login} />
						<ProtectedRoute component={App} user={this.props.user} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
}

export default hot(connect(state => ({user: state.user}))(AppRouter));

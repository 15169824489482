import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import CreatableSelect from 'react-select/creatable';
import '../columnpicker.scss';
import stringHelpers from 'ki-common/utils/stringHelpers';
import KiButton from 'components/KiButton';
import KiCheckbox from 'components/KiCheckbox';
import KiInput from 'components/KiInput';

export class NumericForm extends Component {
	static propTypes = {
		submitMethod: PropTypes.func.isRequired,
		columnToEdit: PropTypes.object,
		closeForm: PropTypes.func,
		dataset: PropTypes.object,
		existingTags: PropTypes.array.isRequired,
		columnList: PropTypes.array.isRequired,
	};

	state = {
		displayName: '',
		precision: '2',
		exposeToDebt: null,
		getWaterfallOutput: null,
		nameError: null,
		precisionError: null,
		tags: [],
	};

	componentDidMount() {
		const {columnToEdit} = this.props;
		if (columnToEdit) {
			this.setState({
				_id: columnToEdit._id,
				displayName: columnToEdit.displayName,
				tags: columnToEdit.tags,
				precision: columnToEdit.precision,
				exposeToDebt: columnToEdit.exposeToDebt,
				getWaterfallOutput: columnToEdit.getWaterfallOutput,
				isInEdit: true,
			});
		}
	}

	onSubmit = () => {
		const {submitMethod, dataset} = this.props;
		this.setState({isSaving: true});

		if (!this.state.displayName.trim()) {
			this.setState({nameError: 'Name cant be blank!', isSaving: false});
			return;
		}

		if (!this.state.displayName.match(stringHelpers.alphanumericRegex)) {
			this.setState({nameError: 'Name must be Alphanumeric!', isSaving: false});
			return;
		}

		if (!this.state.precision.trim()) {
			this.setState({precisionError: 'Precision cant be blank!', isSaving: false});
			return;
		}

		if (!this.state.precision.trim() || parseInt(this.state.precision) > 10) {
			this.setState({precisionError: 'Precision must be between 1 and 10!', isSaving: false});
			return;
		}

		//duplicate test
		const displayNames = this.props.columnList.map(c => c.columnName);
		if (displayNames.includes(this.state.displayName)) {
			this.setState({nameError: 'The column name must be unique', isSaving: false});
			return;
		}

		const payload = {
			columnName: this.state.displayName,
			displayName: this.state.displayName,
			tags: this.state.tags,
			calcEntityLevel: 'fundingVehicle',
			dataType: 'numeric',
			columnType: 'waterfallVariable',
			columnFormType: 'numericVariable',
			precision: this.state.precision,
			exposeToDebt: this.state.exposeToDebt,
			getWaterfallOutput: this.state.getWaterfallOutput,
			isWaterfall: true,
			datasetId: dataset._id,
		};

		if (this.state._id) {
			payload._id = this.state._id;
		}

		return submitMethod(payload);
	};

	setName = val => {
		this.setState({
			displayName: val,
		});
	};

	setPrecision = val => {
		this.setState({
			precision: val,
		});
	};

	setPrecision = val => {
		this.setState({
			precision: val,
		});
	};

	setExposeToDebt = val => {
		const updatedState = {
			exposeToDebt: val,
		};

		if (!val && this.state.exposeToDebt) {
			updatedState.getWaterfallOutput = false;
		}

		if (!this.state.exposeToDebt) {
			updatedState.getWaterfallOutput = true;
		}

		this.setState(updatedState);
	};

	render() {
		return (
			<Fragment>
				<form className="column-selector-form inline-column-form">
					<div>
						<section className="calculation-name-tags">
							<KiInput
								label="Name"
								value={this.state.displayName}
								onChange={val => this.setName(val)}
								disabled={this.state.exposeToDebt && this.state.isInEdit}
								error={this.state.nameError}
							/>
							<span className="form-instruction">Tags:</span>
							<CreatableSelect
								classNamePrefix="aut-select"
								isMulti={true}
								options={this.props.existingTags.map(t => ({
									value: t,
									label: t,
								}))}
								value={this.state.tags.map(t => ({
									value: t,
									label: t,
								}))}
								onChange={val => this.setState({tags: val.map(t => t.value)})}
								placeholder="Add some tags"
							/>
							<br />
							<KiInput
								label="Precision"
								value={this.state.precision}
								onChange={val => this.setPrecision(val)}
								error={this.state.precisionError}
							/>
							<br />
							<KiCheckbox
								checked={this.state.exposeToDebt}
								label="Expose to Debt"
								disabled={this.state.exposeToDebt && this.state.isInEdit}
								onChange={val => this.setExposeToDebt(val)}
							/>
							<KiCheckbox
								checked={this.state.getWaterfallOutput}
								label="Get Waterfall Output"
								disabled={this.state.exposeToDebt && this.state.isInEdit}
								onChange={val => this.setState({getWaterfallOutput: val})}
							/>
						</section>
					</div>
					<section className="format-and-save">
						<div className="inline-column-form-buttons">
							<KiButton flat primary onClick={() => this.props.closeForm()}>
								Cancel
							</KiButton>
							<KiButton raised primary disabled={this.state.isSaving} onClick={() => this.onSubmit()}>
								Save
							</KiButton>
						</div>
					</section>
				</form>
			</Fragment>
		);
	}
}

export default NumericForm;

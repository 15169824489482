import PropTypes from 'prop-types';
import React from 'react';
import styles from './KiModal.theme.scss';
import Modal from 'react-modal';
import KiButton from 'components/KiButton';
import cn from 'classnames';

Modal.setAppElement('#root');

const KiModal = ({
	active,
	className,
	bodyClassName,
	header,
	headerClassName,
	onClose,
	actions,
	children,
	showCloseIcon,
	contentLabel,
}) => {
	return (
		<Modal
			isOpen={active}
			onRequestClose={onClose}
			contentLabel={typeof header === 'string' ? header : contentLabel}
			className={`${styles.kiModalContent} ${className}`}
			overlayClassName={styles.kiModalOverlay}
			closeTimeoutMS={300}
			shouldCloseOnOverlayClick={true}
		>
			{(header || showCloseIcon) && (
				<div className={cn(styles.headerContainer, headerClassName)}>
					{header && <header>{header}</header>}
					{showCloseIcon && (
						<i className="material-icons" onClick={onClose}>
							clear
						</i>
					)}
				</div>
			)}
			<div className={`${styles.kiModalBody} ${bodyClassName}`}>{children && children}</div>
			{!!actions.length && (
				<footer>
					{actions.map((action, idx) => {
						if (action.label === 'Cancel') {
							return (
								<KiButton
									key={idx}
									className={action.className}
									disabled={action.disabled}
									onClick={action.onClick}
									flat
									primary
								>
									{action.label}
								</KiButton>
							);
						} else {
							return (
								<KiButton
									key={idx}
									className={action.className}
									type={action.type}
									disabled={action.disabled}
									onClick={action.onClick}
									raised
									primary
									form={action.form}
								>
									{action.label}
								</KiButton>
							);
						}
					})}
				</footer>
			)}
		</Modal>
	);
};

KiModal.propTypes = {
	active: PropTypes.bool,
	className: PropTypes.string,
	header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClose: PropTypes.func,
	actions: PropTypes.array,
	children: PropTypes.node,
	showCloseIcon: PropTypes.bool,
	bodyClassName: PropTypes.string,
	headerClassName: PropTypes.string,
	contentLabel: PropTypes.string,
};

KiModal.defaultProps = {
	isOpen: false,
	className: '',
	header: '',
	actions: [],
	bodyClassName: '',
	contentLabel: '',
};

export default KiModal;

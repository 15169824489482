// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import options from 'ki-common/options';
import _get from 'lodash/get';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

// Project imports
import {KiSelect, KiCreatable} from 'components/KiSelect';
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import KiDatePicker from 'components/KiDatePicker';
import KiFormulaBuilder from 'components/KiFormulaBuilder';
import DebtFormulasList from 'components/ColumnPicker/forms/components/DebtFormulasList';
import {useMergedState} from 'utils/customHooks';
import {columnServiceApi, ratingsApi} from 'api';
import {fetchFundingVehicleSettingsByDatasetId} from 'api/fundingVehiclesApi';
import {splitFundingVehicleSettingsByType} from 'ki-common/utils/fundingVehicleUtil';
import {getDateColumnOutputFromFormula} from './DateForm';

// Local imports
import styles from './DebtForm.theme.scss';
import DebtFormCopyFormula from './DebtFormCopyFormula';

// TODO someday remove the numeric/boolean limit
let OUTPUT_OPTIONS = options.conditionalOutputDataTypes.concat([
	{
		label: 'Boolean',
		value: 'boolean',
	},
]);
OUTPUT_OPTIONS = OUTPUT_OPTIONS.filter(opt => ['boolean', 'numeric', 'date_long'].includes(opt.value));

const getFormatOptionsByType = dataType => {
	switch (dataType) {
		case 'string':
			return options.textFormats;
		case 'numeric':
			return options.numericFormats;
		case 'date_long':
			return options.dateFormats;
		case 'boolean':
		default:
			return options.booleanFormats;
	}
};

// Default assumption for this form is that this is a Numeric Debt calculation
// isWaterfall changes type to Waterfall (Numeric Waterfall calculation)
// isConditional changes the prefix to Conditional (Conditional Debt calculation)
function DebtForm({
	isDate,
	isConditional,
	isWaterfall,
	isAdmin,
	columnToEdit,
	existingTags,
	fundingVehicles,
	datasetId,
	submitMethod,
	closeForm,
	columns,
}) {
	const defaultDataType = isConditional ? 'boolean' : 'numeric';
	const [displayName, setDisplayName] = useState('');
	const [precision, setPrecision] = useState('2');
	const [precisionError, setPrecisionError] = useState(null);
	const [tags, setTags] = useState([]);
	const [calcEntityLevel, setCalcEntityLevel] = useState('fundingVehicle');
	const [outputState, setOutputState] = useMergedState({
		dataType: defaultDataType,
		displayFormat: getFormatOptionsByType(defaultDataType)[0].value,
		defaultValue: isConditional ? 'false' : '0.00',
	});
	const [formulaState, setFormulaState] = useMergedState({
		list: [],
		selected: null,
		selectedIndex: null,
	});
	const [usedFVs, setUsedFVs] = useState([]);
	const [copySelected, setCopySelected] = useState();
	const [nameError, setNameError] = useState('');
	//const [columns, setColumns] = useState([]); // Only used for the DebtFormulasList
	const [agencyRatings, setAgencyRatings] = useState([]); // Only used for the DebtFormulasList for ratings column
	const [entities, setEntities] = useMergedState({
		all: [],
		tranches: [],
		creditSupports: [],
		fees: [],
		triggers: [],
	});
	const [usedEntitiesByFV, setUsedEntitiesByFV] = useState([]);
	const [isSaving, setIsSaving] = useState(false);
	const [dateFunctionType, setDateFunctionType] = useState('');

	// Sometimes the column is passed in pre-hydrated, this can cause errors with both editing and saving
	// so here we will store another version that can be fetched based off the original _id
	const [baseColumnToEdit, setBaseColumnToEdit] = useState({});
	useEffect(
		() => {
			async function fetchColumn(datasetId, columnId, isInEdit) {
				const res = await columnServiceApi.getColumnByIdFromService(datasetId, columnId, false);
				delete res.detailedDisplayName;
				delete res.htmlDisplayName;
				if (isInEdit) {
					res._id = null;
					res.displayName = `Copy of ${res.displayName}`;
				}
				setBaseColumnToEdit(res);
			}
			if (columnToEdit) {
				const isInEdit = !!columnToEdit.original_id;
				fetchColumn(columnToEdit.datasetId, isInEdit ? columnToEdit.original_id : columnToEdit._id, isInEdit);
			}
		},
		[columnToEdit]
	);

	// On update of baseColumnToEdit
	useEffect(
		() => {
			if (!_.isEmpty(baseColumnToEdit)) {
				setDisplayName(baseColumnToEdit.displayName);
				setPrecision(baseColumnToEdit.precision);
				setTags(baseColumnToEdit.tags);
				setOutputState({
					dataType: baseColumnToEdit.dataType,
					displayFormat: baseColumnToEdit.displayFormat,
					defaultValue: baseColumnToEdit.defaultValue,
				});
				setFormulaState({list: baseColumnToEdit.formulas});
				setCalcEntityLevel(baseColumnToEdit.calcEntityLevel);
				if (isDate) {
					setDateFunctionType(_.get(baseColumnToEdit, 'formulas.0.formula.op'));
				}
			}
		},
		[baseColumnToEdit]
	);

	useEffect(
		() => {
			async function fetchAgencyRatings(agencyId) {
				const res = await ratingsApi.fetchRatingsOptionsByAgency(agencyId);
				setAgencyRatings(res);
			}
			// Make a list out of all the funding vehicles for each formula
			setUsedFVs(formulaState.list.reduce((acc, {fundingVehicles = []}) => [...acc, ...fundingVehicles], []));
			if (!isDate) {
				// date column formulas are objects instead of arrays so skip over those because you can't .find() on them
				const formula = _get(formulaState, 'list[0].formula', []);
				const hasRating = formula.find(f => f.dataType === 'rating');
				const agencyColumn = formula.find(f => f.columnType === 'absRating');
				if (hasRating && agencyColumn) {
					fetchAgencyRatings(agencyColumn.value);
				}
			}

			// Loop formulas and create/update usage lists
			// [fv_id]: [arrray_of_entity_ids]
			const usedByFVs = formulaState.list.reduce((output, formula) => {
				const fvId = `${formula.fundingVehicles[0]}`;
				const used = _.get(output, fvId, []);
				used.push(...formula.entityIds);
				_.set(output, fvId, used);
				return output;
			}, {});
			setUsedEntitiesByFV(usedByFVs);
		},
		[formulaState]
	);

	// Fetch columns every time the datasetId changes
	/*
	// Not anymore...we should be getting columns as a property now
	useEffect(
		() => {
			async function fetchData() {
				const params = {
					sources: {},
					options: {
						includeAllColumns: true,
					},
				};
				const res = await columnServiceApi.getColumnsFromService(datasetId, params);
				setColumns(res);
			}
			if (datasetId) {
				fetchData();
			}
		},
		[datasetId]
	);
	*/

	// If the datasetId changes re-fetch Fees, Credit Supports, and Tranches (debt)
	useEffect(
		() => {
			async function fetchData() {
				const res = await fetchFundingVehicleSettingsByDatasetId(datasetId);
				const splitSettings = splitFundingVehicleSettingsByType(res);

				const params = {
					sources: {
						includeTriggerColumns: true,
					},
				};
				const triggers = await columnServiceApi.getColumnsFromService(datasetId, params);

				setEntities({
					tranches: splitSettings.debt,
					creditSupports: splitSettings.creditSupports,
					fees: splitSettings.fees,
					triggers,
					all: _.concat(splitSettings.debt, splitSettings.creditSupports, splitSettings.fees, triggers),
				});
			}
			if (datasetId) {
				fetchData();
			}
		},
		[datasetId]
	);

	const getUnusedFVOptions = () => {
		const usable = fundingVehicles.filter(fv => !usedFVs.includes(fv._id));
		return usable;
	};

	// Locks some values from edit if formulas have been added or the column was already saved
	const isLocked = !!baseColumnToEdit._id || !!formulaState.list.length;

	// Minimum Required changes for a save
	const isSaveDisabled = !displayName.trim().length || !formulaState.list.length;

	const formatOptions = getFormatOptionsByType(outputState.dataType);

	/*
	OUTPUT
	{
		formula: [],
		fundingVehicles: [],
		dateType: 'reportDate', //unused until we add date and period selectors
		period: 'all', // unused until we add date and period selectors
	}
	*/
	const handleConfirm = (formulaList, fvList, entityIdList) => {
		// Prevent mutation
		const formulas = _.cloneDeep(formulaState.list);
		const newFormula = {
			formula: formulaList,
			fundingVehicles: fvList,
			entityIds: entityIdList,
			dateType: 'reportDate', //unused until we add date and period selectors
			period: 'all', // unused until we add date and period selectors
		};
		if (!Number.isInteger(formulaState.selectedIndex)) {
			formulas.unshift(newFormula);
		} else {
			formulas[formulaState.selectedIndex] = newFormula;
		}

		setFormulaState({
			list: formulas,
			selected: null,
			selectedIndex: null,
		});

		return Promise.resolve(formulas);
	};

	const isDuplicateNameError = () => {
		// Should be able to keep the same name when editing a column
		if (_.get(baseColumnToEdit, 'displayName') === displayName) {
			return;
		}
		return columns.find(
			col => (!isWaterfall || col.isWaterfall) && _.get(col, 'displayName', '').trim() === displayName.trim()
		);
	};

	const onSubmit = () => {
		setIsSaving(true);
		if (!displayName.trim()) {
			setNameError("Name can't be blank");
			setIsSaving(false);
			return false;
		}
		if (isDuplicateNameError()) {
			setNameError('Name is already in use');
			setIsSaving(false);
			return false;
		}
		if (precisionError) {
			setIsSaving(false);
			return false;
		} else {
			setPrecisionError(null);
		}
		const columnForSaving = {
			_id: baseColumnToEdit ? baseColumnToEdit._id : null,
			columnName: _.snakeCase(displayName),
			displayName: displayName,
			dateType: 'reportDate', //unused until we add date and period selectors
			period: 'all', // unused until we add date and period selectors
			defaultValue: outputState.defaultValue,
			tags: tags,
			calcEntityLevel: calcEntityLevel,
			displayFormat: outputState.displayFormat,
			dataType: outputState.dataType,
			columnType: 'debtCalculation',
			columnFormType: isDate ? 'date' : isConditional ? 'conditional' : 'numeric',
			formulas: formulaState.list,
			createdBy: baseColumnToEdit ? baseColumnToEdit.createdBy : null,
			isGlobal: true, //force global
			isWaterfall: isWaterfall,
		};
		if (isDate) {
			// if this is a debt date column, derive the dataType, displayFormat, and defaultValue from the first formula
			Object.assign(columnForSaving, getDateColumnOutputFromFormula(_.get(formulaState, 'list.0.formula')));
		}
		if (isWaterfall) {
			columnForSaving.precision = precision;
		}
		return submitMethod(columnForSaving);
	};

	const handleCancelCopy = () => {
		setCopySelected(null);
	};

	const handleConfirmCopy = (selectedFV, entityIds) => {
		// Prevent mutation
		const formulas = _.cloneDeep(formulaState.list);
		const newFormula = _.cloneDeep(copySelected);

		newFormula.fundingVehicles = [selectedFV];
		newFormula.entityIds = entityIds;
		formulas.push(newFormula);
		setFormulaState({list: formulas});
		handleCancelCopy();
	};

	const onPrecisionChange = val => {
		if (val && parseInt(val) < 11) {
			setPrecisionError(null);
		} else {
			setPrecisionError('Precision valune must be between 1 and 10!');
		}
		setPrecision(val);
	};

	return (
		<React.Fragment>
			<section className="column-selector-form inline-column-form" style={{flex: 0}}>
				{isDate && (
					<React.Fragment>
						<span className="form-instruction">Function:</span>
						<KiSelect
							value={options.dateFunctions.find(opt => opt.value === dateFunctionType)}
							isClearable={false}
							isDisabled={!!(formulaState.list || []).length}
							options={options.dateFunctions}
							onChange={selected => setDateFunctionType(selected.value)}
						/>
					</React.Fragment>
				)}
				<KiInput
					readOnly={!isAdmin}
					label="Name"
					value={displayName}
					onChange={val => setDisplayName(val)}
					error={nameError}
				/>
				<span className="form-instruction">Tags</span>
				<KiCreatable
					isDisabled={!isAdmin}
					classNamePrefix="aut-select"
					isMulti={true}
					options={existingTags.map(t => ({
						value: t,
						label: t,
					}))}
					value={tags.map(t => ({
						value: t,
						label: t,
					}))}
					onChange={val => setTags(val.map(t => t.value))}
					placeholder="Add some tags"
					readonly={!isAdmin}
				/>
				{!isWaterfall && (
					<React.Fragment>
						<span className="form-instruction">Entity Type:</span>
						<KiSelect
							isClearable={false}
							options={options.debt.entityOptions}
							value={options.debt.entityOptions.find(opt => opt.value === calcEntityLevel)}
							isDisabled={isLocked}
							onChange={selected => setCalcEntityLevel(selected.value)}
						/>
					</React.Fragment>
				)}
				<div className={styles.quickRow}>
					{isConditional && (
						<div className={styles.calcFormSection}>
							<span className="form-instruction">Output Data Type:</span>
							<KiSelect
								isClearable={false}
								options={OUTPUT_OPTIONS}
								isOptionDisabled={opt => isWaterfall && opt.value === 'date_long'}
								value={OUTPUT_OPTIONS.find(opt => opt.value === outputState.dataType)}
								isDisabled={isLocked}
								onChange={selected =>
									setOutputState({
										dataType: selected.value,
										displayFormat: getFormatOptionsByType(selected.value)[0].value,
										defaultValue:
											selected.value === 'date_long'
												? '1900-01-01'
												: selected.value === 'numeric'
													? '0.00'
													: 'false',
									})
								}
							/>
						</div>
					)}
					{outputState.dataType === 'numeric' &&
						isWaterfall && (
							<div className={styles.calcFormSection}>
								<KiInput
									readOnly={!isAdmin}
									label="Precision"
									value={precision}
									onChange={val => onPrecisionChange(val)}
									error={precisionError}
								/>
							</div>
						)}
					{!isDate &&
						!isWaterfall && (
							<div className={styles.calcFormSection}>
								<span className="form-instruction">Format:</span>
								<KiSelect
									isDisabled={!isAdmin}
									isClearable={false}
									options={formatOptions}
									value={formatOptions.find(x => x.value === outputState.displayFormat)}
									onChange={selected => setOutputState({displayFormat: selected.value})}
								/>
							</div>
						)}
				</div>
				{!isDate && (
					<div className={styles.quickRow}>
						<div className={styles.calcFormSection}>
							<span className="form-instruction">Default Value:</span>
							{// Default Value field changes based on dataType
							outputState.dataType === 'boolean' && (
								<KiSelect
									value={options.booleanConstants.find(
										opt =>
											opt.value === outputState.defaultValue ||
											`${opt.value}` === outputState.defaultValue
									)}
									options={options.booleanConstants}
									onChange={selected => setOutputState({defaultValue: selected.value})}
								/>
							)}
							{// Default Value field changes based on dataType
							outputState.dataType === 'numeric' && (
								<KiInput
									className={styles.numericDefault}
									value={outputState.defaultValue}
									isNumberMasked={true}
									onChange={val => setOutputState({defaultValue: val})}
								/>
							)}
							{// Default Value field changes based on dataType
							outputState.dataType === 'date_long' && (
								<KiDatePicker
									className={styles.dateDefault}
									value={outputState.defaultValue}
									onChange={val => setOutputState({defaultValue: dateToShortDate(val)})}
								/>
							)}
						</div>
					</div>
				)}
			</section>
			<DebtFormulasList
				isDate={!!isDate}
				isAdmin={isAdmin}
				isWaterfall={isWaterfall}
				formulas={formulaState.list}
				columns={columns}
				agencyRatings={agencyRatings}
				entities={entities.all}
				calcEntityLevel={calcEntityLevel}
				fundingVehicles={fundingVehicles}
				onCopy={formula => {
					setCopySelected(formula);
				}}
				onEdit={(formula, idx) => {
					setFormulaState({
						selected: formula,
						selectedIndex: Number.isInteger(idx) ? idx : null,
					});
				}}
				onDelete={idx =>
					setFormulaState({
						selected: null,
						selectedIndex: null,
						list: formulaState.list.filter((x, fidx) => idx !== fidx),
					})
				}
			/>
			<section className="format-and-save">
				<div className="inline-column-form-buttons">
					<KiButton flat primary onClick={() => closeForm()}>
						Cancel
					</KiButton>
					<KiButton
						disabled={!isAdmin || isSaveDisabled || isSaving}
						raised
						primary
						onClick={() => onSubmit()}
					>
						Save
					</KiButton>
				</div>
			</section>
			{formulaState.selected && (
				<KiFormulaBuilder
					isDebtFormula={!isWaterfall}
					isDate={!!isDate}
					dateFunctionType={dateFunctionType}
					isConditional={isConditional}
					isWaterfall={isWaterfall}
					datasetId={datasetId}
					columnId={_.get(baseColumnToEdit, '_id', '')}
					outputFormat={outputState.dataType}
					formulaArray={formulaState.selected.formula}
					formulaFVArray={formulaState.selected.fundingVehicles}
					allFundingVehicles={fundingVehicles}
					usedFundingVehicles={usedFVs}
					calcEntityLevel={calcEntityLevel}
					formulaEntityArray={formulaState.selected.entityIds}
					usedEntitiesByFV={usedEntitiesByFV}
					onCancel={() =>
						setFormulaState({
							selected: null,
							selectedIndex: null,
						})
					}
					onApply={handleConfirm}
				/>
			)}
			{copySelected && (
				<DebtFormCopyFormula
					applyMethod={handleConfirmCopy}
					cancelMethod={handleCancelCopy}
					isWaterfall={isWaterfall}
					fvList={calcEntityLevel === 'fundingVehicle' ? getUnusedFVOptions() : fundingVehicles}
					entityType={calcEntityLevel}
					entityList={_.get(entities, `[${calcEntityLevel}s]`, [])}
					usedEntitiesByFV={usedEntitiesByFV}
				/>
			)}
		</React.Fragment>
	);
}

DebtForm.propTypes = {
	isDate: PropTypes.bool,
	isConditional: PropTypes.bool,
	isWaterfall: PropTypes.bool,
	isAdmin: PropTypes.bool,
	columnToEdit: PropTypes.object,
	existingTags: PropTypes.array.isRequired,
	fundingVehicles: PropTypes.array,
	datasetId: PropTypes.string,
	submitMethod: PropTypes.func.isRequired,
	closeForm: PropTypes.func.isRequired,
	columns: PropTypes.array.isRequired,
};

DebtForm.defaultProps = {
	isDate: false,
	isConditional: false,
	isWaterfall: false,
	isAdmin: false,
	columnToEdit: {},
	fundingVehicles: [],
	datasetId: '',
};

export default DebtForm;

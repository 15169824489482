// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Project imports
import options from 'ki-common/options';

// Website imports
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import KiSelect from 'components/KiSelect';

// Local imports
import ConstraintHelpers from './constraintHelpers';
import styles from './constraintFormStyles.theme.scss';
import ccBalanceLimit from './validators/ccBalanceLimit';
import validators from 'ki-common/validators';

function BalanceLimitForm({
	constraintData,
	setConstraintItem,
	dataColumns,
	loadingColumns,
	isGroupBy,
	isReadOnly,
	updateFormHasError,
}) {
	const columnOptions = ConstraintHelpers.getCohortColumns(
		dataColumns,
		constraintData.dataColumn,
		true, // isNew
		isGroupBy,
		true // onlyNumeric
	);

	const constraints = ccBalanceLimit.getConstraints();
	const [formError, setFormError] = useState('');
	const isDateValue =
		constraintData.dataColumn?.dataType === 'date_long' || constraintData.dataColumn?.dataType === 'date_short'
			? true
			: false;

	const validateForm = val => {
		const errors = validators.validate(val, constraints);
		setFormError(errors);
	};

	useEffect(
		() => {
			validateForm(constraintData);
		},
		[constraintData]
	);

	return (
		<div className={styles.root}>
			<div className={styles.constraintFormBody}>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Data Column</span>
					<KiSelect
						isDisabled={isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.dataColumn}
						isLoading={loadingColumns}
						isClearable={false}
						options={columnOptions}
						onChange={dc => {
							setConstraintItem('dataColumn', dc);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Logic</span>
					<KiSelect
						isDisabled={!constraintData.dataColumn || isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.logic}
						isClearable={false}
						options={options.balanceLimitOptions}
						onChange={l => {
							setConstraintItem('logic', l);
						}}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<KiInput
						disabled={isReadOnly}
						type="text"
						name="name"
						label={isDateValue ? 'YYYY-MM-DD' : 'Target'}
						isNumberMasked={!isDateValue}
						value={constraintData.target && constraintData.target.toString()}
						onChange={t => {
							setConstraintItem('target', [t]);
						}}
						error={formError?.target}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<KiCheckbox
						disabled={isReadOnly}
						checked={constraintData.excludeZeroBalance}
						label="Exclude zero balance"
						onChange={val => {
							setConstraintItem('excludeZeroBalance', val);
						}}
					/>
				</div>
				{updateFormHasError(formError ? true : false)}
			</div>
		</div>
	);
}

BalanceLimitForm.propTypes = {
	constraintData: PropTypes.object,
	setConstraintItem: PropTypes.func,
	dataColumns: PropTypes.array,
	loadingColumns: PropTypes.bool,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	updateFormHasError: PropTypes.func,
};

BalanceLimitForm.defaultProps = {
	constraintData: {},
};

export default BalanceLimitForm;

// Globals
import React, {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import _ from 'lodash';
import {DragDropContext} from 'react-beautiful-dnd';
// Project imports
import KiInput from 'components/KiInput';
// Local imports
import FundingAnalysisContext from '../fundingAnalysisContext';
import styles from './fundingModelWizard.theme.scss';
import FundingVehicleDragList from './FundingVehicleDragList';
import {reduceStepNumbers} from './CombiningDragUtil';
/**
 * [FundingAnalysis description]
 * @param {[type]} options.isDebtFormula       [description]
 */
function WizardStepOne() {
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const {datasetId} = useParams();
	const allFundingVehicles = useSelector(state => state.fundingVehicleList.data);
	const [fvSources, setFvSources] = useState([]);
	const [fvTargets, setfVTargets] = useState([]);
	const [fvTargets2, setfVTargets2] = useState([]);
	const [nameError, setNameError] = useState('');

	useEffect(
		() => {
			let fvs = _.cloneDeep(allFundingVehicles).filter(fv => fv.datasetId === datasetId);
			fvs = fvs.map(fv => {
				return {
					fvId: fv._id,
					fvName: fv.name,
					priority: 0,
					isUnencumbered: fv.isUnencumbered || false,
				};
			});
			if (fundingAnalysisContext.fvSources.length) {
				const usedFvs = [...fundingAnalysisContext.fvSources, ...fundingAnalysisContext.fvTargets];
				const unusedFvs = fvs.filter(fv => !usedFvs.find(fv1 => fv1.fvId === fv.fvId));
				setFvSources(_.cloneDeep(unusedFvs));
				setfVTargets(_.cloneDeep(fundingAnalysisContext.fvSources));
				setfVTargets2(_.cloneDeep(fundingAnalysisContext.fvTargets));
			} else {
				setFvSources(fvs);
				setfVTargets([]);
				setfVTargets2([]);
				fundingAnalysisContext.setStateItem('allDraggableFvs', fvs);
				fundingAnalysisContext.setModelItem('fvSources', []);
				fundingAnalysisContext.setModelItem('fvTargets', []);
			}
			setNameError('');
		},
		[allFundingVehicles]
	);

	const setSources = (destGroup, result) => {
		let mappedResult = _.cloneDeep(result);
		switch (destGroup) {
			case 0:
				fundingAnalysisContext.setStateItem('allDraggableFvs', mappedResult);
				setFvSources(result);
				break;
			case 1:
				mappedResult = mappedResult.map(s => {
					s.fvType = 'source';
					return s;
				});
				fundingAnalysisContext.setModelItem('fvSources', mappedResult);
				setfVTargets(result);
				break;
			case 2:
				mappedResult = mappedResult.map(s => {
					s.fvType = 'source';
					return s;
				});
				fundingAnalysisContext.setModelItem('fvTargets', mappedResult);
				setfVTargets2(result);
				break;
		}
	};

	const breakGrouping = (steps, index) => {
		const targetGroup = steps[index].priority;

		const itemsToBreak = steps.filter(item => {
			return item.priority === targetGroup;
		});

		const totalInGroup = itemsToBreak.length;

		let stepsAdded = 0;
		steps.forEach(step => {
			if (step.priority === targetGroup) {
				step.priority = targetGroup + stepsAdded;
				stepsAdded++;
			} else if (step.priority > targetGroup) {
				step.priority += totalInGroup;
			}
		});
		return steps;
	};

	const onStepGroupBroken = (index, droppableId) => {
		let result = [[...fvSources], [...fvTargets], [...fvTargets2]][parseInt(droppableId)];
		result = reduceStepNumbers(breakGrouping(result, index));
		setSources(parseInt(droppableId), result);
	};

	const onStepsCombined = result => {
		const {destination, combine, source} = result;
		const sourceGroup = parseInt(source.droppableId);
		const destGroup = parseInt(combine.droppableId);
		const groups = [[...fvSources], [...fvTargets], [...fvTargets2]];
		if (sourceGroup === destGroup) {
			let result = Array.from(groups[destGroup]);
			const destObject = result.find(
				item => combine.droppableId + (item.id || item.fvId) === combine.draggableId
			);
			const [removedSame] = result.splice(source.index, 1);
			removedSame.priority = destObject.priority;
			result.splice(parseInt(combine.droppableId), 0, removedSame);
			result = reduceStepNumbers(result);
			setSources(destGroup, result);
			return;
		}

		//new source array (item was removed from)
		const sourceResult = Array.from(groups[sourceGroup]);
		//removed item
		const [removedDiff] = sourceResult.splice(source.index, 1);
		//reconfigure group numbers
		sourceResult.forEach((fv, index) => {
			if (index >= source.index) {
				fv.priority = index + 1;
			}
		});

		//new dest array (item was added from)
		const destResult = Array.from(groups[destGroup]);
		destResult.splice(destination.index, 0, removedDiff);
		//reconfigure group numbers
		destResult.forEach((fv, index) => {
			if (index >= destination.index) {
				fv.priority = index + 1;
			}
		});
		setSources(destGroup, destResult);
		setSources(sourceGroup, sourceResult);
	};

	const setNewPriorities = (result, destinationIndex) => {
		// If item is moved between combined items it needs to have the same priority
		const itemBeforePriority = result[destinationIndex - 1]?.priority;
		const itemAfterPriority = result[destinationIndex + 1]?.priority;
		if (itemAfterPriority === itemBeforePriority) {
			result[destinationIndex].priority = itemBeforePriority;
		}
		let prevOldPriority = null;
		// Combined items are preserved
		return result.map((fv, index) => {
			const oldPriority = fv.priority;
			if (index === 0) {
				fv.priority = 1;
			} else if (oldPriority === prevOldPriority) {
				fv.priority = result[index - 1].priority;
			} else {
				fv.priority = result[index - 1].priority + 1;
			}
			prevOldPriority = oldPriority;

			return fv;
		});
	};

	const onItemsReordered = result => {
		const {destination, source} = result;
		const sourceGroup = parseInt(source.droppableId);
		const destGroup = parseInt(destination.droppableId);
		const groups = [[...fvSources], [...fvTargets], [...fvTargets2]];

		if (sourceGroup === destGroup) {
			const result = Array.from(groups[destGroup]);
			const [removedSame] = result.splice(source.index, 1);
			result.splice(destination.index, 0, removedSame);
			const newResult = setNewPriorities(result, destination.index);
			setSources(destGroup, newResult);
			return;
		}
		//new source array (item was removed from)
		const sourceResult = Array.from(groups[sourceGroup]);
		//removed item
		const [removedDiff] = sourceResult.splice(source.index, 1);
		//reconfigure group numbers
		sourceResult.forEach((fv, index) => {
			if (index >= source.index) {
				fv.priority = index + 1;
			}
		});
		//new dest array (item was added from)
		const destResult = Array.from(groups[destGroup]);
		destResult.splice(destination.index, 0, removedDiff);
		// Removing priority of item moved for the outside so that it's not mixed with destination items
		destResult[destination.index].priority = null;
		const newDestResult = setNewPriorities(destResult, destination.index);
		setSources(destGroup, newDestResult);
		setSources(sourceGroup, sourceResult);
	};

	const onDragEnd = result => {
		const {destination, combine, source} = result;
		if (!destination && !combine) {
			return;
		}
		if (!destination && combine) {
			onStepsCombined(result);
			return;
		}
		if (_.get(destination, 'droppableId') === source.droppableId && destination.index === source.index) {
			return;
		}
		onItemsReordered(result);
	};

	return (
		<div className={styles.root}>
			<FundingAnalysisContext.Consumer>
				{fundingAnalysisContext => (
					<React.Fragment>
						<div className={styles.header}>
							<KiInput
								type="text"
								name="name"
								label="Model Name"
								error={nameError}
								value={fundingAnalysisContext.name}
								onChange={val => {
									if (fundingAnalysisContext.allModels.find(m => m.name == val)) {
										setNameError('Name must be unique');
									} else {
										setNameError('');
									}
									fundingAnalysisContext.setModelItem('name', val);
								}}
								maxLength={100}
								className={styles.modelName}
							/>
						</div>
						<section className={styles.dragContainer}>
							<DragDropContext onDragEnd={onDragEnd}>
								<div className={styles.sourcesTargetsRow}>
									<div className={styles.sourcesTargetsPanel}>
										<div className={styles.sectionHeader}>
											<p className={styles.detailSectionHeader}>ALL FUNDING VEHICLES</p>
										</div>
										<div className={styles.sectionContent}>
											<FundingVehicleDragList
												itemList={fvSources}
												droppableId={'0'}
												onStepGroupBroken={onStepGroupBroken}
												isCombineEnabled={false}
											/>
										</div>
									</div>
									<div className={styles.panelDivider} />
									<div className={styles.sourcesTargetsPanel}>
										<div className={styles.sectionHeader}>
											<p className={styles.detailSectionHeader}>SOURCES</p>
										</div>
										<div className={styles.sectionContent}>
											<FundingVehicleDragList
												itemList={fvTargets}
												droppableId={'1'}
												isOrderEnabled={true}
												onStepGroupBroken={onStepGroupBroken}
											/>
										</div>
									</div>
									<div className={styles.rightArrow}>
										<span className={'material-icons'}>arrow_forward</span>
									</div>
									<div className={styles.sourcesTargetsPanel}>
										<div className={styles.sectionHeader}>
											<p className={styles.detailSectionHeader}>TARGETS</p>
										</div>
										<div className={styles.sectionContent}>
											<FundingVehicleDragList
												itemList={fvTargets2}
												droppableId={'2'}
												onStepGroupBroken={onStepGroupBroken}
												isOrderEnabled={true}
												isCombineEnabled={false}
											/>
										</div>
									</div>
								</div>
							</DragDropContext>
						</section>
					</React.Fragment>
				)}
			</FundingAnalysisContext.Consumer>
			<p className={styles.dragInfoText}>** Drag/Reorder/Group Funding Vehicles to source and target lists</p>
		</div>
	);
}

export default WizardStepOne;

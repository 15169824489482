import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from '../kiList.theme.scss';

export class KiListItem extends Component {
	static propTypes = {
		className: PropTypes.string,
		caption: PropTypes.string,
		itemContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		icon: PropTypes.string,
		onClick: PropTypes.func,
	};

	static defaultProps = {
		children: '',
		className: '',
	};

	render() {
		return (
			<div className={`${styles.kiListItemIcon} ${this.props.className}`} onClick={this.props.onClick}>
				{this.props.icon && <i className="material-icons">{this.props.icon}</i>}
				<span>{this.props.itemContent || this.props.caption}</span>
			</div>
		);
	}
}
export default KiListItem;

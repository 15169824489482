// Globals
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports
import options from 'ki-common/options';

// Website imports
import KiInput from 'components/KiInput';
import KiSelect, {KiCreatable} from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';

// Local imports
import ConstraintHelpers from './constraintHelpers';
import styles from './constraintFormStyles.theme.scss';
import validators from 'ki-common/validators';
import ccExclude from './validators/ccExclude';

const constraintTypes = [
	{
		label: 'Both',
		value: 'both',
	},
	{
		label: 'Funding',
		value: 'funding',
	},
	{
		label: 'Drawdown',
		value: 'repurchase',
	},
	// None should not be an option for a groupBy funding vehicle
	{
		label: 'None',
		value: 'none',
	},
];

function ExclusionForm({
	constraintData,
	setConstraintItem,
	dataColumns,
	eligibleColumns,
	loadingColumns,
	isGroupBy,
	isReadOnly,
	isFundingAnalysisConstraint,
	updateFormHasError,
}) {
	let dataOptions = ConstraintHelpers.getCohortColumns(
		dataColumns,
		constraintData.dataColumn,
		true, // isNew
		isGroupBy,
		false // onlyNumeric
	);
	const constraints = ccExclude.getConstraints();
	const [formError, setFormError] = useState('');

	dataOptions = ConstraintHelpers.removeEligibleColumns(dataOptions, eligibleColumns);

	const getLogicValues = () => {
		const dataType = _.get(constraintData, 'dataColumn.dataType');
		if (dataType === 'numeric' || !dataType) {
			return options.logic.filter(opt => !['in', 'not_in'].includes(opt.value));
		}
		if (dataType !== 'string' || !dataType) {
			return options.logic;
		}
		return options.logic.filter(opt => ['=', '<>', 'in', 'not_in'].includes(opt.value));
	};

	const getTargetValues = () => {
		if (Array.isArray(constraintData.target)) {
			return constraintData.target;
		}
		return [];
	};

	const validateForm = val => {
		const errors = validators.validate(val, constraints);
		setFormError(errors);
	};

	useEffect(
		() => {
			validateForm(constraintData);
		},
		[constraintData]
	);

	return (
		<div className={styles.root}>
			<div className={styles.constraintFormBody}>
				{!isFundingAnalysisConstraint && (
					<div className={styles.selectWrapper}>
						<span className="theme-label">Constraint Type</span>
						<KiSelect
							isDisabled={isReadOnly || constraintData._id}
							classNamePrefix="aut-select"
							value={constraintTypes.find(opt => opt.value === constraintData.constraintType)}
							isClearable={false}
							options={isGroupBy ? constraintTypes.slice(0, 3) : constraintTypes}
							onChange={option => {
								setConstraintItem('constraintType', option.value);
								// Indicates that this MUST be an eligibility constraint
								if (option.value === 'none') {
									setConstraintItem('includeInEligibility', true);
								}
							}}
						/>
					</div>
				)}
				<div className={styles.selectWrapper}>
					<span className="theme-label">Data Column</span>
					<KiSelect
						key={constraintData.dataColumn}
						isDisabled={isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.dataColumn}
						isLoading={loadingColumns}
						isClearable={false}
						options={dataOptions}
						onChange={dc => {
							setConstraintItem('dataColumn', dc);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Logic</span>
					<KiSelect
						key={constraintData.logic}
						isDisabled={!constraintData.dataColumn || isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.logic}
						isClearable={false}
						options={getLogicValues()}
						onChange={l => {
							const keys = [];
							const values = [];
							keys.push('logic');
							values.push(l);
							keys.push('target');
							if (l.value === 'between') {
								values.push(['', '']);
							} else if (l.value === 'in' || l.value === 'not_in') {
								values.push([]);
							} else {
								values.push('');
							}
							setConstraintItem(keys, values);
						}}
					/>
				</div>
				<div className={styles.selectWrapper}>
					{constraintData.logic &&
						(constraintData.logic.value === 'in' || constraintData.logic.value === 'not_in') && (
							<React.Fragment>
								<span className="theme-label">Target</span>
								<KiCreatable
									isDisabled={isReadOnly}
									isMulti={true}
									type="text"
									placeholder={'Enter a value...'}
									clearable={false}
									noResultsText={false}
									isNumberMasked={_.get(constraintData, 'dataColumn.dataType') === 'numeric'}
									value={getTargetValues().map(t => ({
										value: t,
										label: t,
									}))}
									onChange={t => {
										setConstraintItem('target', t.map(t => t.value));
									}}
									errorMessage={formError?.target}
								/>
								{<div className={styles.ccConcentrationIncludesDateError}>{formError?.target}</div>}
							</React.Fragment>
						)}
					{constraintData.logic &&
						constraintData.logic.value === 'between' && (
							<div
								className={styles.selectWrapper}
								style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
							>
								<KiInput
									disabled={isReadOnly}
									type="text"
									label="Min"
									value={constraintData.target[0]}
									isNumberMasked={_.get(constraintData, 'dataColumn.dataType') === 'numeric'}
									onChange={t => {
										const newValue = [t, constraintData.target[1]];
										setConstraintItem('target', newValue);
									}}
									error={formError?.target}
								/>
								<KiInput
									disabled={isReadOnly}
									type="text"
									label="Max"
									value={constraintData.target[1]}
									isNumberMasked={_.get(constraintData, 'dataColumn.dataType') === 'numeric'}
									onChange={t => {
										const newValue = [constraintData.target[0], t];
										setConstraintItem('target', newValue);
									}}
									error={formError?.target}
								/>
							</div>
						)}
					{constraintData.logic &&
						constraintData.logic.value !== 'in' &&
						constraintData.logic.value !== 'not_in' &&
						constraintData.logic.value !== 'between' && (
							<KiInput
								disabled={isReadOnly}
								type="text"
								name="name"
								label={'Target'}
								isNumberMasked={_.get(constraintData, 'dataColumn.dataType') === 'numeric'}
								value={constraintData.target && constraintData.target.toString()}
								onChange={t => {
									setConstraintItem('target', [t]);
								}}
								error={formError?.target}
							/>
						)}
				</div>
				{!isFundingAnalysisConstraint && (
					<div className={styles.selectWrapper}>
						<KiCheckbox
							disabled={isReadOnly || isGroupBy || constraintData.constraintType === 'none'}
							checked={constraintData.includeInEligibility}
							label="Include in Eligibility Status Calculation"
							onChange={val => {
								setConstraintItem('includeInEligibility', val);
							}}
						/>
					</div>
				)}
				{updateFormHasError(formError ? true : false)}
			</div>
		</div>
	);
}

ExclusionForm.propTypes = {
	constraintData: PropTypes.object,
	setConstraintItem: PropTypes.func,
	dataColumns: PropTypes.array,
	eligibleColumns: PropTypes.array,
	loadingColumns: PropTypes.bool,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isFundingAnalysisConstraint: PropTypes.bool,
	updateFormHasError: PropTypes.func,
};

ExclusionForm.defaultProps = {
	constraintData: {},
};

export default ExclusionForm;

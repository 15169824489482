const TOTAL_BOOK_DATASET_ID = '0b172671-aa07-4631-90ca-49026ad0d7ae';
const TIME_SERIES_GRANULARITY_LIST = [
	{
		label: 'Daily',
		value: 'daily',
	},
	{
		label: 'Weekly',
		value: 'weekly',
	},
	{
		label: 'Weekly Fixed',
		value: 'weeklyFixed',
	},
	{
		label: 'Bi-Weekly',
		value: 'biWeekly',
	},
	{
		label: 'Bi-Weekly Fixed',
		value: 'biWeeklyFixed',
	},
	{
		label: 'Semimonthly',
		value: 'fortnightly',
	},
	{
		label: 'Monthly',
		value: 'monthly',
	},
	{
		label: 'Quarterly',
		value: 'quarterly',
	},
	{
		label: 'Semiannually',
		value: 'semiAnnually',
	},
	{
		label: 'Annually',
		value: 'annually',
	},
];
const DRAWDOWN_SCENARIO_TYPES = [
	'drawdown_and_funding',
	'drawdown_and_top_off',
	'balanced_funding',
	'balanced_and_top_off',
];

module.exports = {
	TOTAL_BOOK_DATASET_ID,
	TIME_SERIES_GRANULARITY_LIST,
	DRAWDOWN_SCENARIO_TYPES,
};

// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import _ from 'lodash';
import Select from 'react-select';

// Project imports
import {generateObjectId} from 'ki-common/utils/constraintUtil';

// Website imports
import {useMergedState} from 'utils/customHooks';
import {getColumnsFromService, getMappedColumnsForDataset, getEligibleColumnIds} from 'api/columnServiceApi';
import KiButton from 'components/KiButton';
import KiFontIcon from 'components/KiFontIcon';
import KiCheckbox from 'components/KiCheckbox';
import KiModal from 'components/KiModal';
import KiInput from 'components/KiInput';
import ConstraintForm from 'components/ConstraintForm';

// Local imports
import styles from './constraintsModalStyles.theme.scss';

function ConstraintsModal({
	isActive,
	setModalActive,
	fundingVehicles,
	curConstraintGroup,
	allConstraintGroups,
	fundingModel,
	isReadOnly,
	onSaveFunc,
}) {
	// Browser State
	const {datasetId} = useParams();

	// Redux State
	//const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));

	// Local State
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [fundingVehicle, setFundingVehicle] = useState(fundingVehicles[0]);
	const [allConstraints, setAllConstraints] = useState([]);
	const [fvConstraints, setFvConstraints] = useState([]);
	const [columnState, setColumnState] = useMergedState({
		areLoading: false,
		allColumns: [],
		eligibleColumns: [],
	});
	const [isGroupBy, setIsGroupBy] = useState(false);
	const [activeConstraint, setActiveConstraint] = useState({});

	const calculateObjectiveOptions = async () => {
		const mappedCols = await getMappedColumnsForDataset(datasetId);
		const {assetColumnId: assetId, _id: cohortId} = mappedCols.assetCol;

		if (fundingModel.groupBy && fundingModel.groupBy !== assetId && fundingModel.groupBy !== cohortId) {
			setIsGroupBy(true);
		} else {
			setIsGroupBy(false);
		}

		const allColumns = await getColumnsFromService(datasetId, {
			sources: {
				includeAssetColumns: true,
				includeAssetCalculations: true,
				includeBusinessFunctions: true,
				includeHistoricalColumns: true,
				includeDateColumns: true,
				includeAggregateColumns: true,
			},
			filters: {
				includeSyntheticColumns: false,
			},
		});

		const eligibleColumns = await getEligibleColumnIds(datasetId);

		setColumnState({
			areLoading: false,
			allColumns,
			eligibleColumns,
		});
	};

	useEffect(
		() => {
			const onModelLoad = async () => {
				if (isActive) {
					setColumnState({
						areLoading: true,
						allColumns: [],
						eligibleColumns: [],
					});
					await calculateObjectiveOptions();
				} else {
					setColumnState({
						areLoading: false,
						allColumns: [],
						eligibleColumns: [],
					});
				}
			};
			onModelLoad();

			setNameError('');
			setName(curConstraintGroup.constraintGroupName);
			setAllConstraints(_.cloneDeep(curConstraintGroup.constraints));
			setFundingVehicle(fundingVehicles[0]);
		},
		[isActive]
	);

	useEffect(
		() => {
			if (fundingVehicle) {
				let currentFvConstraints = _.cloneDeep(allConstraints || []);
				currentFvConstraints = currentFvConstraints.filter(c => {
					if (c.fundingVehicleId === fundingVehicle.fvId && fundingVehicle.fvType === 'source') {
						return c.constraintType === 'both' || c.constraintType === 'repurchase'; //repurchase
					}
					if (c.fundingVehicleId === fundingVehicle.fvId && fundingVehicle.fvType === 'target') {
						return c.constraintType === 'both' || c.constraintType === 'funding'; // funding
					}
					return false;
				});
				setFvConstraints(currentFvConstraints);
			}
		},
		[fundingVehicle, allConstraints]
	);

	// Load the selected constraint for edit
	const onEditConstraint = constraint => {
		setActiveConstraint(constraint);
	};

	// Change the selected constraint to a new constraint
	const clearConstraintForm = () => {
		setActiveConstraint({});
	};

	const onDeleteConstraint = constraintId => {
		const enabledConstraints = _.cloneDeep(allConstraints);
		const idx = enabledConstraints.findIndex(c => c._id === constraintId);
		enabledConstraints.splice(idx, 1);
		setAllConstraints(enabledConstraints);
		clearConstraintForm();
	};

	const onSaveModal = () => {
		const ac = _.cloneDeep(allConstraints).map(c => {
			c.ignore = c.isDisabled;
			return c;
		});
		const groupToSave = {
			constraintGroupName: name,
			fundingModelId: fundingModel._id,
			constraints: ac,
			isFVDefined: false,
		};
		if (curConstraintGroup._id) {
			groupToSave._id = curConstraintGroup._id;
		}
		onSaveFunc(groupToSave);
	};

	const onSaveNewConstraint = constraint => {
		const newConstraint = _.cloneDeep(constraint);
		const existingAllContraints = _.cloneDeep(allConstraints);

		if (newConstraint._id) {
			newConstraint.ignore = newConstraint.isDisabled;
			const idx = existingAllContraints.findIndex(c => c._id === newConstraint._id);
			existingAllContraints[idx] = newConstraint;
		} else {
			newConstraint._id = generateObjectId();
			newConstraint.isDisabled = false;
			newConstraint.ignore = false;
			existingAllContraints.push(newConstraint);
		}

		setAllConstraints(existingAllContraints);
		setActiveConstraint({});
	};

	if (!fundingVehicle) {
		return <div />;
	}

	return (
		<KiModal
			active={isActive}
			actions={[
				{
					label: 'Cancel',
					onClick: () => setModalActive(false),
				},
				{
					label: 'Save Constraint Group',
					disabled: !name || !!nameError || isReadOnly || columnState.areLoading,
					onClick: () => onSaveModal(),
				},
			]}
			onClose={() => setModalActive(false)}
			header={`${isReadOnly ? '' : 'Configure '}Constraints for: ${fundingModel.name}`}
		>
			<div className={styles.root}>
				{/*Left Column Panel*/}
				<section className={styles.leftColumn}>
					<div className={styles.selectWrapper}>
						<KiInput
							disabled={isReadOnly}
							type="text"
							name="name"
							label="Constraint Group Name"
							value={name}
							onChange={val => {
								if (
									allConstraintGroups
										.filter(cg => cg._id !== curConstraintGroup._id)
										.find(cg => cg.constraintGroupName === val)
								) {
									setNameError('Name must be unique');
								} else {
									setNameError('');
								}
								setName(val);
							}}
							error={nameError}
						/>
					</div>
					<div className={styles.selectWrapper}>
						<span className="theme-label">Scenario Funding Vehicles</span>
						<Select
							classNamePrefix="aut-select"
							value={fundingVehicle}
							isClearable={false}
							options={fundingVehicles}
							onChange={val => {
								clearConstraintForm();
								setFundingVehicle(val);
							}}
							getOptionLabel={option => option.fvName}
							getOptionValue={option => option.fvId}
						/>
					</div>

					<div className={styles.constraintListHeader}>
						<KiButton
							primary
							disabled={isReadOnly}
							label="Enable All"
							onClick={() => {
								const enabledConstraints = _.cloneDeep(allConstraints);
								enabledConstraints.forEach(c => (c.isDisabled = false));
								setAllConstraints(enabledConstraints);
								setFundingVehicle(fundingVehicle);
							}}
						/>
						<KiButton
							primary
							disabled={isReadOnly}
							label="Disable All"
							onClick={() => {
								const enabledConstraints = _.cloneDeep(allConstraints);
								enabledConstraints.forEach(c => (c.isDisabled = true));
								setAllConstraints(enabledConstraints);
								setFundingVehicle(fundingVehicle);
							}}
						/>
						<KiButton
							primary
							disabled={isReadOnly}
							label="Add Constraint"
							className={styles.addConstraintButton}
							onClick={() => clearConstraintForm()}
						/>
					</div>

					<div className={styles.constraintList}>
						{fvConstraints.map((item, index) => {
							return (
								<div key={index} className={styles.constraintListItem}>
									<KiCheckbox
										checked={!item.isDisabled}
										label={''}
										disabled={isReadOnly}
										onChange={val => {
											const enabledConstraints = _.cloneDeep(allConstraints);
											const idx = enabledConstraints.findIndex(c => c._id === item._id);
											enabledConstraints[idx].isDisabled = !val;
											setAllConstraints(enabledConstraints);
											setFundingVehicle(fundingVehicle);
										}}
									/>
									<div className={styles.constraintName} onClick={() => onEditConstraint(item)}>
										{item.name}
									</div>
									<div>
										<KiFontIcon
											className="list-icon-btn"
											value={isReadOnly ? 'info' : 'edit'}
											onClick={() => onEditConstraint(item)}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</section>
				{/*Right Column Panel*/}
				<section className={styles.rightColumn}>
					<ConstraintForm
						constraintData={activeConstraint}
						fundingVehicleId={fundingVehicle.fvId}
						fvConstraints={fvConstraints}
						allColumns={columnState.allColumns}
						eligibleColumns={columnState.eligibleColumns}
						onSaveMethod={onSaveNewConstraint}
						onDeleteMethod={onDeleteConstraint}
						isReadOnly={isReadOnly}
						isGroupBy={isGroupBy}
						isFundingAnalysisConstraint={true}
					/>
					{!isReadOnly && (
						<p style={{fontSize: '12px'}}>
							** Editing a Funding Vehicle constraint results in creation of a custom constraint
						</p>
					)}
				</section>
			</div>
			<div className={styles.horizontalRow} />
		</KiModal>
	);
}

ConstraintsModal.propTypes = {
	isActive: PropTypes.bool,
	setModalActive: PropTypes.func,
	curConstraintGroup: PropTypes.object,
	allConstraintGroups: PropTypes.array,
	fundingVehicles: PropTypes.array,
	fundingModel: PropTypes.object.isRequired,
	isReadOnly: PropTypes.bool,
	onSaveFunc: PropTypes.func,
};

ConstraintsModal.defaultProps = {
	isActive: false,
	curConstraintGroup: {
		constraintGroupName: '',
		constraints: [],
		isFVDefined: false,
	},
	allConstraintGroups: [],
};

export default ConstraintsModal;

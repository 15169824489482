// Global imports
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import options from 'ki-common/options';

// Project imports
import KiCardDebt from 'components/KiCardDebt';
import KiCardExplorer from 'components/KiCardExplorer';
import KiCardForecasting from 'components/KiCardForecasting';
import KiCardPayments from 'components/KiCardPayments';
import KiProgressBar from 'components/KiProgressBar';
import KiCardPreview from 'components/KiCardPreview';
import {dataBookmarksApi, fundingVehiclesApi, debtExplorerApi, columnServiceApi} from 'api';

// Local imports
import './KiCard.scss';

function KiCard(props) {
	const {fundingVehicleId, scenarioId, card, reportDefinition} = props;
	const [fundingVehicles, setFundingVehicles] = useState(props.fundingVehicles || []);
	const [datasetColumns, setDatasetColumns] = useState({});
	const [view, setView] = useState({});
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [displayType, setDisplayType] = useState('');

	// handle general load for all cards when card id changes
	useEffect(
		() => {
			// reject invalid config
			if (!props.card) {
				//setError('Card ID required');
				return;
			}
			setError('');
			async function getData() {
				let view;
				setLoading(true);
				const fundingVehicles = await fundingVehiclesApi.fetchFundingVehiclesByDatasetId(card.datasetId);
				setFundingVehicles(fundingVehicles);
				const columns =
					(await columnServiceApi.getColumnsFromService(card.datasetId, {
						sources: {
							includeAssetColumns: true,
							includeCohortColumns: true,
							includeAggregateColumns: true,
							includeAssetCalculations: true,
							includeBusinessCalculations: true,
							includeDebtCalculations: true,
							includeFundingVehicle: true,
							includeBorrowingBase: true,
							includeWaterfallCalculations: true,
							includeDateColumns: true,
						},
						filter: {},
						filters: {},
						options: {
							embedColumns: false,
						},
					})) || [];
				setDatasetColumns(columns);
				switch (card.settings.type) {
					case 'tabular':
						setDisplayType('explorer');
						view = (await dataBookmarksApi.fetchBookmark(card.bookmarkId)) || {};
						break;
					case 'debtView':
						setDisplayType('debt');
						view = (await debtExplorerApi.fetchBookmark(card.settings.debtBookmarkId)) || {};
						break;
					case 'fundingAnalysisView':
						setDisplayType('fundingAnalysis');
						//view = (await fundingAnalysisApi.fetchBookmarkById(card.settings.fundingAnalysisBookmarkId)) || {};
						break;
					case 'projections':
						setDisplayType('projections');
						/*
						view =
							(await fundingAnalysisApi.fetchBookmarkById(card.settings.fundingAnalysisBookmarkId)) || {};
						*/
						break;
					case 'aggregatedPayment':
						setDisplayType('payments');
						break;
					case 'chart':
						break;
				}
				// save data to state
				setView(view);
			}
			// call fetch
			getData()
				.then(() => {
					setLoading(false);
				})
				.catch(err => {
					setLoading(false);
					setError(err.message);
				});
		},
		[props.card]
	);

	if (displayType === 'explorer' && !_.get(view, 'explorerData') && !error) return null;

	return (
		<React.Fragment>
			{loading && <KiProgressBar />}
			{error && <div className="dataCardError">{`Error fetching data: ${error}`}</div>}
			{!loading &&
				!error &&
				displayType === 'debt' && (
					<KiCardDebt
						card={card}
						view={view}
						fundingVehicles={fundingVehicles}
						fundingVehicleId={fundingVehicleId}
						scenarioList={props.scenarioList}
						scenarioParentId={scenarioId}
					/>
				)}
			{!loading &&
				!error &&
				displayType === 'explorer' && (
					<KiCardExplorer
						datasetColumns={datasetColumns}
						card={card}
						view={view}
						fundingVehicles={fundingVehicles}
						fundingVehicleId={fundingVehicleId}
						scenarioList={
							props.scenarioList.length ? props.scenarioList : options.debt.debtScenarioContexts
						}
						scenarioId={scenarioId}
					/>
				)}
			{!loading &&
				!error &&
				displayType === 'fundingAnalysis' && <KiCardPreview card={card} reportDefinition={reportDefinition} />}
			{!loading && !error && displayType === 'projections' && <KiCardForecasting card={card} />}
			{!loading &&
				!error &&
				displayType === 'payments' && (
					<KiCardPayments
						card={card}
						datasetColumns={datasetColumns}
						fundingVehicles={fundingVehicles}
						fundingVehicleId={fundingVehicleId}
						scenarioList={
							props.scenarioList.length ? props.scenarioList : options.debt.debtScenarioContexts
						}
						scenarioId={scenarioId}
					/>
				)}
		</React.Fragment>
	);
}

KiCard.propTypes = {
	fundingVehicles: PropTypes.array,
	scenarioList: PropTypes.array,
	card: PropTypes.object,
	fundingVehicleId: PropTypes.string,
	scenarioId: PropTypes.string,
	reportDefinition: PropTypes.object,
};

KiCard.defaultProps = {
	fundingVehicles: [],
	scenarioList: [],
	card: {},
	fundingVehicleId: '',
	scenarioId: '',
	reportDefinition: null,
};

export default KiCard;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'components/KiSelect';
import KiInput from 'components/KiInput';
import KiModal2 from 'components/KiModal2';
import KiIconButton from 'components/KiIconButton';
import KiCheckbox from 'components/KiCheckbox';
import KiDnDList from 'components/KiDnDList';
import {
	addBlockForFundingVehicle,
	fetchBlocksForFundingVehicle,
	fetchTracksForFundingVehicle,
	fetchFundingVehicleSettingsByDatasetId,
} from 'containers/fundingVehicle/actions';
import {fetchModels} from 'containers/dealStructures/actions';
import debtOptions from 'ki-common/options/debt';
import stringHelpers from 'ki-common/utils/stringHelpers';
import {getSortFuncByField} from 'ki-common/utils/sorters';
// import './dealStructuresBlockModal.scss';
import _countBy from 'lodash/countBy';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import './ModalStyles.scss';
import _ from 'lodash';

const PAY_OPTIONS_CREDIT_SUPPORT = [
	{
		label: 'Select...',
		value: '',
	},
	{
		label: 'Due',
		value: 'due',
	},
	{
		label: 'Deferred',
		value: 'deferred',
	},
];

const PAY_OPTIONS_FEE = [
	{
		label: 'Select...',
		value: '',
	},
	{
		label: 'Due',
		value: 'due',
	},
	{
		label: 'Deferred',
		value: 'deferred',
	},
	{
		label: 'Due & Deferred',
		value: 'duedeferred',
	},
];

const PAY_OPTIONS_TRANCHE = [
	{
		label: 'Select...',
		value: '',
	},
	{
		label: 'Interest',
		value: 'dueint',
	},
	{
		label: 'Arrears',
		value: 'duearrears',
	},
	{
		label: 'Interest Arrears',
		value: 'dueintarrears',
	},
	{
		label: 'Interest And Arrears',
		value: 'interestandarrears',
	},
	{
		label: 'Principal',
		value: 'dueprin',
	},
	{
		label: 'Loss',
		value: 'loss',
	},
];

const PAY_OPTIONS_TYPE = [
	{
		label: 'Select...',
		value: '',
	},
	{
		label: 'Assign',
		value: 'assign',
	},
	{
		label: 'Pay',
		value: 'pay',
	},
	{
		label: 'Increase',
		value: 'increase',
	},
	{
		label: 'Decrease',
		value: 'decrease',
	},
];

const PAY_FROM_OPTIONS = debtOptions.payFromOptions;

class BlocksModal extends React.Component {
	static propTypes = {
		active: PropTypes.bool,
		dataset: PropTypes.object,
		onCancel: PropTypes.func,
		onUpdate: PropTypes.func,
		fundingVehicle: PropTypes.object,
		addBlockForFundingVehicle: PropTypes.func,
		fetchBlocksForFundingVehicle: PropTypes.func,
		fetchTracksForFundingVehicle: PropTypes.func,
		fetchFundingVehicleSettingsByDatasetId: PropTypes.func,
		fetchModels: PropTypes.func,
		currentBlock: PropTypes.object,
		fees: PropTypes.array,
		tranches: PropTypes.array,
		creditSupports: PropTypes.array,
		allBlocks: PropTypes.array,
		waterfallColumns: PropTypes.array,
	};

	static defaultProps = {
		steps: [],
		fundingVehicle: {},
		fees: [],
		tranches: [],
		waterfallColumns: [],
	};

	state = {
		name: '',
		payFrom: [],
		newStep: {
			name: '',
			dueAmount: null,
		},
		steps: [],
		noStepNameError: null,
		noBlockNameError: null,
		isSaving: false,
		isEdit: false,
	};

	componentDidMount() {
		const block = _cloneDeep(this.props.currentBlock);
		if (block) {
			this.setState({
				name: block.name,
				payFrom: block.payFrom.map(id =>
					[...PAY_FROM_OPTIONS, ...this.props.creditSupports].find(c => c._id === id)
				),
				steps: block.steps,
				isEdit: true,
			});
		} else {
			this.setState({
				name: '',
				payFrom: [],
				steps: [],
			});
		}
		return this.props.fetchFundingVehicleSettingsByDatasetId(this.props.dataset.datasetId);
	}

	isNameUnique = name => {
		return this.props.allBlocks.filter(e => e.name === name).length > 0;
	};

	handleConfirm = () => {
		if (this.isNameUnique(this.state.name) && !this.state.isEdit) {
			this.setState({noBlockNameError: 'This name is already taken!'});
		} else if (!this.state.name.match(stringHelpers.alphanumericRegex)) {
			this.setState({noBlockNameError: 'Name must be Alphanumeric!'});
		} else {
			this.setState({
				isSaving: true,
			});
			const newBlock = {
				_id: _get(this.props, 'currentBlock._id') || null,
				fundingVehicleId: this.props.fundingVehicle._id,
				name: this.state.name,
				payFrom: this.state.payFrom.map(col => col._id),
				steps: this.state.steps,
			};
			return this.props.addBlockForFundingVehicle(newBlock).then(() => {
				this.setState(
					{
						name: '',
						payFrom: [],
						steps: [],
						isSaving: false,
					},
					() => {
						this.props.fetchBlocksForFundingVehicle(this.props.fundingVehicle._id);
						this.props.fetchTracksForFundingVehicle(this.props.fundingVehicle._id);
						this.props.fetchModels(this.props.fundingVehicle._id);
						this.props.onCancel();
					}
				);
				if (this.props.onUpdate) {
					this.props.onUpdate();
				}
			});
		}
	};

	onStepsReordered = (orderedSteps, sourceIndex, destinationIndex) => {
		const sourceStepContentType = _.get(orderedSteps, `${sourceIndex}.content.type`);
		const destinationStepContentType = _.get(orderedSteps, `${destinationIndex}.content.type`);
		if (
			['pay', 'assign'].includes(sourceStepContentType) &&
			['increase', 'decrease'].includes(destinationStepContentType)
		) {
			return;
		}

		const steps = orderedSteps.map(step => {
			return step.content;
		});

		const stepTally = _countBy(steps, 'group');
		const sortedStepGroups = Object.keys(stepTally);

		steps.forEach((step, index) => {
			let totalIndex = 0;
			let foundGroupAssignment = false;
			sortedStepGroups.forEach((group, idx) => {
				totalIndex += parseInt(stepTally[group]);
				if (totalIndex >= index + 1 && !foundGroupAssignment) {
					step.group = idx + 1;
					foundGroupAssignment = true;
				}
			});
		});
		this.setState({steps: steps});
	};

	onStepsCombined = (combinedSteps, sourceIndex, destinationIndex) => {
		if (
			combinedSteps[sourceIndex].content.type === 'increase' ||
			combinedSteps[destinationIndex].content.type === 'increase' ||
			combinedSteps[sourceIndex].content.type === 'decrease' ||
			combinedSteps[destinationIndex].content.type === 'decrease' ||
			combinedSteps[sourceIndex].content.type === 'assign' ||
			combinedSteps[destinationIndex].content.type === 'assign'
		) {
			return;
		}

		let steps = combinedSteps.map(step => {
			return step.content;
		});

		steps[sourceIndex].group = steps[destinationIndex].group;

		const [removed] = steps.splice(sourceIndex, 1);

		steps.splice(destinationIndex, 0, removed);

		steps = this.reduceStepNumbers(steps);

		this.setState({steps: steps});
	};

	reduceStepNumbers = steps => {
		const stepTally = _countBy(steps, 'group');
		const sortedStepGroups = Object.keys(stepTally);

		steps = _sortBy(steps, 'group');
		steps.forEach(step => {
			step.group = sortedStepGroups.indexOf(String(step.group)) + 1;
		});
		return steps;
	};

	breakGrouping = (e, index) => {
		const {steps} = this.state;
		const targetGroup = steps[index].group;

		const itemsToBreak = steps.filter(item => {
			return item.group === targetGroup;
		});

		const totalInGroup = itemsToBreak.length;

		let stepsAdded = 0;
		steps.forEach(step => {
			if (step.group === targetGroup) {
				step.group = targetGroup + stepsAdded;
				stepsAdded++;
			} else if (step.group > targetGroup) {
				step.group += totalInGroup;
			}
		});
		this.setState({steps: steps});
	};

	addStep = () => {
		const {steps, newStep} = this.state;

		if (newStep.name.length < 1) {
			this.setState({noStepNameError: 'Desc. is required'});
		} else {
			this.setState({noStepNameError: null});
		}

		if (!newStep.type) {
			this.setState({noStepTypeError: 'Type is required'});
		} else {
			this.setState({noStepTypeError: null});
		}

		if ((newStep.type === 'pay' || newStep.type === 'increase' || newStep.type === 'decrease') && !newStep.entity) {
			this.setState({noEntityError: 'Entity selection is required'});
		} else {
			this.setState({noEntityError: null});
		}

		if (newStep.type === 'assign' && !newStep.entity) {
			this.setState({noEntityError2: 'Entity selection is required'});
		} else {
			this.setState({noEntityError2: null});
		}

		if (!newStep.pay && newStep.type !== 'assign') {
			this.setState({noPayError: 'Pay selection is required'});
		} else {
			this.setState({noPayError: null});
		}

		if (!newStep.value && newStep.type !== 'pay') {
			this.setState({noStepValueError: 'Value is required'});
		} else {
			this.setState({noStepValueError: null});
		}

		if (newStep.type === 'assign' && (newStep.name.length < 1 || !newStep.entity || !newStep.value)) {
			return false;
		}

		if (newStep.type === 'pay' && (newStep.name.length < 1 || !newStep.entity || !newStep.pay)) {
			return false;
		}

		if (
			(newStep.type === 'increase' || newStep.type === 'decrease') &&
			(newStep.name.length < 1 || !newStep.entity || !newStep.value)
		) {
			return false;
		}

		steps.push({
			name: newStep.name,
			type: newStep.type,
			dueAmount: newStep.dueAmount,
			ignore: false,
			group: steps.length + 1,
			entity: newStep.entity,
			pay: newStep.pay,
			value: newStep.value,
		});

		this.setState({
			steps: steps,
			newStep: {
				type: newStep.type,
				name: '',
				dueAmount: null,
				entity: null,
				pay: undefined,
			},
			noStepNameError: null,
			noStepTypeError: null,
			noEntityError: null,
			noEntityError2: null,
			noPayError: null,
			noStepValueError: null,
		});
	};

	onCancel = () => {
		this.setState(
			{
				name: '',
				payFrom: [],
				steps: [],
			},
			this.props.onCancel
		);
	};

	deleteStep = index => {
		const {steps} = this.state;
		steps.splice(index, 1);
		this.setState({
			steps: steps,
		});
	};

	ignore = (val, index) => {
		const {steps} = this.state;
		steps[index].ignore = val;
		this.setState({
			steps: steps,
		});
	};

	updateStepName = (val, index) => {
		const {steps} = this.state;
		steps[index].name = val;
		this.setState({
			steps: steps,
		});
	};

	updateStepDueAmount = (val, index) =>
		this.setState(state => ({
			steps: state.steps.reduce((acc, step, idx) => {
				if (idx === index) {
					return [...acc, {...step, dueAmount: val}];
				}
				return [...acc, step];
			}, []),
		}));

	updateStepEntity = (val, index) =>
		this.setState(state => ({
			steps: state.steps.reduce((acc, step, idx) => {
				if (idx === index) {
					return [...acc, {...step, entity: _.get(val, '_id')}];
				}
				return [...acc, step];
			}, []),
		}));

	updateStepPay = (val, index) =>
		this.setState(state => ({
			steps: state.steps.reduce((acc, step, idx) => {
				if (idx === index) {
					return [...acc, {...step, pay: _.get(val, 'value')}];
				}
				return [...acc, step];
			}, []),
		}));

	updateStepValue = (val, index) => {
		this.setState(state => ({
			steps: state.steps.reduce((acc, step, idx) => {
				if (idx === index) {
					return [...acc, {...step, value: val}];
				}
				return [...acc, step];
			}, []),
		}));
	};

	checkFormSaveEnabled = () => {
		const {name, payFrom, steps} = this.state;
		let error;
		if (!name.length) {
			// this.setState({noBlockNameError: 'Name is required'});
			error = true;
		}
		if (!payFrom || !payFrom.length) {
			// this.setState({noPayFromError: 'Pay From is required'});
			error = true;
		}
		if (!steps.length) {
			// this.setState({noStepsError: 'At least one step is required'});
			error = true;
		}
		if (this.state.isSaving) {
			error = true;
		}
		return error;
	};

	getPayOptions = entityId => {
		if (this.props.tranches.find(x => x._id === entityId)) {
			return PAY_OPTIONS_TRANCHE;
		}
		if (this.props.fees.find(x => x._id === entityId)) {
			return PAY_OPTIONS_FEE;
		}
		if (this.props.creditSupports.find(x => x._id === entityId)) {
			return PAY_OPTIONS_CREDIT_SUPPORT;
		}
		return [];
	};

	getCalcCols = columns => {
		return columns
			.filter(col => col.isWaterfall && col.dataType === 'numeric')
			.sort(getSortFuncByField('displayName', true));
	};

	onNameChange = val => {
		let noBlockNameError = null;
		if (val && this.isNameUnique(val) && !this.state.isEdit) {
			noBlockNameError = 'This name is already taken!';
		}

		if (!val.match(stringHelpers.alphanumericRegex)) {
			noBlockNameError = 'Name must be Alphanumeric!';
		}

		this.setState({
			name: val,
			noBlockNameError: noBlockNameError,
		});
	};

	onStepNameChange = val => {
		let noStepNameError = null;

		if (!val) {
			noStepNameError = 'Desc. is required';
		}

		this.setState({
			noStepNameError: noStepNameError,
			newStep: {
				...this.state.newStep,
				name: val,
			},
		});
	};

	onStepTypeChange = val => {
		let noStepTypeError = null;

		if (!val) {
			noStepTypeError = 'Type is required';
		}

		this.setState({
			noStepTypeError: noStepTypeError,
			newStep: {
				...this.state.newStep,
				type: val.value,
			},
		});
	};

	onStepValueChange = val => {
		let noStepValueError = null;

		if (!val) {
			noStepValueError = 'Value is required';
		}

		this.setState({
			noStepValueError: noStepValueError,
			newStep: {
				...this.state.newStep,
				value: _.get(val, '_id'),
			},
		});
	};

	onEntitySelection = val => {
		let noEntityError = null;

		if (!val._id) {
			noEntityError = 'Entity selection is required';
		}

		this.setState(state => {
			return {
				noEntityError: noEntityError,
				newStep: {
					...state.newStep,
					entity: _get(val, '_id', null),
				},
			};
		});
	};

	paySelectionChange = val => {
		let noPayError = null;

		if (!val.value) {
			noPayError = 'Pay selection is required';
		}

		this.setState({
			noPayError: noPayError,
			newStep: {
				...this.state.newStep,
				pay: _.get(val, 'value'),
			},
		});
	};

	renderStepItem = (step, index) => {
		const fvCalcCols = this.getCalcCols(this.props.waterfallColumns);
		const fees = this.props.fees.filter(fee => {
			return this.props.fundingVehicle._id === fee.fundingVehicleId;
		});
		const entities = [...fees, ...this.props.tranches, ...this.props.creditSupports];
		return (
			<React.Fragment>
				<div className="step-inputs">
					<KiInput
						type="text"
						name="name"
						label="Description"
						error={null}
						value={step.name}
						onChange={val => this.updateStepName(val, index)}
						maxLength={100}
					/>
					<div className="select-wrapper" style={{maxWidth: '75px'}}>
						<span style={{marginBottom: '30px'}} className={'theme-label'}>
							Step Type
						</span>
						<div style={{height: '34px', paddingTop: '10px'}}>{step.type}</div>
					</div>
					{step.type === 'pay' && (
						<div className="select-wrapper">
							<span className={'theme-label'}>Entity</span>
							<Select
								classNamePrefix="aut-select"
								value={entities.find(x => x._id === step.entity)}
								getOptionLabel={opt => {
									if (_.has(opt, 'trancheNumber')) {
										return `Tranche ${opt.classId}-${opt.trancheNumber}`;
									} else if (_.has(opt, 'supportNumber')) {
										return opt.nameId;
									}
									return opt.name;
								}}
								getOptionValue={opt => opt._id}
								isClearable={false}
								options={entities}
								onChange={val => this.updateStepEntity(val, index)}
							/>
						</div>
					)}
					{step.type === 'pay' && (
						<div className="select-wrapper step-pay">
							<span className={'theme-label'}>Pay</span>
							<Select
								classNamePrefix="aut-select"
								value={
									this.getPayOptions(step.entity).find(opt => opt.value === step.pay) ||
									this.getPayOptions(step.entity)[0]
								}
								isClearable={false}
								options={this.getPayOptions(step.entity)}
								onChange={val => this.updateStepPay(val, index)}
							/>
						</div>
					)}
					{step.type === 'pay' && (
						<div className="select-wrapper">
							<span className={'theme-label'}>Override Amount</span>
							<Select
								classNamePrefix="aut-select"
								value={fvCalcCols.find(col => col._id === step.dueAmount)}
								isClearable={true}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								options={fvCalcCols}
								onChange={val => this.updateStepDueAmount(_.get(val, '_id'), index)}
							/>
						</div>
					)}
					{step.type === 'assign' && (
						<div className="select-wrapper">
							<span className={'theme-label'}>Entity</span>
							<Select
								classNamePrefix="aut-select"
								value={fvCalcCols.find(x => x._id === step.entity)}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								options={fvCalcCols}
								onChange={val => this.updateStepEntity(val, index)}
							/>
						</div>
					)}
					{step.type === 'assign' && (
						<div className="select-wrapper">
							<span className={'theme-label'}>Value</span>
							<Select
								classNamePrefix="aut-select"
								value={fvCalcCols.find(x => x._id === step.value)}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								options={fvCalcCols}
								onChange={val => this.updateStepValue(_.get(val, '_id'), index)}
							/>
						</div>
					)}
					{(step.type === 'increase' || step.type === 'decrease') && (
						<div className="select-wrapper">
							<span className={'theme-label'}>Entity</span>
							<Select
								classNamePrefix="aut-select"
								value={fvCalcCols.find(x => x._id === step.entity)}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								isClearable={false}
								options={fvCalcCols}
								onChange={val => this.updateStepEntity(val, index)}
							/>
						</div>
					)}
					{(step.type === 'increase' || step.type === 'decrease') && (
						<div className="select-wrapper">
							<span className={'theme-label'}>Value</span>
							<Select
								classNamePrefix="aut-select"
								value={fvCalcCols.find(x => x._id === step.value)}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								options={fvCalcCols}
								onChange={val => this.updateStepValue(_.get(val, '_id'), index)}
							/>
						</div>
					)}
				</div>
				<div className="step-controls">
					<KiCheckbox
						checked={step.ignore}
						onChange={val => this.ignore(val, index)}
						label={'Ignore Step'}
						className="ignore-step"
					/>
					<KiIconButton disabled={false} icon="delete" onClick={() => this.deleteStep(index)} />
				</div>
			</React.Fragment>
		);
	};

	render() {
		if (!this.props.active || !this.props.dataset) {
			return null;
		}
		const fees = this.props.fees.filter(fee => {
			return this.props.fundingVehicle._id === fee.fundingVehicleId;
		});
		const entities = [{name: 'Select...'}, ...fees, ...this.props.tranches, ...this.props.creditSupports];
		const fvCalcCols = this.getCalcCols(this.props.waterfallColumns);
		const stepGroups = _countBy(this.state.steps, 'group');

		return (
			<KiModal2
				active={this.props.active}
				actions={[
					{label: 'Cancel', onClick: this.onCancel},
					{label: 'Save', onClick: this.handleConfirm, disabled: this.checkFormSaveEnabled()},
				]}
				panelStyles={{maxHeight: '95vh', maxWidth: '95vw', flex: '0 1 120rem'}}
				className="numeric-debt-formula-modal"
				header={`${'Create/Edit'} a Block`}
			>
				<header>
					<KiInput
						style={{width: '50rem'}}
						type="text"
						name="name"
						label="Block Name"
						error={this.state.noBlockNameError}
						value={this.state.name}
						onChange={this.onNameChange}
						maxLength={100}
					/>
					<div className="select-wrapper">
						<span className={'theme-label'}>Pay From</span>
						<Select
							classNamePrefix="aut-select"
							value={this.state.payFrom}
							isClearable={false}
							isMulti={true}
							getOptionLabel={option => {
								if (_.has(option, 'supportNumber')) {
									return option.nameId;
								}
								return option.columnName;
							}}
							getOptionValue={option => option._id}
							options={[...PAY_FROM_OPTIONS, ...this.props.creditSupports]}
							onChange={values =>
								this.setState({
									payFrom: values,
								})
							}
						/>
					</div>
				</header>
				<section className="new-fv-setting-form">
					<div className="step-inputs">
						<KiInput
							type="text"
							name="name"
							label="Description"
							error={this.state.noStepNameError}
							value={this.state.newStep.name}
							onChange={this.onStepNameChange}
							maxLength={100}
						/>
						<div className="select-wrapper">
							<span className={'theme-label'}>Step Type</span>
							<Select
								classNamePrefix="aut-select"
								isClearable={false}
								options={PAY_OPTIONS_TYPE}
								errorMessage={this.state.noStepTypeError}
								onChange={this.onStepTypeChange}
							/>
						</div>
						{this.state.newStep.type === 'pay' && (
							<div className="select-wrapper">
								<span className={'theme-label'}>Entity</span>
								<Select
									classNamePrefix="aut-select"
									value={entities.find(x => x._id === this.state.newStep.entity) || null}
									getOptionLabel={opt => {
										if (_.has(opt, 'trancheNumber')) {
											return `Tranche ${opt.classId}-${opt.trancheNumber}`;
										} else if (_.has(opt, 'supportNumber')) {
											return opt.nameId;
										}
										return opt.name;
									}}
									getOptionValue={opt => opt._id}
									isClearable={false}
									options={entities}
									errorMessage={this.state.noEntityError}
									onChange={this.onEntitySelection}
								/>
							</div>
						)}
						{this.state.newStep.type === 'pay' && (
							<div className="select-wrapper step-pay">
								<span className={'theme-label'}>Pay</span>
								<Select
									classNamePrefix="aut-select"
									value={
										this.getPayOptions(this.state.newStep.entity).find(
											opt => opt.value === this.state.newStep.pay
										) || null
									}
									isClearable={false}
									options={this.getPayOptions(this.state.newStep.entity)}
									errorMessage={this.state.noPayError}
									onChange={this.paySelectionChange}
								/>
							</div>
						)}
						{this.state.newStep.type === 'pay' && (
							<div className="select-wrapper">
								<span className={'theme-label'}>Override Amount</span>
								<Select
									classNamePrefix="aut-select"
									value={fvCalcCols.find(col => col._id === this.state.newStep.dueAmount) || null}
									isClearable={true}
									getOptionLabel={option => option.displayName}
									getOptionValue={option => option._id}
									options={fvCalcCols}
									onChange={val =>
										this.setState({
											newStep: {
												...this.state.newStep,
												dueAmount: _.get(val, '_id'),
											},
										})
									}
								/>
							</div>
						)}
						{this.state.newStep.type === 'assign' && (
							<div className="select-wrapper">
								<span className={'theme-label'}>Entity</span>
								<Select
									classNamePrefix="aut-select"
									value={fvCalcCols.find(col => col._id === this.state.newStep.entity) || null}
									isClearable={true}
									getOptionLabel={option => option.displayName}
									getOptionValue={option => option._id}
									options={fvCalcCols}
									errorMessage={this.state.noEntityError2}
									onChange={this.onEntitySelection}
								/>
							</div>
						)}
						{this.state.newStep.type === 'assign' && (
							<div className="select-wrapper">
								<span className={'theme-label'}>Value</span>
								<Select
									classNamePrefix="aut-select"
									value={fvCalcCols.find(col => col._id === this.state.newStep.value) || null}
									isClearable={true}
									getOptionLabel={option => option.displayName}
									getOptionValue={option => option._id}
									options={fvCalcCols}
									errorMessage={this.state.noStepValueError}
									onChange={this.onStepValueChange}
								/>
							</div>
						)}
						{(this.state.newStep.type === 'increase' || this.state.newStep.type === 'decrease') && (
							<div className="select-wrapper">
								<span className={'theme-label'}>Entity</span>
								<Select
									classNamePrefix="aut-select"
									value={fvCalcCols.find(x => x._id === this.state.newStep.entity) || null}
									getOptionLabel={option => option.displayName}
									getOptionValue={option => option._id}
									isClearable={false}
									options={fvCalcCols}
									errorMessage={this.state.noEntityError}
									onChange={this.onEntitySelection}
								/>
							</div>
						)}
						{(this.state.newStep.type === 'increase' || this.state.newStep.type === 'decrease') && (
							<div className="select-wrapper">
								<span className={'theme-label'}>Value</span>
								<Select
									classNamePrefix="aut-select"
									value={fvCalcCols.find(col => col._id === this.state.newStep.value) || null}
									isClearable={true}
									getOptionLabel={option => option.displayName}
									getOptionValue={option => option._id}
									options={fvCalcCols}
									errorMessage={this.state.noStepValueError}
									onChange={this.onStepValueChange}
								/>
							</div>
						)}
					</div>
					<KiIconButton disabled={false} icon="add" onClick={() => this.addStep()} />
				</section>
				<div className="steps-list">
					<section className="steps-dnd-list-numbers">
						{Object.keys(stepGroups).map((key, index) => (
							<div
								className="list-group-label"
								key={key}
								style={{
									justifyContent: 'center',
									height: `${87 * stepGroups[key] + 10 * (stepGroups[key] - 1)}px`,
								}}
							>
								{stepGroups[key] > 1 && (
									<KiIconButton icon="clear" onClick={e => this.breakGrouping(e, index)} />
								)}
								<span>{index + 1}</span>
							</div>
						))}
					</section>
					<section className="steps-dnd-list">
						<KiDnDList
							items={this.state.steps.map((step, index) => ({
								id: index,
								content: step,
								idx: index,
							}))}
							onReorder={this.onStepsReordered}
							onCombine={this.onStepsCombined}
							contentRenderFunc={this.renderStepItem}
							itemClassName={'existing-fv-setting-form'}
							isCombineEnabled={true}
						/>
					</section>
				</div>
			</KiModal2>
		);
	}
}

const mapStateToProps = state => ({
	fees: state.fundingVehicle.selectedSettings.fees,
	tranches: state.fundingVehicle.selectedSettings.debt.filter(
		x => x.fundingVehicleId === state.fundingVehicle.selected._id
	),
	creditSupports: state.fundingVehicle.selectedSettings.creditSupports.filter(
		x => x.fundingVehicleId === state.fundingVehicle.selected._id
	),
});

const mapDispatchToProps = () => ({
	addBlockForFundingVehicle,
	fetchBlocksForFundingVehicle,
	fetchTracksForFundingVehicle,
	fetchFundingVehicleSettingsByDatasetId,
	fetchModels,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(BlocksModal);

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './FlyoutCardList.theme.scss';
import KiIconButton from 'components/KiIconButton';
import KiFontIcon from 'components/KiFontIcon';
import KiDataCardEditor from 'components/KiDataCardEditor';
import {fetchAllCards, setCurrentCard, deleteCard, addCard} from 'containers/dashboards/actions';
import _toLower from 'lodash/toLower';
import _uniqueId from 'lodash/uniqueId';
import _isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';
import _ from 'lodash';
import KiConfirmModal from 'components/KiConfirmModal';
import {
	getDefaultExplorerViewCard,
	getDefaultDebtCard,
	getDefaultFundingAnalysisViewCard,
	getCardTypeOptionBySettingType,
	getDefaultProjectionsCard,
} from 'ki-common/utils/cardUtil';

export class FlyoutCardList extends Component {
	static propTypes = {
		allCards: PropTypes.array,
		datasetId: PropTypes.string,
		fundingVehicleId: PropTypes.string,
		snapshot: PropTypes.object,
		addCardToReport: PropTypes.func,
		fetchAllCards: PropTypes.func,
		setCurrentCard: PropTypes.func,
		deleteCard: PropTypes.func,
		bookmark: PropTypes.object,
		addCard: PropTypes.func,
		user: PropTypes.object,
		explorerViewType: PropTypes.string,
	};

	state = {
		showCardEditor: false,
		filteredCards: [],
		cardEditorActiveTab: 0,
		confirmMessage: '',
		disableSubmit: false,
	};

	componentDidMount() {
		if (this.props.datasetId) {
			this.props.fetchAllCards(this.props.datasetId).then(() => {
				this.setState({
					filteredCards: this.filterCards(
						this.props.allCards,
						this.props.datasetId,
						this.props.fundingVehicleId,
						this.props.bookmark
					),
				});
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.datasetId !== prevProps.datasetId ||
			this.props.fundingVehicleId !== prevProps.fundingVehicleId ||
			(this.props.bookmark && !_.isEqual(this.props.bookmark, prevProps.bookmark)) ||
			!_isEqual(this.props.allCards, prevProps.allCards)
		) {
			this.setState({
				filteredCards: this.filterCards(
					this.props.allCards,
					this.props.datasetId,
					this.props.fundingVehicleId,
					this.props.bookmark
				),
			});
		}
	}

	openCardEditor = (card, activeTab) => {
		this.setState({cardEditorActiveTab: activeTab}, () => {
			this.props.setCurrentCard(card);
			this.setState({showCardEditor: true});
		});
	};

	closeCardEditor = () => {
		this.setState({showCardEditor: false});
		this.props.setCurrentCard(null);
	};

	renderCards = sortedCards => {
		return sortedCards.map(card => (
			<div className={styles.card} key={_uniqueId(`${card._id}_`)}>
				{!!this.props.addCardToReport && (
					<span className={styles.iconSpan}>
						<KiFontIcon
							value="add_circle"
							onClick={() => this.props.addCardToReport(card._id, card.name)}
						/>
					</span>
				)}
				<p className={styles.cardTitle}>{`${card.name} (${
					getCardTypeOptionBySettingType(card.settings.type).label
				})`}</p>
				<span className={styles.iconSpan}>
					<KiFontIcon value="search" onClick={() => this.openCardEditor(card, 0)} />
					{!card.isSystem && (
						<i title="Edit" className="material-icons" onClick={() => this.openCardEditor(card, 1)}>
							mode_edit
						</i>
					)}
					{!card.isSystem && (
						<i
							title="Delete"
							className="material-icons"
							onClick={() => {
								this.setState({confirmMessage: `Are you sure you want to delete ${card.name}?`});
								this.setState({deleteCardModalActive: true, selectedCard: card});
							}}
						>
							delete
						</i>
					)}
				</span>
			</div>
		));
	};

	filterCards = (cardList, datasetId, fundingVehicleId, bookmark) => {
		let fundingVehicleCards = cardList
			.filter(card => card.datasetId === datasetId)
			.filter(card => card.fundingVehicleId);
		const datasetCards = cardList
			.filter(card => card.datasetId === datasetId)
			.filter(card => !card.fundingVehicleId);
		if (fundingVehicleId) {
			fundingVehicleCards = cardList
				.filter(card => card.datasetId === datasetId)
				.filter(card => card.fundingVehicleId === fundingVehicleId);
		}

		// Filter out any results that do not have a bookmark/view ID that matches the current view
		// Filter out the remainder by user entered search terms
		return _uniqBy([...datasetCards, ...fundingVehicleCards], '_id')
			.filter(
				card =>
					!bookmark ||
					[
						card.bookmarkId,
						_.get(card, 'settings.debtBookmarkId'),
						_.get(card, 'settings.fundingAnalysisBookmarkId'),
					].includes(bookmark._id)
			)
			.filter(card =>
				_toLower(`${card.name} ${card.tags ? JSON.stringify(card.tags) : ''}`).includes(
					_toLower(this.state.searchTerm)
				)
			)
			.sort((a, b) => a.name.localeCompare(b.name));
	};

	// Filter cards, case insensitive
	onSearchTermChange = event => {
		this.setState(
			{
				searchTerm: event.target.value,
			},
			() => {
				this.setState({
					filteredCards: this.filterCards(
						this.props.allCards,
						this.props.datasetId,
						this.props.fundingVehicleId,
						this.props.bookmark
					),
				});
			}
		);
	};

	addNewCard = () => {
		const {addCard, user, bookmark, explorerViewType} = this.props;
		switch (explorerViewType) {
			case 'data': {
				this.setState({addingCard: true});
				return addCard(getDefaultExplorerViewCard(bookmark, user)).then(() =>
					this.setState({addingCard: false})
				);
			}
			case 'debt': {
				this.setState({addingCard: true});
				return addCard(getDefaultDebtCard(bookmark, user)).then(() => this.setState({addingCard: false}));
			}
			case 'fundingAnalysis': {
				this.setState({addingCard: true});
				const defaultFundingAnalysisViewCard = getDefaultFundingAnalysisViewCard(bookmark, user);
				defaultFundingAnalysisViewCard.settings.useReportScenarioId = true;
				return addCard(defaultFundingAnalysisViewCard).then(() =>
					this.setState({addingCard: false})
				);
			}
			case 'projections': {
				this.setState({addingCard: true});
				return addCard(getDefaultProjectionsCard(bookmark, user, this.props.datasetId)).then(() =>
					this.setState({addingCard: false})
				);
			}
			default:
				this.openCardEditor(null);
		}
	};

	deleteCheck = card => {
		const dependencyComponent = (dependencies, card) => {
			return (
				<React.Fragment>
					<div>You cannot delete the card {card.name} because it is being used:</div>
					<ul>
						{dependencies.map((d, counter) => (
							<li key={counter}>
								{d.dependencyType} - {d.name}
							</li>
						))}
					</ul>
				</React.Fragment>
			);
		};

		this.props
			.deleteCard(card)
			.then(result => {
				if (result && result.status) {
					// we had a delete issue
					this.setState({confirmMessage: dependencyComponent(result.data, card)});
					this.setState({disableSubmit: true});
				} else {
					this.setState({selectedCard: null, deleteCardModalActive: false});
				}
			})
			.catch(err => {
				this.setState({confirmMessage: err.message});
			});
	};

	render() {
		if (!this.props.datasetId) {
			return <div className={styles.outputFormInfo}>A dataset must be selected to view cards.</div>;
		}
		const showNoResultsForBookmarkWarning =
			!!this.props.bookmark &&
			!!this.props.allCards.length &&
			!this.state.filteredCards.length &&
			!this.props.allCards.find(card =>
				[card.bookmarkId, _.get(card, 'settings.debtBookmarkId')].includes(this.props.bookmark._id)
			);
		return (
			<React.Fragment>
				<div className={styles.cardsHeader}>
					<label htmlFor={'addNewCardBtn'} className={styles.addCardButtonLabel}>
						{this.state.addingCard ? 'Adding...' : 'Add A Card'}
					</label>
					{!!this.state.disableSubmit}
					<KiIconButton
						icon="add_circle"
						id={'addNewCardBtn'}
						onClick={this.addNewCard}
						inverse
						disabled={this.state.addingCard}
						title="Click to create a new card."
					/>
				</div>

				<input
					className={styles.searchInput}
					role="search"
					onChange={this.onSearchTermChange}
					placeholder="Cards Search"
				/>
				{showNoResultsForBookmarkWarning && (
					<p className={styles.noResultsForBookmarkWarning}>
						No cards have been created for the current view.
					</p>
				)}
				<div className={styles.DnD}>{this.renderCards(this.state.filteredCards)}</div>
				<KiDataCardEditor
					datasetId={this.props.datasetId}
					snapshot={this.props.snapshot}
					addCardToReport={this.props.addCardToReport}
					show={this.state.showCardEditor}
					onClose={this.closeCardEditor}
					activeTab={this.state.cardEditorActiveTab}
				/>
				<KiConfirmModal
					header="Delete a card"
					message={<div>{this.state.confirmMessage}</div>}
					acceptFunc={() => {
						this.deleteCheck(this.state.selectedCard);
					}}
					acceptLabel="Delete"
					rejectFunc={() =>
						this.setState({deleteCardModalActive: false, selectedCard: null, disableSubmit: false})
					}
					active={this.state.deleteCardModalActive}
					acceptDisabled={this.state.disableSubmit}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	allCards: state.dashboards.allCards,
});

const mapDispatchToProps = {
	fetchAllCards,
	setCurrentCard,
	deleteCard,
	addCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlyoutCardList);

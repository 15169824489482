const _get = require('lodash/get');
const _pick = require('lodash/pick');
const {dateToShortDate} = require('./dateHelpers');

const defaultState = {
	isLoading: false,
	transpose: false,
	showTotals: false,
	error: null,
	sortColumn: null,
	sortCalculation: null,
	sortOrder: 'asc',
	pageNumber: 1,
	snapshotType: 'standard',
	groupBy: '',
	maxRecords: null,
	datasetId: null,
	columns: [],
	breadcrumbs: [],
	bucket: {
		min: null,
		max: null,
		value: null,
	},
	granularity: null,
	timeSeriesAll: true,
	timeseries: {
		column: null,
		range: null,
		period: null,
	},
	tableType: 'cohort',
	quickFilters: {
		scenarioType: 'assetSnapshot',
		scenarioId: 'assetSnapshot',
		fundingVehicleId: '',
		poolId: '',
		hypoFundingVehicleId: '',
		hypoPoolId: '',
	},
	filters: [],
	bands: {
		type: 'default',
	},
	startInclusive: true,
	statementDate: null,
	dateContext: null,
	isFixedDate: false,
	dateContextName: '',
	calculatedDate: '',
};

// this goes from bookmark > explorer redux state
const getExplorerStateFromBookmark = (explorerState, bookmark) => {
	const mapped = {
		statementDate: bookmark.explorerData.statementDate || dateToShortDate(new Date()),
		dateContext: bookmark.explorerData.dateContext || '',
		startDateContext: bookmark.explorerData.startDateContext || '',
		endDateContext: bookmark.explorerData.endDateContext || '',
		isFixedDate: bookmark.explorerData.isFixedDate || false,
		sortColumn: bookmark.explorerData.sortColumn || '',
		sortCalculation: bookmark.explorerData.sortCalculation || '',
		sortOrder: bookmark.explorerData.sortOrder,
		pageNumber: bookmark.explorerData.pageNumber,
		groupBy: bookmark.explorerData.groupBy || '',
		maxRecords: bookmark.explorerData.maxRecords || '',
		snapshotType: bookmark.explorerData.snapshotType || 'standard',
		datasetId: bookmark.explorerData.datasetId,
		columns: bookmark.explorerData.columns,
		bucket: {
			min: _get(bookmark.explorerData, 'bucket.min', ''),
			max: _get(bookmark.explorerData, 'bucket.max', ''),
			value: _get(bookmark.explorerData, 'bucket.value', ''),
		},
		granularity: bookmark.explorerData.granularity ? bookmark.explorerData.granularity.toString() : '',
		tableType: bookmark.explorerData.tableType,
		createdBy: bookmark.explorerData.createdBy,
		filters: bookmark.explorerData.filters || [],
		timeseries: bookmark.explorerData.timeseries || {},
		isLoading: false,
		error: null,
		breadcrumbs: bookmark.explorerData.breadcrumbs || [],
		transpose: bookmark.explorerData.transpose || false,
		showTotals: bookmark.explorerData.showTotals || false,
		data: null,
		quickFilters: {
			scenarioId: _get(bookmark.explorerData, 'quickFilters.scenarioId', ''),
			scenarioType: _get(bookmark.explorerData, 'quickFilters.scenarioType', 'assetSnapshot'),
			fundingVehicleId: _get(bookmark.explorerData, 'quickFilters.fundingVehicleId', ''),
			poolId: _get(bookmark.explorerData, 'quickFilters.poolId', ''),
			hypoFundingVehicleId: _get(bookmark.explorerData, 'quickFilters.hypoFundingVehicleId', ''),
			hypoPoolId: _get(bookmark.explorerData, 'quickFilters.hypoPoolId', ''),
		},
		bands: bookmark.explorerData.bands || defaultState.bands,
		startInclusive: bookmark.explorerData.startInclusive,
	};

	if (bookmark.explorerData.dateRange) {
		mapped.dateRange = bookmark.explorerData.dateRange;
	}

	// handle missing custom buckets
	if (!mapped.columns[0].bands) {
		mapped.columns[0].bands = {type: 'default'};
	}

	// handle time series breadcrumb orphan
	if (
		_get(bookmark, 'explorerData.breadcrumbs[0].type', null) === 'timeSeries' &&
		bookmark.explorerData.tableType !== 'timeSeries'
	) {
		mapped.breadcrumbs = [];
	}

	// if timeseries column is selected, get latest display format from bookmark's matching summary column
	const tsColId = _get(mapped, 'timeseries.column._id');
	if (tsColId && tsColId !== 'all') {
		const match = bookmark.explorerData.columns.find(col => col._id === tsColId);
		if (match) {
			Object.assign(mapped.timeseries.column, _pick(match, ['displayFormat']));
		}
	}
	return mapped;
};

// this goes from explorer redux state > api request BE needs
// eslint-disable-next-line complexity
const buildRequestFromDataExplorerState = (dataExplorerState, isOdata, datasetColumns) => {
	const parsedRequest = {
		statementDate: dataExplorerState.statementDate,
		dateContext: dataExplorerState.dateContext || '',
		startDateContext: dataExplorerState.startDateContext || '',
		endDateContext: dataExplorerState.endDateContext || '',
		isFixedDate: dataExplorerState.isFixedDate || false,
		tableType: dataExplorerState.tableType,
		pageSize: dataExplorerState.pageSize || 100,
		snapshotType: dataExplorerState.snapshotType || 'standard',
		groupBy: dataExplorerState.groupBy || null,
		maxRecords: !dataExplorerState.maxRecords ? null : Number(dataExplorerState.maxRecords),
		datasetId: dataExplorerState.datasetId,
		pageNumber: dataExplorerState.pageNumber,
		name: '',
		sortOrder: dataExplorerState.sortOrder,
		sortCalculation: dataExplorerState.sortCalculation,
		sortColumn: dataExplorerState.sortColumn,
		columns: dataExplorerState.columns,
		isGadget: dataExplorerState.isGadget || false,
		transpose: dataExplorerState.transpose || false,
		showTotals: dataExplorerState.showTotals || false,
		quickFilters: {
			scenarioId: _get(dataExplorerState, 'quickFilters.scenarioId') || null,
			scenarioType: _get(dataExplorerState, 'quickFilters.scenarioType', 'assetSnapshot'),
			fundingVehicleId: _get(dataExplorerState, 'quickFilters.fundingVehicleId') || null,
			poolId: _get(dataExplorerState, 'quickFilters.poolId') || null,
			hypoFundingVehicleId: _get(dataExplorerState, 'quickFilters.hypoFundingVehicleId') || null,
			hypoPoolId: _get(dataExplorerState, 'quickFilters.hypoPoolId') || null,
		},
		filters: dataExplorerState.filters,
	};

	// Handle pending scenario selections by adding the transfer date
	if (parsedRequest.quickFilters.scenarioType === 'lastCommitted') {
		parsedRequest.quickFilters.transferDate = _get(dataExplorerState, 'quickFilters.scenarioId');
	}

	parsedRequest.columns[0].bucket = dataExplorerState.bucket;
	parsedRequest.columns[0].numberOfBuckets = dataExplorerState.numberOfBuckets || 25;
	// if its a short date column, then the default granularity should be monthly
	parsedRequest.columns[0].granularity = dataExplorerState.granularity
		? dataExplorerState.granularity.toString()
		: parsedRequest.columns[0].dataType === 'date_short'
		? 'monthly'
		: '';
	if (dataExplorerState.dateRange) {
		parsedRequest.columns[0].dateRange = dataExplorerState.dateRange;
	}
	parsedRequest.columns[0].bands = dataExplorerState.bands || {type: 'default'};
	parsedRequest.columns[0].startInclusive = dataExplorerState.startInclusive;

	if (parsedRequest.tableType === 'timeSeries') {
		if (!dataExplorerState.timeseries.column || (dataExplorerState.timeseries.column._id === 'all' && datasetColumns)) {
			parsedRequest.timeSeriesAll = true;
			parsedRequest.columns = [
				datasetColumns.find(col => _get(col, 'assetColumn.columnName') === 'kiSnapshotDate'),
				...dataExplorerState.columns.filter(col => col.columnType === 'aggregate'),
			];
		} else {
			parsedRequest.timeSeriesAll = false;
			parsedRequest.columns = [parsedRequest.columns[0], dataExplorerState.timeseries.column];
		}
		parsedRequest.timeSeriesPeriodsDisplayed = dataExplorerState.timeseries.range;
		parsedRequest.timeSeriesGranularity = dataExplorerState.timeseries.period;
		parsedRequest.timeSeriesChronological = dataExplorerState.timeseries.chronological;
	}
	if (parsedRequest.tableType === 'cohort') {
		// we only want cohort + aggregate columns
		parsedRequest.columns = parsedRequest.columns.filter(
			col => col.columnType === 'cohort' || col.columnType === 'aggregate'
		);
	}
	if (parsedRequest.tableType === 'asset') {
		// we only want cohort + asset columns
		parsedRequest.columns = parsedRequest.columns.filter(
			col => col.columnType === 'cohort' || col.columnType === 'asset'
		);
	}

	// the BE cannot be relied upon for correct displayFormat yet so fix it before we send it
	// TODO make category schema put in the correct defaults
	for (const col of parsedRequest.columns) {
		if (col.calculation === 'COUNT_PCT' || col.calculation === 'SUM_PCT' || col.calculation === 'SUM_PCT_TOTAL') {
			// if user has selected a specific format, use it otherwise default
			if (!col.displayFormat || col.displayFormat.indexOf('%') < 0) {
				col.displayFormat = '9,999.99%';
			}
		}
	}

	if (isOdata) {
		delete parsedRequest.pageNumber;
		delete parsedRequest.pageSize;
	}

	return parsedRequest;
};

const getNewColumnsByType = (columns = [], columnType, columnsToSet) => {
	const unchangedColumns = columnType && columns.length ? columns.filter(c => c.columnType !== columnType) : [];
	if (unchangedColumns.length > 1 && unchangedColumns[1].columnType === 'asset') {
		columnsToSet.unshift(unchangedColumns.shift());
		return [...columnsToSet, ...unchangedColumns];
	}
	return [...unchangedColumns, ...columnsToSet];
};

module.exports = {
	defaultState,
	getExplorerStateFromBookmark,
	buildRequestFromDataExplorerState,
	getNewColumnsByType,
};

import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {debtOutputOptions} from 'ki-common/options/debt';
import KiSelect from 'components/KiSelect';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';

function ScenarioFormRunResults(props) {
	const [isResultSecondary, setIsResultSecondary] = useState(false);
	const [selectedOutputType, setSelectedOutputType] = useState('');
	const [selectedSubOutputType, setSelectedSubOutputType] = useState('');
	const [resultEntitys, setResultEntitys] = useState([]);
	const [resultsColumns, setResultsColumns] = useState([]);
	const [resultsRows, setResultsRows] = useState([]);
	const [resultsError, setResultsError] = useState([]);
	//const [results, setResults] = useState([])
	//const [publishError, setPublishError] = useState('');

	const secondaryResultOptions = () => {
		const items = resultEntitys.map(item => {
			return {label: item.name, value: item.name};
		});

		items.unshift({label: 'Select...', value: ''});

		return items;
	};

	const formatResultData = res => {
		if (res) {
			const columns = res.response.columns.map((item, index) => {
				return {
					Header: item.displayName,
					accessor: `col-${index}`,
				};
			});

			const rows = res.response.rows.map(item => {
				const row = {};
				item.data.forEach((val, index) => {
					row[`col-${index}`] = val;
				});

				return row;
			});

			return {
				columns: columns,
				rows: rows,
				error: res.error,
			};
		} else {
			return {
				columns: [],
				rows: [],
				error: '',
			};
		}
	};

	const handleOutputType = val => {
		const entity = props.selectedScenarios.explorationResponses.filter(item => item.tag === val);
		const results = entity.length > 0 ? formatResultData(entity[0]) : [];
		setIsResultSecondary(false);
		setSelectedOutputType(val);
		setSelectedSubOutputType(entity.length > 0 ? entity[0].name : null);
		setResultEntitys(entity);
		setResultsColumns(results.columns);
		setResultsRows(results.rows);
		setResultsError(results.error);
		//([])
	};

	const handleSubOutputType = val => {
		const entity = props.selectedScenarios.explorationResponses.filter(
			item => item.name === val && item.tag === selectedOutputType
		);
		const results = formatResultData(entity[0]);

		setIsResultSecondary(true);
		setSelectedSubOutputType(val);
		//setResult(entity[0])
		setResultsColumns(results.columns);
		setResultsRows(results.rows);
		setResultsError(results.error);
		//setResults([])
	};

	const isSecondaryComboVisible = () => {
		return (resultEntitys && resultEntitys.length > 0 && resultEntitys[0].name) || isResultSecondary;
	};

	/*
	const handlePublishResults = () => {
		// TBD for now
		setPublishError('');
	};
	*/
	const validResultsCategories =
		props.selectedScenarios && props.selectedScenarios.explorationResponses
			? debtOutputOptions.filter(o =>
					props.selectedScenarios.explorationResponses
						.filter(r => r.response && r.response.totalNumberOfRows > 0)
						.map(item => item.tag)
						.includes(o.value)
			  )
			: debtOutputOptions;

	return (
		<div className="structure-block-item">
			<div style={{margin: '1.5rem'}}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							marginBottom: '2rem',
							marginRight: '2rem',
							width: '250px',
						}}
					>
						<div className="param-label">Entity</div>
						<KiSelect
							classNamePrefix="aut-select"
							value={validResultsCategories.find(item => item.value === selectedOutputType)}
							onChange={val => handleOutputType(val.value)}
							options={validResultsCategories}
						/>
					</div>
					{isSecondaryComboVisible() && (
						<div
							style={{
								marginBottom: '0.5rem',
								marginRight: '2rem',
								width: '250px',
							}}
						>
							<KiSelect
								classNamePrefix="aut-select"
								value={secondaryResultOptions().find(item => item.value === selectedSubOutputType)}
								onChange={val => handleSubOutputType(val.value)}
								options={secondaryResultOptions()}
							/>
						</div>
					)}
				</div>
				{/*publishError && (
					<div style={{textAlign: 'right', marginBottom: '10px'}}>
						<p className="text-error-red">{`Publish failed - ${publishError}`}</p>
					</div>
				)*/}
				<div style={{textAlign: 'left', marginBottom: '10px'}}>
					<p className="text-error-red">{resultsError}</p>
				</div>
				<AbsTable
					data={resultsRows}
					columns={resultsColumns}
					noDataMessage="No rows found"
					showNoDataMessage={resultsRows.length === 0}
				/>
			</div>
		</div>
	);
}

ScenarioFormRunResults.propTypes = {
	selectedScenarios: PropTypes.object.isRequired,
};

export default ScenarioFormRunResults;

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classnames from 'classnames';
import KiIconButton from 'components/KiIconButton';
import _get from 'lodash/get';
import styles from './KiTwoPanelList.theme.scss';

export class DefaultEditItem extends Component {
	static propTypes = {
		item: PropTypes.object,
		onClick: PropTypes.func,
	};

	static defaultProps = {
		item: {},
		onClick: () => {
			// eslint-disable-next-line
			console.log('Default onClick event');
		},
	};

	render() {
		const {item} = this.props;
		if (item === null) {
			return <span />;
		}
		return (
			<span onClick={this.props.onClick}>
				{Object.keys(item).map(key => {
					return (
						<div key={key}>
							{key} - {JSON.stringify(item[key], null, 2)}
						</div>
					);
				})}
			</span>
		);
	}
}

export class DefaultListItem extends Component {
	static propTypes = {
		item: PropTypes.object,
	};

	static defaultProps = {
		item: {},
	};

	render() {
		if (this.props.item === null) {
			return <span />;
		}
		return <span>{JSON.stringify(this.props.item, null, 2)}</span>;
	}
}

// QA class handle is ktpl
/*
the components can be passed in either using the function
	eg. editItemComponent: DefaultEditItem,
or by passing in a full component block
	eg. editItemComponent: <DefaultEditItem onClick={() => {CODE HERE}} />
 */
export class KiTwoPanelList extends Component {
	static propTypes = {
		itemList: PropTypes.array,
		itemKeyAttribute: PropTypes.string,
		listItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
		editItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
		listHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
		className: PropTypes.string,
	};
	// TODO add left/right panel width settings

	static defaultProps = {
		itemList: [],
		itemKeyAttribute: 'id',
		listItemComponent: DefaultListItem,
		editItemComponent: DefaultEditItem,
		listHeader: 'Item List',
		className: '',
	};

	static getDerivedStateFromProps(props, state) {
		let selectedItem = state.SelectedItem;
		let selectedItemId = state.selectedItemId;
		if (state.selectedItemId) {
			// Make sure to update selectedItem from new props
			selectedItem = props.itemList.find(element => {
				return _get(element, props.itemKeyAttribute, '') === state.selectedItemId;
			});

			// Check if the current selection was deleted then default to first if possible
			if (!selectedItem) {
				selectedItem = props.itemList[0];
			}

			selectedItemId = _get(selectedItem, props.itemKeyAttribute, '');
		}

		return {
			selectedItemId: selectedItemId,
			selectedItem: selectedItem,
			showEditPanel: true,
		};
	}

	constructor(props) {
		super(props);
		const first = props.itemList[0];
		const itemId = _get(first, props.itemKeyAttribute, '');
		this.state = {
			selectedItemId: itemId,
			selectedItem: first || null,
			showEditPanel: first ? true : false,
		};
		this.handleAddClick = this.handleAddClick.bind(this);
	}

	handleItemClick(itemId, item) {
		this.setState({
			selectedItemId: itemId,
			selectedItem: item,
			showEditPanel: true,
		});
	}

	handleAddClick() {
		this.setState({
			selectedItemId: '',
			selectedItem: {},
			showEditPanel: true,
		});
	}

	render() {
		const {selectedItem, selectedItemId} = this.state;
		const {listHeader, listItemComponent, editItemComponent, className} = this.props;
		let headerElement;
		switch (typeof listHeader) {
			case 'object':
				headerElement = React.cloneElement(listHeader, {}, []);
				break;
			case 'function':
				headerElement = React.createElement(listHeader, {}, []);
				break;
			case 'string':
			default:
				headerElement = listHeader;
		}
		return (
			<div className={classnames(`${styles['outerWrapper']}`, `${className}`)}>
				<div className={styles.listPanel}>
					<div className={styles.listHeaderRow}>
						<div className={styles.listHeader}>{headerElement}</div>
						<KiIconButton
							icon="add_circle"
							className={classnames(`${styles['addButton']}`, 'qa_ktpl_add')}
							onClick={() => this.handleAddClick()}
							inverse={true}
						/>
					</div>
					{this.props.itemList.map(item => {
						const itemId = _get(item, this.props.itemKeyAttribute, '');
						return (
							<div
								key={itemId}
								className={classnames({
									[`qa_ktpl_list_item_${itemId}`]: true,
									[`${styles['listItem']}`]: true,
									[`${styles['activeListItem']}`]: selectedItemId === itemId,
								})}
								onClick={() => this.handleItemClick(itemId, item)}
							>
								{typeof listItemComponent === 'object'
									? React.cloneElement(listItemComponent, {itemId, item}, [])
									: React.createElement(listItemComponent, {itemId, item}, [])}
							</div>
						);
					})}
				</div>
				<div className={styles.itemPanel}>
					{this.state.showEditPanel &&
						(typeof editItemComponent === 'object'
							? React.cloneElement(editItemComponent, {item: selectedItem, itemId: selectedItemId}, [])
							: React.createElement(editItemComponent, {item: selectedItem, itemId: selectedItemId}, []))}
				</div>
			</div>
		);
	}
}

export default KiTwoPanelList;

// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _includes from 'lodash/includes';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';
import _toLower from 'lodash/toLower';
import {Link} from 'react-router-dom';
// Externals
import ContextIcons from 'components/ContextSidebar/icons';
import KiAppBar from 'components/KiAppBar';
import KiConfirmModal from 'components/KiConfirmModal';
import KiFontIcon from 'components/KiFontIcon';
import KiIcons from 'components/KiIcons';
import KiIconButton from 'components/KiIconButton';
import KiInput from 'components/KiInput';
import KiList from 'components/KiList';
import eligibilityIcon from 'static/svgs/eligibility_analysis.svg';
import {datasetsApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';
// Relative
import './datasetList.scss';
import CriteriaBlock from './components/CriteriaBlock';
import KiProgressBar from 'components/KiProgressBar';
import SchemaBlock from './components/SchemaBlock';
import SnapshotBlock from './components/SnapshotBlock';
import {fetchDatasetList, setSelectedDataset} from './actions';

export const ConfirmSnapshotMessage = props => {
	const {snapshots, dataset} = props;
	const multi = snapshots.length > 1;
	let willDeleteDataset = false;
	let datasetName = '';
	if (dataset && dataset.snapshots) {
		willDeleteDataset = dataset.snapshots.length === snapshots.length;
		datasetName = dataset.name;
	}
	const msg = `Deleting ${multi ? 'these snapshots' : 'this snapshot'} will invalidate all Hypos run for ${
		multi ? 'these snapshot dates' : 'this snapshot date'
	}.`;
	const datasetMsg = `The dataset "${datasetName}" will be deleted.`;
	return (
		<div>
			<div>{msg}</div>
			{willDeleteDataset && (
				<div>
					<br />
					{datasetMsg}
				</div>
			)}
			<ul>{snapshots.map((snapshot, idx) => <li key={idx}>{snapshot}</li>)}</ul>
			<div>Are you sure you want to continue?</div>
		</div>
	);
};

ConfirmSnapshotMessage.propTypes = {
	snapshots: PropTypes.array,
	dataset: PropTypes.object,
};

ConfirmSnapshotMessage.defaultprops = {
	snapshots: [],
};

export class DatasetList extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		datasetList: PropTypes.array,
		fetchDatasetList: PropTypes.func.isRequired,
		setSelectedDataset: PropTypes.func,
		showSnackbar: PropTypes.func,
		history: PropTypes.object,
		isLoading: PropTypes.bool,
		user: PropTypes.object,
	};

	constructor(props) {
		super(props);
		document.title = `${this.props.app.kiVersion} - Datasets`;
	}

	state = {
		criteriaEditorOpenDatasets: [],
		snapshotsOpenDatasets: [],
		isDeleteSnapshotsConfirmActive: false,
		deleteSnapshotsList: [],
		deleteSnapshotsFromDataset: null,
		schemaEditorOpenDatasets: [],
		pendingDelete: false,
		filteredDatasetList: this.props.datasetList,
		searchTerm: '',
	};

	componentDidMount() {
		this.props.fetchDatasetList(true);
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps.datasetList, this.props.datasetList)) {
			this.setState({filteredDatasetList: this.props.datasetList});
		}
	}

	handleEligibilityAnalysisClick = (event, dataset) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.setSelectedDataset(dataset);
		this.props.history.push(`/fundingAnalysis/${dataset.datasetId}`);
	};

	handleEditCriteriaClick = (e, datasetId) => {
		e.preventDefault();
		const {criteriaEditorOpenDatasets} = this.state;
		const idx = criteriaEditorOpenDatasets.indexOf(datasetId);
		if (idx > -1) {
			this.setState({
				criteriaEditorOpenDatasets: _remove(criteriaEditorOpenDatasets, d => d !== datasetId),
			});
		} else {
			this.setState({
				criteriaEditorOpenDatasets: [datasetId, ...this.state.criteriaEditorOpenDatasets],
			});
		}
	};

	handleEditSnapshotsClick = (e, datasetId) => {
		e.preventDefault();
		this.handleEditSnapshots(datasetId);
	};

	handleEditSnapshots = datasetId => {
		const {snapshotsOpenDatasets} = this.state;
		const idx = snapshotsOpenDatasets.indexOf(datasetId);
		if (idx > -1) {
			this.setState({
				snapshotsOpenDatasets: _remove(snapshotsOpenDatasets, d => d !== datasetId),
			});
		} else {
			this.setState({
				snapshotsOpenDatasets: [datasetId, ...this.state.snapshotsOpenDatasets],
			});
		}
	};

	handleEditSchemaClick = (e, datasetId) => {
		e.preventDefault();
		const {schemaEditorOpenDatasets} = this.state;
		const idx = schemaEditorOpenDatasets.indexOf(datasetId);
		if (idx > -1) {
			this.setState({
				schemaEditorOpenDatasets: _remove(schemaEditorOpenDatasets, d => d !== datasetId),
			});
		} else {
			this.setState({
				schemaEditorOpenDatasets: [datasetId, ...this.state.schemaEditorOpenDatasets],
			});
		}
	};

	handleDatasetDelete = dataset => {
		return datasetsApi
			.deleteDatasets([
				{
					id: dataset.datasetId,
					name: dataset.name,
				},
			])
			.then(res => {
				this.setState({
					pendingDelete: false,
					isDeleteSnapshotsConfirmActive: false,
					deleteSnapshotsList: [],
					deleteSnapshotsFromDataset: null,
				});
				this.props.fetchDatasetList(true);
				this.props.showSnackbar(res.message);
			})
			.catch(() => {
				this.setState({pendingDelete: false});
			});
	};

	showDeleteSnapshotsConfirm = (dataset, snapshots) => {
		this.setState({
			isDeleteSnapshotsConfirmActive: true,
			deleteSnapshotsList: snapshots,
			deleteSnapshotsFromDataset: dataset,
		});
	};

	hideDeleteSnapshotsConfirm = () => {
		this.setState({
			isDeleteSnapshotsConfirmActive: false,
			deleteSnapshotsList: [],
			deleteSnapshotsFromDataset: null,
		});
	};

	handleDeleteSnapshots = () => {
		this.setState(
			{
				pendingDelete: true,
			},
			() => {
				const snapshotsToDeleteCount = this.state.deleteSnapshotsList.length;
				const selectedDatasetSnapshotCount = this.state.deleteSnapshotsFromDataset
					? this.state.deleteSnapshotsFromDataset.snapshots.length
					: 0;
				if (
					this.state.deleteSnapshotsFromDataset &&
					this.state.deleteSnapshotsFromDataset.datasetId &&
					snapshotsToDeleteCount === selectedDatasetSnapshotCount
				) {
					return this.handleDatasetDelete(this.state.deleteSnapshotsFromDataset);
				} else {
					return datasetsApi
						.deleteDatasetSnapshots(this.state.deleteSnapshotsFromDataset, this.state.deleteSnapshotsList)
						.then(res => {
							const datasetInUse = this.state.deleteSnapshotsFromDataset;
							this.setState(
								{
									pendingDelete: false,
									isDeleteSnapshotsConfirmActive: false,
									deleteSnapshotsList: [],
									deleteSnapshotsFromDataset: null,
								},
								() => {
									this.handleEditSnapshots(datasetInUse.datasetId);
									this.props.fetchDatasetList(true);
									this.props.showSnackbar(res.message);
								}
							);
						})
						.catch(() => {
							this.setState({pendingDelete: false});
						});
				}
			}
		);
	};

	// Filter datasets, case insensitive
	onSearchTermChange = value => {
		const filteredDatasetList = this.props.datasetList.filter(dataset =>
			_includes(_toLower(dataset.name), _toLower(value))
		);
		this.setState({
			filteredDatasetList,
			searchTerm: value,
		});
	};

	getLatestSubmission = dataset => {
		if (!dataset.snapshots.length) {
			return 'Commit Failed';
		}
		return dataset.snapshots
			.map(snapshot => snapshot.snapshotDate)
			.sort()
			.slice(-1);
	};

	render() {
		const {isLoading = false} = this.props;
		const {criteriaEditorOpenDatasets, snapshotsOpenDatasets, schemaEditorOpenDatasets} = this.state;

		if (isLoading) {
			return (
				<section className="dataset-list">
					<KiAppBar title="Datasets" className="top-bar">
						{this.props.user.groups.includes('SystemAdmins') && (
							<Link to={'/datasets/create'}>
								<KiIconButton icon="add_circle" inverse title="Click to create a new dataset." />
							</Link>
						)}
					</KiAppBar>
					<div className="ki-panel no-list-items-container">
						<div>{isLoading ? <KiProgressBar /> : 'Upload submissions to view their datasets here'}</div>
					</div>
				</section>
			);
		}

		return (
			<section className="dataset-list">
				<KiAppBar title="Datasets" className="top-bar">
					{this.props.user.groups.find(u => u === 'SystemAdmins') && (
						<Link to={'/datasets/create'}>
							<KiIconButton icon="add_circle" inverse title="Click to create a new dataset." />
						</Link>
					)}
				</KiAppBar>

				<KiList className="ki-panel">
					<KiInput
						className="search-input"
						onChange={this.onSearchTermChange}
						label="Dataset Search"
						value={this.state.searchTerm}
					/>

					{this.state.filteredDatasetList.map((dataset, idx) => {
						const {datasetId} = dataset;
						return (
							<div
								key={idx}
								className={`dataset-list-item ${
									criteriaEditorOpenDatasets.includes(datasetId) ? 'active' : ''
								}`}
							>
								<div className="dataset-wrapper">
									<div className="dataset-title">{dataset.name}</div>

									<div className="dataset-list-row-container">
										<div className="dataset-information">
											<h2 className="dataset-block-header">Information</h2>
											<hr className="light-grey-divider" />
											<p>Last Submission: {this.getLatestSubmission(dataset)}</p>
										</div>

										<div className="dataset-explore">
											<h2 className="dataset-block-header">Explore</h2>
											<hr className="light-grey-divider" />

											<Link
												className={`${
													dataset.snapshots.length ? 'icon-span' : 'icon-span-disabled'
												}`}
												to={`/dataExplorer/${dataset.datasetId}`}
											>
												<KiFontIcon value="explore" />
												<p>Summary / Asset / Time Series</p>
											</Link>

											<Link
												className={`${
													dataset.snapshots.length ? 'icon-span' : 'icon-span-disabled'
												}`}
												data-automation="ds-list-link-eligibility-analysis"
												to={`/fundingAnalysis/${dataset.datasetId}`}
												onClick={e => this.handleEligibilityAnalysisClick(e, dataset)}
											>
												<img alt="" src={eligibilityIcon} className="dataset-icon" />
												<p>Funding Analysis</p>
											</Link>

											<Link
												to={`/datasets/${datasetId}/debt`}
												className={`${
													dataset.snapshots.length ? 'icon-span' : 'icon-span-disabled'
												}`}
												data-automation="ds-list-link-debt"
											>
												<KiFontIcon value="trending_down" />
												<p>Debt</p>
											</Link>
											<span
												className={`${
													dataset.snapshots.length ? 'icon-span' : 'icon-span-disabled'
												} ${schemaEditorOpenDatasets.includes(datasetId) ? 'hover' : ''}`}
												data-automation="ds-list-link-view-schema"
												onClick={e => this.handleEditSchemaClick(e, dataset.datasetId)}
											>
												<KiFontIcon value="device_hub" />
												<p>View Schema</p>
											</span>
											<Link
												className="icon-span"
												to={`/datasets/${dataset.datasetId}/logs`}
												data-automation="ds-list-link-logs"
											>
												<KiFontIcon value="reorder" />
												<p>Logs</p>
											</Link>
										</div>
										{!this.props.user.groups.find(u => u === 'SystemAdmins') ? (
											<div className="dataset-manage">
												<h2 className="dataset-block-header">Manage</h2>
												<hr className="light-grey-divider" />
												<div className="dataset-row">
													<div className="dataset-column" />
												</div>
											</div>
										) : (
											<div className="dataset-manage">
												<h2 className="dataset-block-header">Manage</h2>
												<hr className="light-grey-divider" />
												<div className="dataset-row">
													<div className="dataset-column">
														<span
															className={`${
																dataset.snapshots.length
																	? 'icon-span'
																	: 'icon-span-disabled'
															} ${
																snapshotsOpenDatasets.includes(datasetId) ? 'hover' : ''
															}`}
															onClick={e =>
																this.handleEditSnapshotsClick(e, dataset.datasetId)
															}
															data-automation="ds-list-link-delete-snapshot"
														>
															<KiFontIcon value="delete" />
															<p>Delete Snapshot</p>
														</span>
														<Link
															className="icon-span"
															to={`/datasets/${dataset.datasetId}/submission/upload`}
															data-automation="ds-list-link-submissions"
														>
															<KiFontIcon value="cloud_upload" />
															<p>Upload</p>
														</Link>
														<Link
															className={`${
																dataset.snapshots.length
																	? 'icon-span'
																	: 'icon-span-disabled'
															}`}
															to={`/associatedDataList/${dataset.datasetId}`}
															data-automation="ds-list-link-associated-data"
														>
															<KiFontIcon value={<KiIcons.KiAssociatedData />} />
															<p>Associated Data</p>
														</Link>
														{window.WATERFALL_ENABLED === 'true' && (
															<Link
																className={`${
																	dataset.snapshots.length
																		? 'icon-span'
																		: 'icon-span-disabled'
																}`}
																to={`/dealStructures/${dataset.datasetId}`}
																data-automation="ds-list-link-deal-structures"
															>
																<KiFontIcon value="more" />
																<p>Deal Structures</p>
															</Link>
														)}
													</div>

													<div className="dataset-column">
														<Link
															className={`${
																dataset.snapshots.length
																	? 'icon-span'
																	: 'icon-span-disabled'
															}`}
															to={`/poolMaintenance/${dataset.datasetId}`}
															data-automation="ds-list-link-pool-maintenance"
														>
															<KiFontIcon value="build" />
															<p>Pool Maintenance</p>
														</Link>

														<span
															className={`${
																dataset.snapshots.length
																	? 'icon-span'
																	: 'icon-span-disabled'
															} ${
																criteriaEditorOpenDatasets.includes(datasetId)
																	? 'hover'
																	: ''
															}`}
															data-automation="ds-list-link-global-exclusions"
															onClick={e =>
																this.handleEditCriteriaClick(e, dataset.datasetId)
															}
														>
															<KiFontIcon
																value={<ContextIcons.FiltersIcon />}
																onClick={e =>
																	this.handleEditCriteriaClick(e, dataset.datasetId)
																}
															/>
															<p>Global Exclusions</p>
														</span>

														<Link
															className={`${
																dataset.snapshots.length
																	? 'icon-span'
																	: 'icon-span-disabled'
															}`}
															to={`/debtInputMaintenance/${dataset.datasetId}`}
															data-automation="ds-list-link-debt-inputs"
														>
															<KiFontIcon value="keyboard" />
															<p>Debt Inputs</p>
														</Link>
														<Link
															className={`${
																dataset.snapshots.length
																	? 'icon-span'
																	: 'icon-span-disabled'
															}`}
															to={`/mappings/${dataset.datasetId}`}
															data-automation="ds-list-link-mappings"
														>
															<KiFontIcon value="edit_location" />
															<p>Mappings</p>
														</Link>
														<Link
															className={`${
																dataset.snapshots.length
																	? 'icon-span'
																	: 'icon-span-disabled'
															}`}
															to={`/datasets/${dataset.datasetId}/fundingVehicles`}
															data-automation="ds-list-link-funding-vehicles"
														>
															<KiFontIcon value="build" />
															<p>Funding Vehicles</p>
														</Link>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
								<CriteriaBlock
									dataset={dataset}
									datasetId={datasetId}
									isVisible={criteriaEditorOpenDatasets.includes(datasetId)}
									handleEditCriteriaClick={this.handleEditCriteriaClick}
								/>
								<SnapshotBlock
									dataset={dataset}
									isVisible={snapshotsOpenDatasets.includes(datasetId)}
									handleEditSnapshotsClick={this.handleEditSnapshotsClick}
									showDeleteSnapshotsConfirm={this.showDeleteSnapshotsConfirm}
								/>
								{schemaEditorOpenDatasets.includes(datasetId) && (
									<SchemaBlock
										dataset={dataset}
										datasetId={datasetId}
										isVisible={schemaEditorOpenDatasets.includes(datasetId)}
										handleEditSchemaClick={this.handleEditSchemaClick}
									/>
								)}
							</div>
						);
					})}
				</KiList>
				<KiConfirmModal
					header={`Delete Dataset Snapshot${this.state.deleteSnapshotsList.length > 1 ? 's' : ''}`}
					message={
						<ConfirmSnapshotMessage
							snapshots={this.state.deleteSnapshotsList}
							dataset={this.state.deleteSnapshotsFromDataset}
						/>
					}
					acceptFunc={this.handleDeleteSnapshots}
					rejectFunc={this.hideDeleteSnapshotsConfirm}
					acceptLabel={this.state.pendingDelete ? 'Deleting...' : 'Delete'}
					rejectLabel="Cancel"
					acceptDisabled={this.state.pendingDelete}
					active={this.state.isDeleteSnapshotsConfirmActive}
				/>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	isLoading: state.datasetList.isLoading,
	datasetList: state.datasetList.data,
	user: state.user,
});

export default connect(
	mapStateToProps,
	{fetchDatasetList, setSelectedDataset, showSnackbar}
)(DatasetList);

export const getCohortColumns = (columns = [], dataColumn, isNew, isGrouped, onlyNumeric) => {
	const selected = columns.find(c => c._id === dataColumn);
	let list = [];
	if (isNew) {
		list = columns.filter(c => c.columnType === (isGrouped ? 'aggregate' : 'asset'));
	} else if (selected) {
		list = columns.filter(c => c.columnType === selected.columnType);
	}
	if (onlyNumeric) {
		list = list.filter(cc => cc.dataType === 'numeric');
	}
	return list;
};

export const removeEligibleColumns = (columns = [], eligibleColumns = []) => {
	const output = columns.reduce((output, curCol) => {
		if (!eligibleColumns.includes(curCol._id)) {
			output.push(curCol);
			return output;
		}
		return output;
	}, []);
	return output;
};

export default {
	getCohortColumns,
	removeEligibleColumns,
};

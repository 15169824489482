// Globals
import React, {useEffect, useState, useContext} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {DragDropContext} from 'react-beautiful-dnd';

// Project imports
import KiList from 'components/KiList';
import KiListItem from 'components/KiList/KiListItem';
import {getGroupByColumnsForDataset} from 'api/columnServiceApi';

// Local imports
import styles from './fundindModelDetail.theme.scss';
import FundingAnalysisContext from '../fundingAnalysisContext';
import FundingVehicleDragList from './FundingVehicleDragList';
import ConstraintsModal from './ConstraintsModal';
/**
 * [FundingAnalysis description]
 * @param {[type]} options.isDebtFormula       [description]
 */
function FundingModelDetailContents({dragEnabled}) {
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const datasetId = useSelector(state => state.datasetList.selected.datasetId);
	const [isConstraintModalActive, setIsConstraintModalActive] = useState(false);
	// TODO move to ki-common
	const optimizationTypes = [
		{label: 'First Compliant', value: 'first'},
		{label: 'First Break', value: 'best'},
		{label: 'Minimize Excess', value: 'minimize_excess'},
	];
	const modelTypes = [
		{label: 'Balanced Funding', value: 'balanced_funding'},
		{label: 'Max Funding', value: 'max_funding'},
	];

	const [groupByName, setGroupByName] = useState('loading...');

	// On Mount
	useEffect(
		() => {
			async function getGroupByName() {
				const groupByCols = await getGroupByColumnsForDataset(datasetId);
				const match = groupByCols.find(col => col._id === fundingAnalysisContext.groupBy);
				setGroupByName(match ? match.detailedDisplayName : 'No Detail');
			}
			if (datasetId) {
				getGroupByName();
			} else {
				setGroupByName('loading...');
			}
		},
		[datasetId]
	);

	if (!fundingAnalysisContext.constraintGroups) {
		return null;
	}

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<h4>{fundingAnalysisContext.name}</h4>
				<hr />
			</div>
			<div className={styles.details}>
				<section className={styles.sources} style={{pointerEvents: `${dragEnabled ? 'auto' : 'none'}`}}>
					<DragDropContext onDragEnd={() => ({})}>
						<div>
							<div className={styles.sectionHeader}>
								<p className={styles.detailSectionHeader}>SOURCES</p>
							</div>
							<div className={styles.sectionContent}>
								<FundingVehicleDragList
									itemList={fundingAnalysisContext.fvSources}
									droppableId={'1'}
									dragEnabled={false}
									isOrderEnabled
								/>
							</div>
						</div>
						<div>
							<div className={styles.sectionHeader}>
								<p className={styles.detailSectionHeader}>TARGETS</p>
							</div>
							<div className={styles.sectionContent}>
								<FundingVehicleDragList
									itemList={fundingAnalysisContext.fvTargets}
									droppableId={'2'}
									dragEnabled={false}
									isOrderEnabled
								/>
							</div>
						</div>
					</DragDropContext>
				</section>
				<section className={styles.options}>
					<div className={styles.sectionHeader}>
						<p className={styles.detailSectionHeader}>OPTIONS</p>
					</div>
					<div className={(styles.sectionContent, styles.optionsTable)}>
						<div className={styles.optionsColumn}>
							<p style={{fontWeight: 'bold'}}>Type:</p>
							<p style={{fontWeight: 'bold'}}>Group By:</p>
							<p style={{fontWeight: 'bold'}}>Optimization:</p>
							{/*<p style={{fontWeight: 'bold'}}>Fractional:</p>*/}
							<p style={{fontWeight: 'bold'}}>Include Submitted:</p>
							<p style={{fontWeight: 'bold'}}>Blended:</p>
							<p style={{fontWeight: 'bold'}}>Topoff:</p>
						</div>
						<div className={styles.optionsColumn}>
							<p>
								{modelTypes.find(t => t.value === fundingAnalysisContext.type)
									? modelTypes.find(t => t.value === fundingAnalysisContext.type).label
									: ' '}
							</p>
							<p>{groupByName ? groupByName : '\u00A0'}</p>
							<p>
								{optimizationTypes.find(t => t.value === fundingAnalysisContext.optimization)
									? optimizationTypes.find(t => t.value === fundingAnalysisContext.optimization).label
									: ' '}
							</p>
							{/*<p>{fundingAnalysisContext.isFractional ? 'True' : 'False'}</p>*/}
							<p>{fundingAnalysisContext.includePrevAssets ? 'True' : 'False'}</p>
							<p>{fundingAnalysisContext.isBlended ? 'True' : 'False'}</p>
							<p>{fundingAnalysisContext.isTopoff ? 'True' : 'False'}</p>
						</div>
					</div>
				</section>
				<section className={styles.constraints}>
					<div className={styles.sectionHeader}>
						<p className={styles.detailSectionHeader}>CONSTRAINT GROUPS</p>
					</div>
					<div className={styles.sectionContent}>
						<KiList className={styles.constraintsList}>
							{fundingAnalysisContext.constraintGroups.map((c, index) => (
								<KiListItem
									key={index}
									itemContent={c.constraintGroupName}
									onClick={() => {
										fundingAnalysisContext.setStateItem('currentConstraintGroup', c);
										setIsConstraintModalActive(true);
									}}
								/>
							))}
						</KiList>
					</div>
				</section>
			</div>
			{!!isConstraintModalActive && (
				<ConstraintsModal
					isActive={isConstraintModalActive}
					setModalActive={setIsConstraintModalActive}
					fundingVehicles={[
						...fundingAnalysisContext.fvSources.map(fv => {
							fv.fvType = 'source';
							return fv;
						}),
						...fundingAnalysisContext.fvTargets.map(fv => {
							fv.fvType = 'target';
							return fv;
						}),
					].filter(fv => !fv.isUnencumbered && fv.fvName !== 'Unencumbered')} //filter out isUnencumbered
					curConstraintGroup={fundingAnalysisContext.currentConstraintGroup}
					allConstraintGroups={[]}
					fundingModel={fundingAnalysisContext.model}
					isReadOnly={true}
				/>
			)}
		</div>
	);
}

FundingModelDetailContents.propTypes = {
	dragEnabled: PropTypes.boolean,
	// constraintGroups: PropTypes.array,
};

FundingModelDetailContents.defaultProps = {
	dragEnabled: true,
	// constraintGroups: [],
};

export default FundingModelDetailContents;

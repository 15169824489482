// Global imports
import React, {createContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {usePrevious} from 'utils/customHooks';

// Local imports
import {useMergedState} from 'utils/customHooks';
import {
	fetchBookmarkById,
	fetchDefaultBookmark,
	fetchScenarios,
	fetchModel,
	fetchQuickFilters,
} from 'api/fundingAnalysisApi';
import {fetchPortfolioDates, getCalculatedDateInfo} from 'api/datasetDatesApi';

const defaultState = {
	_id: null,
	createdBy: null,
	createdAt: null,
	updatedAt: null,
	isDefault: false,
	isFavorite: false,
	name: '',
	datasetId: null,
	createDate: null,
	tags: [],
	settings: {
		viewType: null, // scenario|summary|pool|excess|breaches
		tableType: null,
		sortOrder: 'asc',
		sortColumn: null,
		constraintGroup: 'setup',
		statementDate: dateToShortDate(new Date()),
		dateContext: '',
		scenarioType: 'assetSnapshot',
		scenarioId: null,
		transferDate: null,
		fundingVehicleIds: ['all'],
	},
	viewColumns: {
		scenario: [],
		summary: [],
		breaches: [],
		compliance: [],
	},
};

// Main context
export const BookmarkContext = createContext();
BookmarkContext.displayName = 'Funding Bookmark Context';

// Main provider
export const BookmarkProvider = ({children}) => {
	const [state, setState, resetState] = useMergedState(defaultState);
	const [parent, setParent] = useState({});
	const [appliedBookmark, setAppliedBookmark] = useState(_.cloneDeep(defaultState));
	const [scenario, setScenario] = useState({});
	const [appliedScenario, setAppliedScenario] = useState({});
	const [model, setModel] = useState({});
	const [hasChanges, setHasChanges] = useState(false);
	const prevState = usePrevious(state);

	// Initialization variables
	const [coreSettings, setCoreSettings, resetCoreSettings] = useMergedState({
		datasetId: '',
		bookmarkId: null,
		scenarioId: null,
	});

	// UI State Items
	const [datasetDateList, setDatasetDateList] = useState([]);
	const [scenarioList, setScenarioList] = useState([]);
	const [scenarioFilterList, setScenarioFilterList] = useState([]);
	const [infoRibbonMessage, setInfoRibbonMessage] = useState(null);

	useEffect(
		() => {
			if (state?._id && prevState?._id && prevState._id === state._id) {
				setHasChanges(true);
			}
		},
		[state]
	);

	const resetBookmarkContext = () => {
		resetCoreSettings({
			datasetId: '',
			bookmarkId: null,
			scenarioId: null,
		});
	};

	const fetchScenarioQuickFilters = async ({statementDate, dateContext, viewType}) => {
		if (viewType === 'scenario') {
			setScenarioFilterList(scenarioList);
			return scenarioList;
		} else {
			if (!coreSettings.datasetId) {
				return false; // Do not tyr to fetch lists with no datasetId
			}
			const lists = await fetchQuickFilters(
				coreSettings.datasetId,
				statementDate || dateToShortDate(new Date()),
				dateContext
			);
			setScenarioFilterList(lists.scenarioList);
			return lists.scenarioList;
		}
	};

	const getRibbonMessage = () => {
		if (!['summary', 'eligibility'].includes(state.settings.tableType)) {
			return null;
		}
		let targetDate = datasetDateList.find(dateColumn => dateColumn._id === state.settings.dateContext);
		if (!targetDate) {
			targetDate = datasetDateList.find(dateColumn => dateColumn.name === 'Latest Snapshot');
		}
		return getCalculatedDateInfo(
			targetDate._id,
			datasetDateList,
			state.settings.statementDate || dateToShortDate(new Date()),
			undefined,
			false,
			coreSettings.datasetId
		).then(res => {
			setInfoRibbonMessage(
				`Data results for ${res[0].calculatedDate} using Statement Date and ${targetDate.name} context`
			);
		});
	};

	useEffect(
		() => {
			getRibbonMessage();
			fetchScenarioQuickFilters(state.settings);
		},
		[state.settings.dateContext, state.settings.statementDate, state.settings.tableType]
	);

	const applyBookmark = (newBookmark = state, newScenario = scenario) => {
		setAppliedBookmark(_.cloneDeep(newBookmark));
		setAppliedScenario(_.cloneDeep(newScenario));
		setHasChanges(false);
	};

	// Just loads the correct bookmark into the state, another call is made to get data
	const loadBookmark = async (
		bookmarkData,
		apply = false,
		datasetDates = datasetDateList,
		scenarios = scenarioList
	) => {
		const settings = bookmarkData.settings;

		// Default statement date to current date
		if (!settings.statementDate) {
			_.set(bookmarkData, 'settings.statementDate', dateToShortDate(new Date()));
		}

		// If this is a scenario view and none is selected use the first one from the list
		if (settings.tableType === 'scenario' && _.isEmpty(settings.scenarioId)) {
			_.set(bookmarkData, 'settings.scenarioId', scenarios[0]._id);
		}

		let targetScenario = scenario; //Used to prevent race condition with setScenario

		if (settings.tableType === 'scenario') {
			// Fetch the associated scenario
			targetScenario = scenarios.find(s => s._id === settings.scenarioId);
			setScenario(targetScenario);

			// Fetch the associated model
			// TODO check if its the same model to prevent extra calls
			const model = await fetchModel(targetScenario.fundingModelId);
			setModel(model);
			_.set(bookmarkData, 'settings.isBlended', model.isBlended ? true : false);
		} else {
			// All non-scenario views need a date context
			if (!settings.dateContext) {
				const latestDateContext = datasetDates.find(date => date.name === 'Latest Snapshot');
				_.set(bookmarkData, 'settings.dateContext', latestDateContext._id);
			}
			await fetchScenarioQuickFilters(bookmarkData.settings);
		}
		resetState(bookmarkData);
		//getRibbonMessage(settings.tableType, settings.statementDate, settings.dateContext, datasetDates);
		if (apply) {
			applyBookmark(bookmarkData, targetScenario);
		}
	};

	const loadBookmarkById = async bookmarkId => {
		const bookmarkData = await fetchBookmarkById(bookmarkId);
		loadBookmark(bookmarkData);
	};

	// If there is a passed dataset use that, otherwise use state
	const fetchDatasetDates = async datasetIdParam => {
		const dates = await fetchPortfolioDates(datasetIdParam || coreSettings.datasetId);
		setDatasetDateList(dates);
		return dates;
	};

	// If there is a passed dataset use that, otherwise use state
	const getScenarios = async datasetIdParam => {
		const scenarios = await fetchScenarios(datasetIdParam || coreSettings.datasetId);
		setScenarioList(scenarios);
		return scenarios;
	};

	const _initializeBookmark = async () => {
		const {datasetId, bookmarkId, scenarioId} = coreSettings;
		//console.log(`_initializeBookmark datasetId:${datasetId}, bookmarkId:${bookmarkId}, scenarioId:${scenarioId}`)
		if (!datasetId) {
			return false; // Shortcut out since page is not loaded
		}
		const datasetDatesRes = await fetchDatasetDates(datasetId);
		const scenariosRes = await getScenarios(datasetId);

		let bookmarkData;
		if (bookmarkId) {
			//console.log(`_initializeBookmark bookmarkId`, bookmarkId);
			bookmarkData = await fetchBookmarkById(bookmarkId);
			// Links from the scenarios list will include the bookmarkId and the scenario Id
			// otherwise leave this field alone or bookmarks saved with a scenario will not load
			// the correct scenario
			if (scenarioId) {
				_.set(bookmarkData, 'settings.scenarioId', scenarioId);
			}
		} else if (scenarioId) {
			//console.log(`_initializeBookmark scenarioId`, scenarioId);
			bookmarkData = await fetchDefaultBookmark(datasetId, 'scenario');
			_.set(bookmarkData, 'settings.scenarioId', scenarioId);
			_.set(bookmarkData, 'settings.scenarioType', 'hypo');
		} else {
			//console.log(`_initializeBookmark datasetId`, datasetId);
			bookmarkData = await fetchDefaultBookmark(datasetId, 'summary');
		}

		loadBookmark(bookmarkData, true, datasetDatesRes, scenariosRes);
	};

	const initializeBookmark = async (datasetId, bookmarkId, scenarioId) => {
		resetState(defaultState);
		setParent(null);
		const newSettings = {
			datasetId,
			bookmarkId,
			scenarioId,
		};
		if (datasetId !== coreSettings.datasetId) {
			resetCoreSettings(newSettings); // Fully replace
		} else {
			setCoreSettings(newSettings); // Only change differences
		}
	};

	// When one of the core values changes, re-initialize the bookmark
	useEffect(
		() => {
			_initializeBookmark();
		},
		[coreSettings]
	);

	// Requires more than just a re-fetch of table data
	// Re-load for setting scenario, model, and isBlended
	const setScenarioId = async scenarioId => {
		const bookmarkData = _.cloneDeep(state);
		_.set(bookmarkData, 'settings.scenarioId', scenarioId);
		loadBookmark(bookmarkData);
	};

	// Requires more than just a re-fetch of table data
	const setViewType = async viewType => {
		setParent(null);
		const bookmarkData = _.cloneDeep(state);
		const latestSnapshotContext = datasetDateList.find(date => date.name === 'Latest Snapshot');

		_.set(bookmarkData, 'settings.transferDate', null);
		_.set(bookmarkData, 'settings.viewType', viewType);
		_.set(bookmarkData, 'settings.tableType', viewType);
		_.set(bookmarkData, 'settings.dateContext', latestSnapshotContext._id);

		switch (viewType) {
			case 'summary':
				_.set(bookmarkData, 'settings.scenarioType', 'lastApproved');
				_.set(bookmarkData, 'settings.scenarioId', '');
				_.set(bookmarkData, 'settings.constraintGroup', 'setup');
				break;
			case 'scenario':
				_.set(bookmarkData, 'settings.scenarioId', scenarioList[0]._id);
				_.set(bookmarkData, 'settings.scenarioType', 'hypo');
				_.set(bookmarkData, 'settings.statementDate', null);
				_.set(bookmarkData, 'settings.constraintGroup', 'setup');
				// isBlended set in loadBookmark method
				break;
			default:
				break;
		}

		loadBookmark(bookmarkData);
	};

	// Start - Drilldown Logic
	// Save a parent view of the current state, then modify the current view to add
	// required settings for the drilldown views
	const loadDrillDown = (type, fvId) => {
		const newParent = _.cloneDeep(state);
		setParent(newParent);

		const bookmarkData = _.cloneDeep(state);
		_.set(bookmarkData, 'settings.viewType', type);
		_.set(bookmarkData, 'settings.fundingVehicleIds', [fvId]);
		loadBookmark(bookmarkData, true);
	};

	const restoreParent = () => {
		const bookmarkData = _.cloneDeep(parent);
		setParent(null);
		loadBookmark(bookmarkData, true);
	};
	// End - Drilldown Locig

	return (
		<BookmarkContext.Provider
			displayName="Funding Bookmark Provider"
			value={{
				bookmark: state,
				scenario,
				model,
				setBookmark: setState,
				loadBookmark,
				loadBookmarkById,
				initializeBookmark,
				setScenarioId,
				setViewType,
				loadDrillDown,
				parent,
				restoreParent,
				appliedBookmark,
				applyBookmark,
				appliedScenario,
				ui: {
					infoRibbonMessage,
					datasetDateList,
					scenarioList,
					scenarioFilterList,
				},
				hasChanges,
				resetBookmarkContext,
			}}
		>
			{children}
		</BookmarkContext.Provider>
	);
};

BookmarkProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

BookmarkProvider.defaultProps = {};

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiFontIcon from 'components/KiFontIcon';
import './SchemaBlock.scss';
import {categorySchemasApi} from 'api';

export class SchemaBlock extends Component {
	//eslint-disable-line react/prefer-stateless-function
	static propTypes = {
		dataset: PropTypes.object,
		isVisible: PropTypes.bool,
		handleEditSchemaClick: PropTypes.func,
	};

	state = {
		schema: null,
	};

	componentDidMount() {
		if (this.props.dataset) {
			categorySchemasApi.fetchCategorySchemaBySnapshotDate(this.props.dataset).then(schema => {
				this.setState({schema: schema});
			});
		}
	}

	render() {
		if (!this.props.isVisible || !this.state.schema) {
			return null;
		}

		const {datasetId} = this.props.dataset;

		return (
			<div className="dataset-list-schema-block">
				<section className="block-header">
					<p>Schema ({this.state.schema.columns.length} Columns)</p>
					<KiFontIcon
						value="close"
						className="close-icon"
						onClick={e => this.props.handleEditSchemaClick(e, datasetId)}
					/>
				</section>
				<section className="dataset-schema-list-container">
					<table>
						<thead>
							<tr className="schema-item header">
								<th>Display Name</th>
								<th>Map Column</th>
								<th>ID</th>
								<th>Map Type</th>
								<th>Data Type</th>
								<th className="expando-column" />
							</tr>
						</thead>
						<tbody>
							{this.state.schema.columns.map(column => (
								<tr key={column.id} className="schema-item">
									<td>{column.displayName}</td>
									<td>{column.mapColumn}</td>
									<td>{column.id}</td>
									<td>{column.mapType}</td>
									<td>{column.dataType}</td>
									<td className="expando-column" />
								</tr>
							))}
						</tbody>
					</table>
				</section>
			</div>
		);
	}
}

export default connect()(SchemaBlock);

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink as Link, withRouter} from 'react-router-dom';
import {showGlobalNav, hideGlobalNav} from 'state/actions/App';
import analytics from '../../analytics';
import {
	auditIcon,
	businessFunctionsIcon,
	calendarsIcon,
	dashboardsIcon,
	datasetsIcon,
	fundingIcon,
	helpIcon,
	ledgersIcon,
	reportsIcon,
	forecastingIcon,
	datasetCopyIcon,
} from './icons';
import {userGroupsIntersect, USER_GROUPS} from '../../utils/userGroupsUtil';
const helpURL = '/help/Ki%20Training%20Exercises.htm';

const navSchema = activeNav => [
	{
		to: '/dashboards',
		label: 'DASHBOARDS',
		icon: dashboardsIcon,
		access: true,
		visible: true,
	},
	{
		to: '/datasets',
		label: 'DATASETS',
		icon: datasetsIcon,
		access: true,
		activeCheck: activeNav === 'datasets',
		visible: true,
	},
	{
		to: '/reports',
		label: 'REPORTS',
		icon: reportsIcon,
		access: true,
		visible: true,
	},
	{
		to: '/fundingAnalysis',
		label: 'FUNDING',
		icon: fundingIcon,
		access: true,
		visible: true,
	},
	{
		to: '/businessFunctions',
		label: 'BUSINESS FUNCTIONS',
		icon: businessFunctionsIcon,
		access: true,
		visible: true,
	},
	{
		to: '/audit',
		label: 'AUDIT',
		icon: auditIcon,
		access: [USER_GROUPS.SYSTEM_ADMINS],
		visible: true,
	},
	{
		to: '/calendars',
		label: 'CALENDARS',
		icon: calendarsIcon,
		access: [USER_GROUPS.SYSTEM_ADMINS],
		visible: true,
	},
	{
		to: '/ledgers',
		label: 'LEDGERS',
		icon: ledgersIcon,
		access: [USER_GROUPS.USERS, USER_GROUPS.USER_ADMINS, USER_GROUPS.SYSTEM_ADMINS],
		visible: window.LEDGER_ENABLED === 'true',
	},
	{
		to: '/forecasting',
		label: 'FORECASTING',
		icon: forecastingIcon,
		access: true,
		visible: window.FORECASTING_ENABLED === 'true',
	},
	{
		to: '/datasetCopy',
		label: 'COPY',
		icon: datasetCopyIcon,
		access: [USER_GROUPS.SYSTEM_ADMINS],
		visible: true,
	},
];

export class Nav extends Component {
	static propTypes = {
		path: PropTypes.string,
		showGlobalNav: PropTypes.func.isRequired,
		hideGlobalNav: PropTypes.func.isRequired,
		userGroups: PropTypes.array,
	};

	state = {
		isOpen: false,
		activeNav: null,
	};

	componentDidMount() {
		this.setNavState(this.props.path);
	}

	componentDidUpdate(prevProps) {
		if (this.props.path !== prevProps.path) {
			this.setNavState(this.props.path);
		}
	}

	setNavState(path = this.props.path) {
		if (path.indexOf('/') === 0) {
			path = path.substr(1);
		}
		if (path.indexOf('/') !== -1) {
			path = path.split('/')[0];
		}

		if (
			path === 'dataExplorer' ||
			path === 'dataCards' ||
			path === 'associatedDataList' ||
			path === 'associatedData' ||
			path === 'debtInputMaintenance' ||
			path === 'dealStructures' ||
			path === 'mappings' ||
			path === 'submissions' ||
			path === 'fundingVehicles' ||
			path === 'poolMaintenance' ||
			path === 'poolMaintenance'
		) {
			this.setState({activeNav: 'datasets'});
		} else {
			this.setState({activeNav: path});
		}
	}

	toggleNav = () => {
		if (this.state.isOpen) {
			this.setNavState();
			this.props.hideGlobalNav();
		} else {
			this.props.showGlobalNav();
		}

		analytics.sendEvent('navigation', 'toggle', this.state.isOpen ? 'close' : 'open');
		this.setState({isOpen: !this.state.isOpen});
	};

	renderNavItems = activeNav => {
		return navSchema(activeNav).map((link, index) => {
			if (link.visible && (link.access === true || userGroupsIntersect(this.props.userGroups, link.access))) {
				return (
					<Link
						to={link.to}
						activeClassName="active"
						className={`ki-nav-item ${link.activeCheck ? 'active' : ''}`}
						key={index}
					>
						<div className="top-level-menu">
							<span className="icon-container" dangerouslySetInnerHTML={{__html: link.icon}} />
							<h5>{link.label}</h5>
							<div className="tooltip">{link.label}</div>
						</div>
					</Link>
				);
			}
			return null;
		});
	};

	render() {
		const {activeNav, isOpen} = this.state;
		return (
			<nav className={`global-nav ${isOpen ? 'open' : 'closed'}`}>
				<div className="nav-container" style={{justifyContent: 'space-between'}}>
					<section style={{display: 'flex', flexFlow: 'column nowrap'}}>
						{this.renderNavItems(activeNav)}
					</section>
					<section style={{display: 'flex', flexFlow: 'column nowrap'}}>
						<div
							style={{visibility: window.ABS_SSO_ENABLED === 'true' ? 'hidden' : 'inherit'}}
							className="ki-nav-item help"
						>
							<Link to={helpURL} activeClassName="active" className="top-level-menu" target="help">
								<span className="icon-container" dangerouslySetInnerHTML={{__html: helpIcon}} />
								<h5>HELP</h5>
							</Link>
							<div className="tooltip">HELP</div>
						</div>
						<div className="bottom-arrow" onClick={this.toggleNav}>
							<i className="material-icons">keyboard_arrow_right</i>
						</div>
					</section>
				</div>
			</nav>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	showGlobalNav: () => dispatch(showGlobalNav()),
	hideGlobalNav: () => dispatch(hideGlobalNav()),
});

const mapStateToProps = state => ({
	userGroups: state.user.groups,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Nav));

// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Project imports
import options from 'ki-common/options';

// Website imports
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';

// Local imports
import ConstraintHelpers from './constraintHelpers';
import styles from './constraintFormStyles.theme.scss';
import validators from 'ki-common/validators';
import ccWeightedAvg from './validators/ccWeightedAvg';

function WeightedAverageForm({
	constraintData,
	setConstraintItem,
	dataColumns,
	eligibleColumns,
	loadingColumns,
	isGroupBy,
	isReadOnly,
	updateFormHasError,
}) {
	let dataOptions = ConstraintHelpers.getCohortColumns(
		dataColumns,
		constraintData.dataColumn,
		true, // isNew
		isGroupBy,
		true // onlyNumeric
	);
	if (isGroupBy) {
		dataOptions = ConstraintHelpers.removeEligibleColumns(dataOptions, eligibleColumns);
	}

	const constraints = ccWeightedAvg.getConstraints();
	const [formError, setFormError] = useState('');

	const validateForm = val => {
		const errors = validators.validate(val, constraints);
		setFormError(errors);
	};

	useEffect(
		() => {
			validateForm(constraintData);
		},
		[constraintData]
	);

	return (
		<div className={styles.root}>
			<div className={styles.constraintFormBody}>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Data Column</span>
					<KiSelect
						isDisabled={isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.dataColumn}
						isLoading={loadingColumns}
						isClearable={false}
						options={dataOptions}
						onChange={dc => {
							setConstraintItem('dataColumn', dc);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Logic</span>
					<KiSelect
						isDisabled={!constraintData.dataColumn || isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.logic}
						isClearable={false}
						options={options.weightedLogicOptions}
						onChange={l => {
							const keys = [];
							const values = [];
							keys.push('logic');
							values.push(l);
							keys.push('target');
							if (l.value === 'between') {
								values.push(['', '']);
							} else {
								values.push(['']);
							}
							setConstraintItem(keys, values);
						}}
					/>
				</div>
				{constraintData.logic &&
					constraintData.logic.value === 'between' && (
						<div
							className={styles.selectWrapper}
							style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
						>
							<KiInput
								disabled={isReadOnly}
								type="text"
								label="Min"
								value={constraintData.target[0]}
								isNumberMasked={true}
								onChange={t => {
									const newValue = [t, constraintData.target[1]];
									setConstraintItem('target', newValue);
								}}
								error={formError?.target}
							/>
							<KiInput
								disabled={isReadOnly}
								type="text"
								label="Max"
								value={constraintData.target[1]}
								isNumberMasked={true}
								onChange={t => {
									const newValue = [constraintData.target[0], t];
									setConstraintItem('target', newValue);
								}}
								error={formError?.target}
							/>
						</div>
					)}
				{constraintData.logic &&
					constraintData.logic.value !== 'between' && (
						<div className={styles.selectWrapper}>
							<KiInput
								disabled={isReadOnly}
								type="text"
								name="name"
								label={'Target'}
								isNumberMasked={true}
								value={constraintData.target && constraintData.target.toString()}
								onChange={t => {
									setConstraintItem('target', [t]);
								}}
								error={formError?.target}
							/>
						</div>
					)}
				<div className={styles.selectWrapper}>
					<span className="theme-label">Selection</span>
					<KiSelect
						isDisabled={isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.selection}
						isClearable={false}
						options={dataOptions}
						onChange={sel => {
							setConstraintItem('selection', sel);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				{updateFormHasError(formError ? true : false)}
			</div>
		</div>
	);
}

WeightedAverageForm.propTypes = {
	constraintData: PropTypes.object,
	setConstraintItem: PropTypes.func,
	dataColumns: PropTypes.array,
	eligibleColumns: PropTypes.array,
	loadingColumns: PropTypes.bool,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	updateFormHasError: PropTypes.func,
};

WeightedAverageForm.defaultProps = {
	constraintData: {},
};

export default WeightedAverageForm;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {waterfallApi} from 'api';
import KiConfirmModal from 'components/KiConfirmModal';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import styles from '../index.theme.scss';
import KiButton from '../../../../../components/KiButton';
import KiIconButton from '../../../../../components/KiIconButton';

export const ScenarioDetailsTable = props => {
	const [confirmationModalActive, setConfirmationModalActive] = useState(false);
	const [scenarioHistoryDeleteKey, setScenarioHistoryDeleteKey] = useState({});

	/*
	useEffect(
		() => {
			if (props.scenario?._id) {
				waterfallApi
					.getProjectionResultsForScenarioId(props.scenario._id)
					.then(runs => setScenarioRunHistory(runs));
			}
		},
		[props.scenario]
	);
		*/

	const deleteProjectionResults = async () => {
		await waterfallApi.deleteProjectionResults(scenarioHistoryDeleteKey);
		props.requeryScenarioRunResults(props.scenario._id);
	};

	const lockProjectionResults = async row => {
		await waterfallApi.lockProjectionResults(row.original._id);
		props.requeryScenarioRunResults(props.scenario._id);
	};

	const unlockProjectionResults = async row => {
		await waterfallApi.unlockProjectionResults(row.original._id);
		props.requeryScenarioRunResults(props.scenario._id);
	};

	return (
		<div className={styles.noTopMarginContainer}>
			<AbsTable
				data={props.scenarioRunHistory}
				columns={[
					{
						Header: 'Run',
						accessor: 'lastRun',
						Cell: ({row}) =>
							`${moment(row.original.lastRun).format('YYYY-MM-DD HH:mm')} by ${row.original.userName}`,
					},
					{Header: 'Data Set', accessor: 'dataSetName'},
					{Header: 'Collateral', accessor: 'collateralName'},
					{Header: 'Model', accessor: 'modelName'},
					{Header: 'Repline Set', accessor: 'replineSet'},
					{Header: 'Call', accessor: 'call', width: 90},
					{Header: 'Report Date', accessor: 'nextPaymentDate', width: 100},
					{
						id: 'actions',
						width: 155,
						displayName: 'actions',
						/* eslint-disable react/display-name, react/prop-types */
						Cell: ({row}) => (
							<div className={styles.actionsColumn}>
								<KiButton mini={true} primary onClick={() => props.getRunResults(row)}>
									View
								</KiButton>
								<KiIconButton
									icon={row.original.locked ? 'lock' : 'lock_open'}
									onClick={() =>
										row.original.locked ? unlockProjectionResults(row) : lockProjectionResults(row)
									}
								/>
								<KiIconButton
									icon="delete"
									onClick={() => {
										setConfirmationModalActive(true);
										setScenarioHistoryDeleteKey(row.original._id);
									}}
								/>
							</div>
						),
					},
				]}
				noDataMessage="No run history"
				showNoDataMessage={!props.isLoading && props.scenarioRunHistory?.length === 0}
				isLoading={props.isLoading}
			/>
			<KiConfirmModal
				active={confirmationModalActive}
				acceptFunc={() => {
					deleteProjectionResults();
					setConfirmationModalActive(false);
				}}
				rejectFunc={() => setConfirmationModalActive(false)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				header="Delete Scenario Result"
				message="Are you sure?"
			/>
		</div>
	);
};

ScenarioDetailsTable.propTypes = {
	scenario: PropTypes.object,
	getRunResults: PropTypes.func.isRequired,
	requeryScenarioRunResults: PropTypes.func.isRequired,
	scenarioRunHistory: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
};

export default ScenarioDetailsTable;

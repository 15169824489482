/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {deleteFundingVehicles} from '../../api/fundingVehiclesApi';
import {fetchDataset} from '../../api/datasetsApi';
import {useParams} from 'react-router';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import {Link, useHistory} from 'react-router-dom';
import KiAppBar from 'components/KiAppBar';
import KiIconButton from 'components/KiIconButton';
import FundingVehicleModal from './components/FundingVehicleModal/FundingVehicleModal';
import DeletionModal from './components/DeletionModal/DeletionModal';
import {showSnackbar} from '../../state/actions/Snackbar';
import styles from './FundingVehicleList.theme.scss';
import KiButton from 'components/KiButton';
import ContextSidebar from 'components/ContextSidebar';
import ManageTriggers from 'containers/dealStructures/components/ManageTriggers';
import ContextIcons from 'components/ContextSidebar/icons';
import {fetchFundingVehicleList} from './actions';
import ErrorDisplayCard from 'components/ErrorDisplayCard';

const FundingVehicleList = () => {
	const {datasetId} = useParams();
	const history = useHistory();
	const [dataset, setDataset] = useState({});
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [deletionTarget, setDeletionTarget] = useState();
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const fvList = useSelector(state => state.fundingVehicleList.data);
	const fvListLoading = useSelector(state => state.fundingVehicleList.isLoading);
	const [error, setError] = useState(null);

	const getFVList = () => {
		dispatch(fetchFundingVehicleList(datasetId));
	};

	useEffect(() => {
		getFVList();
		fetchDataset(datasetId).then(setDataset);
	}, []);

	const onFVSave = () => {
		setIsAddModalOpen(false);
		getFVList();
		dispatch(showSnackbar('Added funding vehicle successfully'));
	};

	const onDeleteConfirmed = () => {
		deleteFundingVehicles([deletionTarget._id])
			.then(() => {
				setDeletionTarget(null);
				getFVList();
				setError(null);
				dispatch(showSnackbar('Deleted funding vehicle successfully'));
			})
			.catch(err => {
				setError(err);
				setDeletionTarget(null);
			});
	};

	const columns = [
		{
			accessor: 'name',
			Header: 'Funding Vehicle Name',
		},
		{
			accessor: 'legalName',
			Header: 'Legal Name',
		},
		{
			Header: '',
			id: 'links',
			Cell: ({row}) => (
				<div className="fvLinks">
					<Link
						className="firstLink"
						to={`/datasets/${row.original.datasetId}/fundingVehicles/${
							row.original._id
						}/capitalStructure/setup`}
					>
						Capital Structure
					</Link>
					<Link
						to={`/datasets/${row.original.datasetId}/fundingVehicles/${
							row.original._id
						}/fundingConfiguration/setup`}
					>
						Funding Configuration
					</Link>
				</div>
			),
			minWidth: 200,
		},
		{
			Header: '',
			id: 'actions',
			Cell: ({row}) => (
				<div>
					<KiButton
						mini
						primary
						onClick={() => history.push(`/dataExplorer/${datasetId}?fundingVehicleId=${row.original._id}`)}
					>
						Explore
					</KiButton>
					<KiIconButton
						className={styles.iconButton}
						icon="delete"
						onClick={() => setDeletionTarget(row.original)}
					/>
				</div>
			),
		},
	];

	return (
		<div className="container-wrapper">
			<section className="container-body">
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1 className="link" onClick={() => history.push('/datasets')}>{`Datasets`}</h1>
						<h1>{` > ${dataset.name}`}</h1>
						<h1>{` > Funding Vehicles`}</h1>
					</div>
					<KiIconButton
						icon="add_circle"
						inverse
						onClick={() => setIsAddModalOpen(true)}
						title="Click to create a new funding vehicle."
					/>
				</KiAppBar>
				<div className="ki-panel">
					<ErrorDisplayCard error={error} />
					<div className={styles.fvTable}>
						<AbsTable
							columns={columns}
							data={fvList}
							isLoading={fvListLoading}
							noDataMessage="No Funding Vehicles found"
							showNoDataMessage={!fvListLoading && fvList?.length === 0}
						/>
					</div>
				</div>
				<FundingVehicleModal
					dataset={dataset}
					isActive={isAddModalOpen}
					onClose={() => setIsAddModalOpen(false)}
					fundingVehicles={fvList}
					onSave={onFVSave}
				/>
				<DeletionModal
					fv={deletionTarget}
					onCancel={() => setDeletionTarget(null)}
					onDeleteConfirmed={onDeleteConfirmed}
				/>
			</section>
			<ContextSidebar
				items={[
					{
						name: 'Triggers',
						icon: <ContextIcons.MaterialIcon name="playlist_add_check" />,
						element: <ManageTriggers user={user} datasetId={datasetId} panelMode={false} />,
					},
				]}
			/>
		</div>
	);
};

export default FundingVehicleList;

// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Project imports
import options from 'ki-common/options';

// Website imports
import KiInput from 'components/KiInput';
import KiSelect, {KiCreatable} from 'components/KiSelect';

// Local imports
import ConstraintHelpers from './constraintHelpers';
import styles from './constraintFormStyles.theme.scss';
import validators from 'ki-common/validators';
import ccConcentration from './validators/ccConcentration';

function ConcentrationForm({
	constraintData,
	setConstraintItem,
	dataColumns,
	eligibleColumns,
	loadingColumns,
	isGroupBy,
	isReadOnly,
	updateFormHasError,
}) {
	const dataOptions = ConstraintHelpers.getCohortColumns(
		dataColumns,
		constraintData.dataColumn,
		true, // isNew
		isGroupBy,
		false // onlyNumeric
	);

	let selectionOptions = ConstraintHelpers.getCohortColumns(
		dataColumns,
		constraintData.dataColumn,
		true, // isNew
		isGroupBy,
		true // onlyNumeric
	);

	const constraints = ccConcentration.getConstraints();
	const [formError, setFormError] = useState('');
	const isDateValue =
		constraintData.dataColumn?.dataType === 'date_long' || constraintData.dataColumn?.dataType === 'date_short'
			? true
			: false;

	const validateForm = val => {
		const errors = validators.validate(val, constraints);
		setFormError(errors);
	};

	useEffect(
		() => {
			validateForm(constraintData);
		},
		[constraintData]
	);

	if (isGroupBy) {
		selectionOptions = ConstraintHelpers.removeEligibleColumns(selectionOptions, eligibleColumns);
	}

	return (
		<div className={styles.root}>
			<div className={styles.constraintFormBody}>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Data Column</span>
					<KiSelect
						key={constraintData.dataColumn}
						isDisabled={isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.dataColumn}
						isLoading={loadingColumns}
						isClearable={false}
						options={dataOptions}
						onChange={dc => {
							setConstraintItem('dataColumn', dc);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Logic</span>
					<KiSelect
						key={constraintData.logic}
						isDisabled={!constraintData.dataColumn || isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.logic}
						isClearable={false}
						options={options.concentrationLogicOptions}
						onChange={l => {
							const keys = [];
							const values = [];
							keys.push('logic');
							values.push(l);
							keys.push('target');
							if (l.value === 'between') {
								values.push(['', '']);
							} else {
								values.push(['']);
							}
							setConstraintItem(keys, values);
						}}
					/>
				</div>
				{constraintData.logic &&
					constraintData.logic.value === 'between' && (
						<div
							className={styles.selectWrapper}
							style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
						>
							<KiInput
								disabled={isReadOnly}
								type="text"
								label="Min %"
								value={constraintData.target[0]}
								isNumberMasked={!isDateValue}
								onChange={t => {
									const newValue = [t, constraintData.target[1]];
									setConstraintItem('target', newValue);
								}}
								error={formError?.target}
							/>
							<KiInput
								disabled={isReadOnly}
								type="text"
								label="Max %"
								value={constraintData.target[1]}
								isNumberMasked={!isDateValue}
								onChange={t => {
									const newValue = [constraintData.target[0], t];
									setConstraintItem('target', newValue);
								}}
								error={formError?.target}
							/>
						</div>
					)}
				{constraintData.logic &&
					constraintData.logic.value !== 'between' && (
						<div className={styles.selectWrapper}>
							<KiInput
								disabled={isReadOnly}
								type="text"
								value={constraintData.target && constraintData.target.toString()}
								label={'Target %'}
								isNumberMasked={!isDateValue}
								onChange={t => {
									setConstraintItem('target', [t]);
								}}
								error={formError?.target}
							/>
						</div>
					)}
				<div className={styles.selectWrapper}>
					<span className="theme-label">Selection</span>
					<KiSelect
						key={constraintData.selection}
						isDisabled={isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.selection}
						isClearable={false}
						options={options.selections}
						onChange={sel => {
							setConstraintItem('selection', sel);
						}}
					/>
				</div>
				{constraintData.selection &&
					constraintData.selection.value === 'include' && (
						<div className={styles.includeWrapper}>
							<span className="theme-label">Includes</span>
							<KiCreatable
								isDisabled={isReadOnly}
								isMulti={true}
								type="text"
								placeholder={'Enter a value...'}
								clearable={false}
								noResultsText={false}
								value={
									constraintData.concentrationIncludes &&
									constraintData.concentrationIncludes.map(t => ({
										value: t,
										label: t,
									}))
								}
								onChange={t => {
									setConstraintItem('concentrationIncludes', t.map(t => t.value));
								}}
							/>
							{
								<div className={styles.ccConcentrationIncludesDateError}>
									{formError?.concentrationIncludes}
								</div>
							}
						</div>
					)}
				{constraintData.selection &&
					constraintData.selection.value === 'top_nth_bucket' && (
						<React.Fragment>
							<span className="theme-label">Excludes</span>
							<KiCreatable
								isDisabled={isReadOnly}
								isMulti={true}
								type="text"
								placeholder={'Enter a value...'}
								clearable={false}
								noResultsText={false}
								value={
									constraintData.concentrationExcludes &&
									constraintData.concentrationExcludes.map(t => ({
										value: t,
										label: t,
									}))
								}
								onChange={t => {
									setConstraintItem('concentrationExcludes', t.map(t => t.value));
								}}
							/>
							{
								<div className={styles.ccConcentrationIncludesDateError}>
									{formError?.concentrationExcludes}
								</div>
							}
							<div className={styles.selectWrapper}>
								<KiInput
									disabled={isReadOnly}
									type="text"
									label="Value"
									value={constraintData.concentrationValue}
									isNumberMasked={!isDateValue}
									onChange={t => {
										setConstraintItem('concentrationValue', t);
									}}
									error={formError?.concentrationValue}
								/>
							</div>
						</React.Fragment>
					)}
				{constraintData.selection &&
					constraintData.selection.value === 'top_n_combined' && (
						<div className={styles.selectWrapper}>
							<span className="theme-label">Excludes</span>
							<KiCreatable
								isDisabled={isReadOnly}
								isMulti={true}
								type="text"
								placeholder={'Enter a value...'}
								clearable={false}
								noResultsText={false}
								value={
									constraintData.concentrationExcludes &&
									constraintData.concentrationExcludes.map(t => ({
										value: t,
										label: t,
									}))
								}
								onChange={t => {
									setConstraintItem('concentrationExcludes', t.map(t => t.value));
								}}
							/>
							{
								<div className={styles.ccConcentrationIncludesDateError}>
									{formError?.concentrationExcludes}
								</div>
							}
							<div className={styles.selectWrapper}>
								<KiInput
									disabled={isReadOnly}
									type="text"
									label="Min"
									value={constraintData.concentrationMin}
									isNumberMasked={!isDateValue}
									onChange={t => {
										setConstraintItem('concentrationMin', t);
									}}
									error={formError?.concentrationMin}
								/>
								<KiInput
									disabled={isReadOnly}
									type="text"
									label="Max"
									value={constraintData.concentrationMax}
									isNumberMasked={!isDateValue}
									onChange={t => {
										setConstraintItem('concentrationMax', t);
									}}
									error={formError?.concentrationMax}
								/>
							</div>
						</div>
					)}
				<div className={styles.selectWrapper}>
					<span className="theme-label">Weighted By</span>
					<KiSelect
						key={constraintData.weightedBy}
						isDisabled={isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.weightedBy}
						isClearable={false}
						options={selectionOptions}
						onChange={sel => {
							setConstraintItem('weightedBy', sel);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				{updateFormHasError(formError ? true : false)}
			</div>
		</div>
	);
}

ConcentrationForm.propTypes = {
	constraintData: PropTypes.object,
	setConstraintItem: PropTypes.func,
	dataColumns: PropTypes.array,
	eligibleColumns: PropTypes.array,
	loadingColumns: PropTypes.bool,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	updateFormHasError: PropTypes.func,
};

ConcentrationForm.defaultProps = {
	constraintData: {},
};

export default ConcentrationForm;
